import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import { IClientLookup } from "../../../models/IClientLookup";
import { baseUrl } from "../../../utils/authConfig";

interface ClientAutoCompleteProps {
  disabled?: boolean;
  fullWidth?: boolean;
  value?: any;
  label?: string;
  accessToken?: string;
  valid?: boolean | null;
  onChange(client: IClientLookup): void;
}

const ClientAutoComplete: React.FunctionComponent<ClientAutoCompleteProps> = ({
  disabled,
  fullWidth,
  onChange,
  label,
  accessToken,
  valid,
}): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly IClientLookup[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const onChangeHandle = async (value: any) => {
    setIsLoading(true);
    var search = value.trim();
    const url = baseUrl + `api/ClientLookups/${search}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      const options = data.map((cl: any) => {
        return {
          ...cl,
          id: cl.clientLookupId,
          label: `${cl.client} - ${cl.clientName}`,
        };
      });
      setOptions(options);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = (_event: any, newValue: any) => {
    if (onChange && newValue) {
      onChange(newValue);
    }
  };

  return (
    <div className="client-autocomplete">
      <h6 className="label">{label}</h6>
      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "0 0 5px 0" }}
      >
        <span
          className="material-icons"
          style={{
            background: "#e9ecef",
            borderRadius: "0.25rem",
            border: "1px solid #ced4da",
            padding: "7px",
          }}
        >
          search
        </span>
        <Autocomplete
          id="client-auto-complete"
          options={options}
          fullWidth={fullWidth}
          disabled={disabled}
          filterOptions={(x) => x}
          autoHighlight
          noOptionsText="No clients found."
          size="small"
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={isLoading}
          getOptionLabel={(option) =>
            option.client + " - " + option.clientName || ""
          }
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.clientLookupId}>
              {option.client} - {option.clientName}
            </Box>
          )}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              onChange={(ev) => {
                if (ev.target.value !== "" || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              className={`${
                valid === null || valid === undefined
                  ? "inputStyle"
                  : valid === true
                  ? "isValid"
                  : "isInValid"
              }`}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ClientAutoComplete;
