/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { Dangerous, Warning } from '@mui/icons-material';
import AppMenu from './AppMenu';
import Header from './Header/Header';
import { sbuLeaderCommentsEnabledSelector } from '../store/reducers/Settings';

const Layout: React.FunctionComponent = (props : any): JSX.Element => {

  const isAdmin = props.auth.roles && props.auth.roles.length > 0 && props.auth.roles.includes('admin');
  const currentYear = new Date().getFullYear();
   
  const renderDefault = () => {
    return(
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <AppMenu sbuLeaderCommentsEnabled={props.sbuLeaderCommentsEnabled} isAdmin={isAdmin} />
          </Grid>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            {props.children}
          </Grid>
          <Grid item xs={12}>
            <div className="disclaimer">&copy; {currentYear} Husch Blackwell LLP. All rights reserved</div>
          </Grid>    
        </Grid>
      </Box>
    )
  }

  const renderLoading = () => {
		return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>         
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <h1 className="text-success text-center" style={{marginTop:'1rem'}}>
              <CircularProgress color="success" /> {" "}
              Loading
            </h1>
          </Grid>
          <Grid item xs={12}>
            <div className="disclaimer">&copy; {currentYear} Husch Blackwell LLP. All rights reserved</div>
          </Grid>    
        </Grid>
      </Box>
		);
	}

  const renderUnauthorized = () => {
		return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" style={{verticalAlign: 'middle', display: 'inline-flex', fontWeight:700 }}>
              <Dangerous color="warning" sx={{fontSize:'42px'}} />{" "} Unauthorized
            </Typography>
          </Grid>  
          <Grid item xs={12} style={{marginTop:'2rem'}}>
            <Typography variant="h6">
              You are not authorized to view this site. If you believe this to be an error, please contact Karen Huck.
            </Typography> 
          </Grid>        
          <Grid item xs={12} style={{marginTop:'2rem'}}>
            <div className="disclaimer">&copy; {currentYear} Husch Blackwell LLP. All rights reserved</div>
          </Grid>    
        </Grid>
      </Box>
		);
	}

  const renderError = () => {
		return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <AppMenu />
          </Grid>
          <Grid item xs={12} style={{marginTop:'2rem'}}>
            <Typography variant="h4" style={{verticalAlign: 'middle', display: 'inline-flex', fontWeight:700 }}>
              <Warning color='error' sx={{fontSize:'40px'}} />{" "} Error
            </Typography>
          </Grid>  
          <Grid item xs={12} style={{marginTop:'2rem'}}>
            <Typography variant="h6">
              An error has occurred.  Please refresh the webpage to continue.
            </Typography> 
          </Grid>     
          <Grid item xs={12} style={{marginTop:'2rem'}}>
            <div className="disclaimer">&copy; {currentYear} Husch Blackwell LLP. All rights reserved</div>
          </Grid>    
        </Grid>
      </Box>
		);
	}


  if (props.auth.isLoading) return renderLoading();
	if (!props.auth.user) return renderUnauthorized();
	return (props.error) ? renderError() : renderDefault();
}

const mapStateToProps = (state: any) => {
	return {
		identity: state.identity,
		auth: state.auth,
		error: state.error,		
		sbuLeaderCommentsEnabled: sbuLeaderCommentsEnabledSelector(state)		
	};
};

export default connect(mapStateToProps, null)(Layout);