import * as React from 'react';
import {Backdrop, CircularProgress} from '@mui/material/';

interface BackdropProps {
  show: boolean;
}

// eslint-disable-next-line no-empty-pattern
const ReportBackdrop: React.FunctionComponent<BackdropProps> = ({
  show
}): JSX.Element => {
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ReportBackdrop;