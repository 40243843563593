const utils = {
	getValidationStateCheckbox: function(checked, defaultValidationState = null) {
		if (!this.hasText(checked)) return defaultValidationState;
		return "success";
	},

	getValidationStateCollection: function(collection, min, getValidationFunction = null, defaultValidationState = null) {
		if (collection === null || collection === undefined) return defaultValidationState;
		if (collection.length === 0) return null;
		if (collection.length < min) return "warning";
		if (getValidationFunction) {
			for (let i = 0; i < collection.length; i++) {
				const item = collection[i];
				const itemResult = getValidationFunction(item);
				if (itemResult === "warning" || itemResult === "danger") return "warning";
			}
		}
		return "success";
	},

	getValidationStateComments: function(comments, defaultValidationState = null) {
		if (!this.hasText(comments)) return defaultValidationState;
		return true;
	},

	getValidationStateDm: function(dm, defaultValidationState = null) {
		if (!this.hasText(dm)) return defaultValidationState;
		else if (!this.isNumeric(dm)) return false;
		return true;
	},

	getValidationStateHours: function(hours, defaultValidationState = null) {
		if (!this.hasText(hours)) return defaultValidationState;
		else if (!this.isNumeric(hours)) return false;
		return true;
	},

	getValidationStateRevenue: function(revenue, defaultValidationState = undefined) {
		if (!this.hasText(revenue)) return defaultValidationState;
		else if (!this.isNumeric(revenue)) return false;
		return true;
	},

	getValidationStateSelect: function(select, defaultValidationState = null) {
		if (!this.hasText(select)) return defaultValidationState;
		return "success";
	},

	getValidationStateText: function(text, defaultValidationState) {		
		if (!this.hasText(text)) return defaultValidationState;
		return true;
	},

	getValidationState: function(text, defaultValidationState = null) {		
		if (!this.hasText(text)) return defaultValidationState;
		return true;
	},

	hasText: function(text) {
		if (text === null || text === undefined || text.toString().trim() === "") return false;
		return true;
	},

	isDefined: function(value) {
		return (value !== undefined && value !== null);
	},

	isNumeric: function(value) {
		return !isNaN(value - parseFloat(value));
	},

	isTextComplete: function(value, minLength = 1) {
		return value !== undefined && value !== null && value.toString().length >= minLength;
	},

	isEmptyOrZero: function(value) {
		return (!value || (this.isNumeric(value) && parseInt(value, 10) === 0));
	}
};

export default utils;