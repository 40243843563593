import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IClientLookup } from "../../models/IClientLookup";
import { IEconomicPcClient } from "../../models/IEconomicPcClient";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import ClientAutoComplete from "../common/client-autocomplete/client-autocomplete";
import Input from "../common/input/Input";

interface EconomicPcClientAddEditProps {
  accessToken: string;
  isNew?: boolean;
  title: string;
  year?: number;
  reviewYear: number;
  canEdit?: boolean;
  client?: IEconomicPcClient;
  proliferationAreas: Array<string>;
  close(): void;
  save(arg0: IEconomicPcClient, isReviewYear: boolean): void;
  isReviewYear: boolean;
}

const EconomicPcClientAddEdit: React.FunctionComponent<
  EconomicPcClientAddEditProps
> = ({
  accessToken,
  isNew,
  title,
  year,
  reviewYear,
  canEdit,
  client,
  proliferationAreas,
  save,
  close,
  isReviewYear,
}): JSX.Element => {
  const [tempPcClient, setPcClient] = useState(
    client || ({ year: year } as IEconomicPcClient)
  );
  const [defaultValidationState, setDefaultValdationState] = useState<
    boolean | null
  >(null);
  const disabled = client && client.year === reviewYear && !isReviewYear;
  const valid = Utils.getValidationStateText(
    tempPcClient.proliferationArea,
    defaultValidationState
  );

  const proliferationAreaOptions = proliferationAreas.map(pa => {
    return (
      <MenuItem key={pa} value={pa}>
        {pa}
      </MenuItem>
    );
  });

  const isClientComplete = (client: IEconomicPcClient) => {
    if (client === undefined || client === null) return false;
    const result =
      Utils.isTextComplete(client.clientNumber) &&
      Utils.isTextComplete(client.clientName) &&
      Utils.isTextComplete(client.proliferationArea);
    return result;
  };

  const onSave = () => {
    if (!canEdit) {
      close();
      return;
    }

    const isPcClientComplete = isClientComplete(tempPcClient);
    if (!isPcClientComplete) {
      setDefaultValdationState(false);
      return;
    }

    save(tempPcClient, isReviewYear);
  };

  const handleClientChange = (value: IClientLookup) => {
    if (!canEdit) return;

    setPcClient({
      ...tempPcClient,
      clientNumber: value.client,
      clientName: value.clientName,
    });
  };

  const handleChange = (event: any) => {
    if (!canEdit) return;

    const { name, value } = event.target;
    setPcClient({
      ...tempPcClient,
      [name]: value,
    });
  };

  return (
    <div>
      <Dialog
        open={true}
        scroll="paper"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ textAlign: "center", pb: 0 }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Typography sx={{ textAlign: "left", fontSize: 22, pb: 2 }}>
                {title}
              </Typography>
            </div>
            <div>
              <IconButton
                onClick={close}
                sx={{ textAlign: "right", fontSize: 22 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {isNew && (
            <ClientAutoComplete
              accessToken={accessToken}
              fullWidth
              label="Client Name/Number:"
              onChange={handleClientChange}
              valid={Utils.getValidationStateText(
                tempPcClient.clientNumber,
                defaultValidationState
              )}
            />
          )}

          <div style={{ marginTop: "15px" }}>
            <Input
              placeholder="Client Number"
              value={Format.formatString(tempPcClient.clientNumber)}
              disabled
              fullWidth
              name="clientNumber"
              label="Client Number:"
              valid={Utils.getValidationStateText(
                tempPcClient.clientNumber,
                defaultValidationState
              )}
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <Input
              placeholder="Client Name"
              value={Format.formatString(tempPcClient.clientName)}
              disabled
              fullWidth
              name="clientName"
              label="Client Name:"
              valid={Utils.getValidationStateText(
                tempPcClient.clientName,
                defaultValidationState
              )}
            />
          </div>
          <div style={{ marginTop: "15px" }} className="economic-client">
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Proliferation Area:
            </Typography>
            <FormControl size="small" fullWidth>
              <Select
                labelId="proliferationArea"
                id="proliferationArea"
                name="proliferationArea"
                disabled={!canEdit || disabled}
                value={tempPcClient.proliferationArea}
                onChange={handleChange}
                className={`${
                  valid === null || valid === undefined
                    ? "inputStyle"
                    : valid === true
                    ? "isValid"
                    : "isInValid"
                }`}
              >
                {proliferationAreaOptions}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className="theme-button"
            onClick={close}
          >
            Cancel
          </Button>
          {canEdit && !disabled && (
            <Button
              variant="contained"
              size="medium"
              className="theme-button"
              onClick={onSave}
            >
              Save and Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EconomicPcClientAddEdit;
