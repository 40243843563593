import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Format from "../../../utils/Format";
import { actionCreators as settingsActionCreators } from "../../../store/reducers/Settings";
import { useEffect } from "react";

const AdminSettings: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    props.settingsActions.loadSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckbox = (event: any) => {
    props.settingsActions.updateSetting({
      name: event.target.id,
      value: event.target.checked.toString(),
    });
  };

  const handleTextChange = (event: any) => {
    props.settingsActions.updateSetting({
      name: event.target.id,
      value: event.target.value,
    });
  };

  const { settings } = props;
  let canEdit = settings.data.find((s: any) => s.name === "canEdit");
  canEdit = canEdit ? canEdit.value === "true" : false;
  let canEditProxy = settings.data.find((s: any) => s.name === "canEditProxy");
  canEditProxy = canEditProxy ? canEditProxy.value === "true" : false;
  let canEditTeam = settings.data.find((s: any) => s.name === "canEditTeam");
  canEditTeam = canEditTeam ? canEditTeam.value === "true" : false;
  let partnerCommentsEnabled = settings.data.find(
    (s: any) => s.name === "partnerCommentsEnabled"
  );
  partnerCommentsEnabled = partnerCommentsEnabled
    ? partnerCommentsEnabled.value === "true"
    : false;
  let sbuLeaderCommentsEnabled = settings.data.find(
    (s: any) => s.name === "sbuLeaderCommentsEnabled"
  );
  sbuLeaderCommentsEnabled = sbuLeaderCommentsEnabled
    ? sbuLeaderCommentsEnabled.value === "true"
    : false;

  let reviewYear = settings.data.find((s: any) => s.name === "reviewYear");
  reviewYear = reviewYear ? reviewYear.value : new Date().getFullYear();

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className={`admin container ${(settings.isUpdating || settings.isLoading) ? 'whirl' : ''}`}>
      <Grid item className="header">
        <Typography variant="h5">Admin Settings</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is a list of all of the current settings:
        </Typography>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Card>
          <CardHeader
            title={
              <Typography className="title" sx={{ fontSize: 16, fontWeight: 700 }}> Review Year </Typography>
            }
            className="card-header"
            avatar={
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  margin: "0px !important",
                }} > <BorderColorIcon sx={{ color: "white", fontSize: "18px" }} /></Avatar>
            } />
          <CardContent className="card-content">
            <FormGroup>            
              <InputLabel sx={{fontWeight: 700}}> Review Year </InputLabel>
              <TextField  id="reviewYear"
                          variant="outlined" 
                          size="small"                          
                          sx={{width:"20%"}}     
                          value={reviewYear} onChange={handleTextChange} />
            </FormGroup>           
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Card>
          <CardHeader
            title={
              <Typography
                className="title"
                sx={{ fontSize: 16, fontWeight: 700 }}>
                Can Edit
              </Typography>
            }
            className="card-header"
            avatar={
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  margin: "0px !important",
                }}>
                <BorderColorIcon sx={{ color: "white", fontSize: "18px" }} />
              </Avatar>
            } />
          <CardContent className="card-content">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    id="canEdit"
                    checked={Format.formatCheckboxInput(canEdit)}
                    onChange={handleCheckbox}
                  />
                }
                label="Enable Planning Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    id="canEditProxy"
                    checked={Format.formatCheckboxInput(canEditProxy)}
                    onChange={handleCheckbox}
                  />
                }
                label="Allow Proxies to Enter Planning Goals"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    id="canEditTeam"
                    checked={Format.formatCheckboxInput(canEditTeam)}
                    onChange={handleCheckbox}
                  />
                }
                label="Enable Teamwork & Collaboration In Review and Planning"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Card>
          <CardHeader
            title={
              <Typography
                className="title"
                sx={{ fontSize: 16, fontWeight: 700 }}> Stages </Typography>
            }
            className="card-header"
            avatar={
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  margin: "0px !important",
                }} > <BorderColorIcon sx={{ color: "white", fontSize: "18px" }} /></Avatar>
            } />
          <CardContent className="card-content">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    id="partnerCommentsEnabled"
                    checked={Format.formatCheckboxInput(partnerCommentsEnabled)}
                    onChange={handleCheckbox}
                  />
                }
                label="Enable In Review Partner Comments"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    id="sbuLeaderCommentsEnabled"
                    checked={Format.formatCheckboxInput(sbuLeaderCommentsEnabled)}
                    onChange={handleCheckbox}
                  />
                }
                label="SBU Leader Recommendations"
              />
            </FormGroup>           
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    settings: state.settings,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    settingsActions: bindActionCreators(settingsActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
