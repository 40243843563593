import { Grid, FormControl, InputLabel } from "@mui/material";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import EconomicHistory from "./EconomicHistory";
import TextArea from "../common/textarea/TextArea";
import { ISummaryComment } from "../../models/ISummaryComments";
import { billableHoursTarget } from "../../utils/billableHoursTarget";

interface EconomicWAProps {
  disabled?: boolean;
  economic: IEconomic;
  canEdit?: boolean;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
  summaryComment: ISummaryComment;
  onSummaryChange?: (arg0: any) => void;
}

const EconomicWA: React.FunctionComponent<EconomicWAProps> = ({
  disabled,
  economic,
  onChange,
  economicHistories,
  summaryComment,
  onSummaryChange
}): JSX.Element => {

  const handleOnChange = (event:  any) => {
    if (disabled) return;

    if (onChange) {
      onChange(event);

      let actualBillableHours = 0;

        if (event.target.name === "waBillableHours") {
            actualBillableHours = Math.round(event.target.value) + Math.round(economic.waInvestmentHours ?? 0);
        } else if(event.target.name === "waInvestmentHours") {
            actualBillableHours = Math.round(economic.waBillableHours ?? 0) + Math.round(event.target.value);
        }
        
        if (actualBillableHours >= billableHoursTarget) {
            handleOnSummaryChange({ target: { name: "economicHoursComments", value: "N/A" } });
        }
    }
  };

    const handleOnSummaryChange = (event:  any) => {
    if (disabled) return;

    if (onSummaryChange) {
      onSummaryChange(event);
    }
  };

  const setEconomicHoursHidden = () => {
      if ((economic.waBillableHours === null && economic.waInvestmentHours === null) ||
      (economic.waBillableHours?.toString() === "" && economic.waInvestmentHours?.toString() === "")) { return 'none'; }
      
      if (Math.round(economic.waBillableHours ?? 0) + Math.round(economic.waInvestmentHours ?? 0) >= 2400) {         
          return 'none';
      } else {
          return 'block';
      }
    }
  
  return (
    <div>
      <Grid container>
        <EconomicHistory  label="Billable Hours: "
                          section="wa"
                          isBillableHours
                          economicHistories={economicHistories} />
        <FormControl fullWidth>
          <Input  value={Format.formatCurrencyInput(economic.waBillableHours)}
                  endIcon="Hours"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  name="waBillableHours"
                  onChange={handleOnChange}
                  valid={Utils.getValidationStateHours(economic.waBillableHours)} />
        </FormControl>       
      </Grid>
      <Grid container>
        <EconomicHistory  label="Investment Hours: "
                          section="wa"
                          isinvestmentHours
                          economicHistories={economicHistories} />
        <FormControl fullWidth>
          <Input  
                  value={Format.formatCurrencyInput(economic.waInvestmentHours)}
                  endIcon="Hours"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  name="waInvestmentHours"
                  onChange={handleOnChange}
                  valid={Utils.getValidationStateHours(economic.waInvestmentHours)} />
        </FormControl>
      </Grid>
      <Grid container>
      <InputLabel className="label">
        (Billable Hours plus Investment Hours must equal at least 2400)
      </InputLabel>
      </Grid>
      <Grid container>
              <Grid sx={{display: setEconomicHoursHidden}}>
                        <TextArea
                          label="If there are circumstances preventing you from achieving the 2400 hour goal, please comment below."
                          name="economicHoursComments"
                          value={Format.formatString(summaryComment.economicHoursComments)}
                          onChange={handleOnSummaryChange}
                          limit={550}
                          disabled={disabled}
                        />
          </Grid>
      </Grid>
      <Grid container>
        <EconomicHistory  label="Collections: "
                          section="wa"
                          isRevenue
                          economicHistories={economicHistories} />
        <FormControl fullWidth>
          <Input  placeholder="Collections"
                  value={Format.formatCurrencyInput(economic.waCollections)}
                  icon="attach_money"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  name="waCollections"
                  onChange={handleOnChange}
                  valid={Utils.getValidationStateRevenue(economic.waCollections)} />
        </FormControl>       
      </Grid>
    </div>
  )
}

export default EconomicWA;