import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonIcon from "@mui/icons-material/Person";
import { ITeam } from "../../models/ITeam";
import Format from "../../utils/Format";
import Status from "../../utils/Status";
import { useState } from "react";
import Confirmation from "../common/Confirmation";

interface TeamDisplayProps {
  team: ITeam;
  canEdit: boolean;
  editTeam(team: ITeam): void;
  removeTeam(id: number): void;
}

// eslint-disable-next-line no-empty-pattern
const TeamDisplay: React.FunctionComponent<TeamDisplayProps> = ({
  team,
  canEdit,
  editTeam,
  removeTeam,
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const isComplete = Status.isTeamComplete(team);
  const incomplete = isComplete ? "" : " - INCOMPLETE";
  const showTargets = team.yourRole === "CRM";
  const confirmationText =
    "Are you sure you want to delete this team?  This process cannot be undone.";

  function onConfirmationOk() {
    setOpen(false);
    if (!canEdit) return;
    removeTeam(team.teamId);
  }

  const onRemoveTeam =(e: any) => {
    e.stopPropagation();
    setOpen(true)
  }

  const onEditTeam = (e: any) => {
    e.stopPropagation();
    editTeam(team);
  };

  return (
    <Grid item xs={4} sx={{ padding: "5px" }}>
      <Card
        className="card"
        sx={{
          border: isComplete
            ? `1px solid green !important`
            : "1px solid red !important",
          minHeight: "135px",
        }}
      >
        <CardActionArea onClick={onEditTeam}>
          <CardHeader
            title={
              <Typography className="fs-14">
                {team.teamName}
                {incomplete}
              </Typography>
            }
            avatar={
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  margin: "0px !important",
                }}
              >
                <PersonIcon sx={{ color: "black", fontSize: "18px" }} />
              </Avatar>
            }
            action={
              canEdit && (
                <IconButton onClick={(e) => onRemoveTeam(e)}>
                  <HighlightOffIcon
                    color="error"
                    sx={{ fontSize: "18px", mt: "8px" }}
                  />
                </IconButton>
              )
            }
            className="card-header"
          ></CardHeader>
          <CardContent sx={{ padding: "12px !important" }}>
            <Typography className="fs-14" gutterBottom>
              Role: <b>{team.yourRole}</b>
            </Typography>
            {showTargets && (
              <Typography className="fs-14" gutterBottom>
                Revenue Target:{" "}
                <b>{Format.formatCurrency(team.revenueTarget)}</b>
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      <Confirmation
        open={open}
        onClose={() => setOpen(false)}
        onOk={onConfirmationOk}
        title={confirmationText}
      />
    </Grid>
  );
};

export default TeamDisplay;
