import Utils from './Utils';

const Format = {
	formatCheckboxInput: function(value) {
		return !!value;
	},

	formatComments: function(value, maxLength = null) {
		if (!Utils.hasText(value)) return '';
		if (maxLength !== null && maxLength !== undefined) {
			if (value.length > maxLength) return value.substr(0, maxLength - 3) + '...';
		}
		return value;
	},

	formatCurrency: function(value) {
		if (!Utils.isNumeric(value)) return '';
		return value.toLocaleString(undefined,
			{ style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
	},

	formatCurrencyInput: function(value) {
		if (!Utils.isNumeric(value)) return '';
		if (typeof value === 'number') return Math.round(value, 2);
		return value;
	},

	formatDate(value) {
		if (!Utils.hasText(value)) return '';
		const date = new Date(value);
		return date.toLocaleDateString();
	},

	formatDateTime(value) {
		if (!Utils.hasText(value)) return '';
		const date = new Date(value);
		return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
	},

	formatHours: function(value) {
		if (!Utils.isNumeric(value)) return '';
		var result = value.toLocaleString(undefined,
			{ style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });
		if (result === '1') result += ' hour';
		else result += ' hours';
		return result;
	},

	formatMultiselectInput: function(value) {
		if (!Utils.hasText(value)) return [];
		const array = value.split(',');
		return array;
	},

	formatPercentage: function(value, fractionDigits = 1) {
		if (!Utils.isNumeric(value)) return '';
		return value.toLocaleString(undefined,
				{ style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }) +
			'%';
	},

	formatPercentageInput: function(value) {
		if (!Utils.isNumeric(value)) return '';
		return value;
	},

	formatString: function (value) {
		if (!Utils.hasText(value)) return '';
		return value;
	},

	formatAppendString: function (value, appendText) {
		if (!Utils.hasText(value)) return '';
		return `${value} ${appendText}`;
	},
	formatWholeNumber: function (value) {
		if (!Utils.isNumeric(value)) return '';
		return Math.round(value);
	},
	formatNaturalNumber: function (value) {
		if (!Utils.isNumeric(value)) return '';
		return Math.abs(value);
	},
};

export default Format;