import { Card
  , CardContent
  , CardHeader
  , Grid, Paper
  , styled
  , Table
  , TableBody
  , TableCell
  , tableCellClasses
  , TableContainer
  , TableHead
  , TableRow
  , Typography } from "@mui/material";

function createData(
  name: string,
  ext: string,
  workphone: string,
  mobile: string
) {
  return { name, ext, workphone, mobile };
}

const rows = [
  createData("Matt Apprill", "x261621", "(314) 480-1621", "(314) 623-1918"),
  createData("Jamie Henss", "x266331", "(314) 345-6331", "(618) 971-8390"),
];

const rows1 = [
  createData("Karen Huck", "x261574", "(314) 480-1574", "(573) 883-6789"),
  createData("Tim Tyler", "x261558", "(314) 480-1558", "(314) 606-4554"),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: "none",
    padding: "2px 5px",
    fontSize: "12px",
    lineHeight: "0.9rem",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    padding: "2px 5px",
    fontSize: "12px",
    lineHeight: "0.8rem",
  },
}));


const Contacts: React.FunctionComponent = (props: any): JSX.Element => {
 
  return (
    <Card>
      <CardHeader title={<Typography className="title">Contacts</Typography>}
                  className="card-header" />
      <CardContent className="card-content">
        <Typography variant="body1" component="p" sx={{ fontSize: "14px" }}>
          For questions and assistance, please contact:
        </Typography>
        <Grid container>
          <Grid item sx={{ p: 2.35 }} xs={6}>
            <TableContainer component={Paper} className="borderless-table-container">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={5}
                                      sx={{
                                        textAlign: "left",
                                        textDecoration: "underline",
                                      }} >
                      Statistical Data
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell width="30%">Contact</StyledTableCell>
                    <StyledTableCell align="center" width="20%">
                      Ext
                    </StyledTableCell>
                    <StyledTableCell align="center" width="25%">
                      Work Phone
                    </StyledTableCell>
                    <StyledTableCell align="center" width="25%">
                      Mobile
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}>
                      <StyledTableCell component="td" scope="row" width="30%" >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="20%">
                        {row.ext}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="25%">
                        {row.workphone}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="25%">
                        {row.mobile}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" component="p" className="fs-12" sx={{ p: 1 }}>
              You can also email your questions to{" "}
              <a href="mailto:FinancialReportingTeam@huschblackwell.com">
                FinancialReportingTeam@huschblackwell.com{" "}
              </a>
            </Typography>
          </Grid>
          <Grid item sx={{ p: 2.35 }} xs={6}>
            <TableContainer component={Paper} className="borderless-table-container">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell colSpan={5}
                                      sx={{
                                        textAlign: "left",
                                        textDecoration: "underline",
                                      }}>
                      General Questions
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell width="30%">Contact</StyledTableCell>
                    <StyledTableCell align="center" width="20%">
                      Ext
                    </StyledTableCell>
                    <StyledTableCell align="center" width="25%">
                      Work Phone
                    </StyledTableCell>
                    <StyledTableCell align="center" width="25%">
                      Mobile
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows1.map((row) => (
                    <TableRow key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}>
                      <StyledTableCell component="td" scope="row" width="30%">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="20%">
                        {row.ext}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="25%">
                        {row.workphone}
                      </StyledTableCell>
                      <StyledTableCell align="center" width="25%">
                        {row.mobile}
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body1" component="p" className="fs-12" sx={{ p: 1 }}>
              You can also email your questions to{" "}
              <a href="mailto:PCR.Support@huschblackwell.com">
                PCR.Support@huschblackwell.com{" "}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Contacts;