import { Typography, Card, CardHeader, CardContent, IconButton, CardActionArea} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IEconomicScClient } from "../../models/IEconomicScClient";
import { useState } from "react";
import Confirmation from "../common/Confirmation";

interface CrmClientProps {
  client: IEconomicScClient;
  disabled?: boolean;
  edit(arg0: IEconomicScClient): void;
  remove(id: number): void;  
}

const ScClientCard: React.FunctionComponent<CrmClientProps> = ({
  client,
  disabled,
  edit,
  remove
 }): JSX.Element => {

  const [open, setOpen] = useState(false);
  const confirmationText = "Are you sure you want to delete this client?  This process cannot be undone.";

  function onConfirmationOk() {
    setOpen(false);
		if (disabled) return;
		remove(client.economicScClientId);
	}

  const onEditClient = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
    edit(client);
	}
  
  return (
    <div>
      <Card className="card">
        <CardHeader
          title={
            <Typography className="fs-14">
              {client.clientNumber} - {client.clientName}
            </Typography>
          }
          action={!disabled
                  ? <IconButton onClick={() => setOpen(true)}>
                      <HighlightOffIcon color="error" sx={{ fontSize: "18px"}}/>
                    </IconButton>
                  : null
                }
          className="card-header"/>
        <CardActionArea onClick={onEditClient}>
          <CardContent className="card-body">
            <Typography className="fs-14">
              Percentage Shared: <b>{client.clientShared}</b>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Confirmation open={open}
                    onClose={() => setOpen(false)}
                    onOk={onConfirmationOk}
                    title={confirmationText} />
    </div>
  )
}

export default ScClientCard;