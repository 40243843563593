import { baseUrl } from "../../utils/authConfig";

const initialState = { };

export const actionCreators = {
	addSubmitLogItem: () => async (_dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		const userName = state.header.name;
		const description = `Submitted data for ${userName} (${userId})`;
		const data = {logTypeId: 5, description: description };
		const url = `${baseUrl}api/LogItems`;
		try {
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				return;
			}
			await response.json();
		} catch (exception) {
			return;
		}
	},
	updateSubmitDateTime: () => async (_dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		const url = `${baseUrl}api/Users/Submit/${userId}`;
		try {
			const response = await fetch(url,
				{
					method: "PUT",
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				return;
			}
			await response.json();
		} catch (exception) {
			return;
		}
	}

};

export const reducer = (state: any) => {
  state = state || initialState;
	return state;
};