import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import "./economic.scss";
import TextArea from "../common/textarea/TextArea";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import EconomicCrmClient from "./EconomicCrmClient";
import EconomicScClient from "./EconomicScClient";
import EconomicMA from "./EconomicMA";
import EconomicNccClient from "./EconomicNccClient";
import EconomicWA from "./EconomicWA";
import EconomicPcClient from "./EconomicPcClient";
import { actionCreators as summaryCommentsActionCreators } from "../../store/reducers/SummaryComments";
import { actionCreators as partnerCommentsActionCreators } from "../../store/reducers/PartnerComments";
import { actionCreators as economicActionCreators } from "../../store/reducers/Economic";
import { actionCreators as economicHistoryActionCreators } from "../../store/reducers/EconomicHistory";
import {
  canEditProxyUserSelector,
  canEditSelector,
  partnerCommentsEnabledSelector,
  planYearSelector,
  planYearTextSelector,
  reviewYearSelector,
  reviewYearTextSelector,
  isExceptionUserSelector,
} from "../../store/reducers/Settings";
import NavigationBtn from "../common/navigation-btn/navigation-btn";

const TabEconomic: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.summaryCommentsActions.loadSummaryComments();
      props.partnerCommentsActions.loadPartnerComments();
      props.economicActions.requestEconomic();
      props.economicHistoryActions.requestEconomicHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const summaryCommentForCurrentYear =
    props.summaryComments.summaryCommentCurrentYear;
  const summaryCommentForPlanYear =
    props.summaryComments.summaryCommentPlanYear;
  const partnerCommentsReviewYear = props.partnerComments.data;

  const canEditPlanSummaryComments = props.canEdit || props.canEditProxyUser;

  
  const canEditPlanYear = props.canEdit || props.canEditProxyUser;

    const handleSummaryCommentsPlan = (event: any, isCurrentYear: boolean) => {
        if (!canEditPlanSummaryComments) return;

        let summaryCommentField = event.target.name === "economicHoursComments" ? "economicHoursComments" : "economicSummaryComments";

        if (!isCurrentYear) {
        props.summaryCommentsActions.saveSummaryComments(
            {
                ...props.summaryComments.summaryCommentPlanYear,
                [summaryCommentField]: event.target.value,
            },
            isCurrentYear
        );
    } else {
        props.summaryCommentsActions.saveSummaryComments(
            {
                ...props.summaryComments.summaryCommentCurrentYear,
                [summaryCommentField]: event.target.value,
            },
            isCurrentYear
        );
    }
  };

  const handlePartnerComments = (event: any) => {
    if (!props.partnerCommentsEnabled) return;

    const { name } = event.target;
    props.partnerCommentsActions.savePartnerComments({
      ...props.partnerComments.data,
      [name]: event.target.value,
    });
  };

  const handleOnChangePlanYear = (event: any, selectedYear: any) => {
    if (!canEditPlanYear) return;

    let value = event.target.value;

    if (!Utils.isNumeric(value)) value = value.replace(/[^\d.]/g, "");
    
    props.economicActions.requestPostEconomic({
      ...selectedYear,
      [event.target.name]: value,
    });
  };

  const printNccPcReport = async (e: any, reportType: string) => {
    e.preventDefault();
    props.economicActions.requestNccPcReport(reportType);
  };

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="economic container">
      <Grid item className="header">
        <Typography variant="h5" className="title">
          Economic Contribution & Stewardship
        </Typography>
        <Typography variant="subtitle1" className="fs-18">
          <strong>Instructions: </strong>This section is intended to capture your annual individual quantitative
                  goals for each of the Firm's quantitative metrics
                  (note: The metrics below are your personal attributions in aggregate).
        </Typography>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Card>
          <CardHeader
            className="card-header"
            title={
              <Typography className="title">
                Client Relationship Manager (CRM)
              </Typography>
            }
          />
          <CardContent className="card-content">
            <Grid container>
              <Grid item xs={6}>
                <EconomicCrmClient
                                  key={props.reviewYear}
                                  yearText={props.reviewYearText}
                                  year={props.reviewYear}
                                  disabled={!props.isExceptionUser}
                                  economic={props.economic.reviewYearEconomic}
                                  onChange={(e) =>
                                      handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                                  }
                                  economicHistories={props.economicHistory.data}
                                  canShowClientsSection={props.isExceptionUser}
                                  isReviewYear={true}
                />
              </Grid>
              <Grid item xs={6} sx={{ borderLeft: "2px solid #0033a0" }}>
                <EconomicCrmClient
                  key={props.planYear}
                  yearText={props.planYearText}
                  year={props.planYear}
                  disabled={!canEditPlanYear}
                  canShowClientsSection={true}
                  economic={props.economic.planYearEconomic}
                  onChange={(e) =>
                    handleOnChangePlanYear(e, props.economic.planYearEconomic)
                  }
                  economicHistories={props.economicHistory.data}
                  isReviewYear={false}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                className="card-header"
                title={
                  <Typography className="title">
                    Sharing Credit (SC): Transition of CRM to another attorney.
                  </Typography>
                }
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item sx={{ p: 1 }} xs={6}>
                    <EconomicScClient
                      year={props.reviewYear}
                      disabled={!props.isExceptionUser}
                      economic={props.economic.reviewYearEconomic}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                      }
                      economicHistories={props.economicHistory.data}
                      canShowClientsSection={props.isExceptionUser}
                      isReviewYear={true}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ p: 1, borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <EconomicScClient
                      year={props.planYear}
                      economic={props.economic.planYearEconomic}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.planYearEconomic)
                      }
                      disabled={!canEditPlanYear}
                      canShowClientsSection={true}
                      economicHistories={props.economicHistory.data}
                      isReviewYear={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                className="card-header"
                title={
                  <Typography className="title">
                    Managing Attorney (MA): Works with the CRM to manage the
                    delivery of service to a client.
                  </Typography>
                }
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item sx={{ p: 1 }} xs={6}>
                    <EconomicMA
                      disabled={!props.isExceptionUser}
                      economic={props.economic.reviewYearEconomic}
                      canEdit={false}
                      economicHistories={props.economicHistory.data}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ p: 1, borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <EconomicMA
                      economic={props.economic.planYearEconomic}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.planYearEconomic)
                      }
                      canEdit={canEditPlanYear}
                      disabled={!canEditPlanYear}
                      economicHistories={props.economicHistory.data}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                className="card-header"
                title={
                  <Typography className="title">
                    New Client Credit (NCC): Work from a client the firm has not
                    received from in the last three years, plus work from
                    existing clients where new client credit was awarded
                    previouly but has not expired
                  </Typography>
                }
                action={
                  <Button
                    className="print-button"
                    variant="contained"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={(e) => printNccPcReport(e, "PcrNccClientReport")}
                  >
                    NCC Clients
                  { props.isLoadingNccReport && <CircularProgress size="1.5rem" color="success" /> }
                  </Button>
                }
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item sx={{ p: 1 }} xs={6}>
                    <EconomicNccClient
                      disabled={!props.isExceptionUser}
                      economic={props.economic.reviewYearEconomic}
                      canEdit={false}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                      }
                      economicHistories={props.economicHistory.data}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ p: 1, borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <EconomicNccClient
                      economic={props.economic.planYearEconomic}
                      disabled={!canEditPlanYear}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.planYearEconomic)
                      }
                      economicHistories={props.economicHistory.data}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                className="card-header"
                title={
                  <Typography className="title">
                    Proliferation Credit (PC): Sale of work for existing clients
                    to a new practice area
                  </Typography>
                }
                action={
                  <Button
                    className="print-button"
                    variant="contained"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={(e) => printNccPcReport(e, "PcrPcClientReport")}
                  >
                    PC Clients
                  { props.isLoadingPcReport && <CircularProgress size="1.5rem" color="success" /> }
                  </Button>
                }
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item sx={{ p: 1 }} xs={6}>
                    <EconomicPcClient
                      year={props.reviewYear}
                      disabled={!props.isExceptionUser}
                      economic={props.economic.reviewYearEconomic}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                      }
                      economicHistories={props.economicHistory.data}
                      canShowClientsSection={props.isExceptionUser}
                      isReviewYear={true}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ p: 1, borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <EconomicPcClient
                      year={props.planYear}
                      economic={props.economic.planYearEconomic}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.planYearEconomic)
                      }
                      disabled={!canEditPlanYear}
                      canShowClientsSection={true}
                      economicHistories={props.economicHistory.data}
                      isReviewYear={false}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                className="card-header"
                title={
                  <Typography className="title">
                    Working Attorney (WA)
                  </Typography>
                }
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item sx={{ p: 1 }} xs={6}>
                    <EconomicWA
                        disabled={!props.isExceptionUser}
                        economic={props.economic.reviewYearEconomic}
                        canEdit={false}
                        onChange={(e) =>
                            handleOnChangePlanYear(e, props.economic.reviewYearEconomic)
                        }
                        economicHistories={props.economicHistory.data}
                        summaryComment={summaryCommentForCurrentYear}
                        onSummaryChange={(e) => handleSummaryCommentsPlan(e, true)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ p: 1, borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <EconomicWA
                      economic={props.economic.planYearEconomic}
                      canEdit={canEditPlanYear}
                      disabled={!canEditPlanYear}
                      onChange={(e) =>
                        handleOnChangePlanYear(e, props.economic.planYearEconomic)
                      }
                      economicHistories={props.economicHistory.data}
                      summaryComment={summaryCommentForPlanYear}
                      onSummaryChange={(e) => handleSummaryCommentsPlan(e, false)}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography className="title">Economic Summary</Typography>
                }
                className="card-header"
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item className="p-1" xs={6}>
                    <TextArea
                      label=" Provide additional information to support planned goals. (Max 550 characters)"
                      value={Format.formatString(
                        summaryCommentForCurrentYear &&
                          summaryCommentForCurrentYear.economicSummaryComments
                      )}
                      name="economicSummaryCommentsCurrentYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, true)}
                      limit={550}
                      disabled={!props.isExceptionUser}
                    />
                    <TextArea
                      label="Partner Comments: (Max 1100 characters)"
                      value={Format.formatString(
                        partnerCommentsReviewYear &&
                          partnerCommentsReviewYear.economicComments
                      )}
                      name="economicComments"
                      onChange={handlePartnerComments}
                      limit={1100}
                      disabled={!props.partnerCommentsEnabled}
                    />
                  </Grid>
                  <Grid
                    item
                    className="p-1"
                    sx={{ borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <TextArea
                      label=" Provide additional information to support planned goals. (Max 550 characters)"
                      value={Format.formatString(
                        summaryCommentForPlanYear &&
                          summaryCommentForPlanYear.economicSummaryComments
                      )}
                      name="economicSummaryCommentsPlanningYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, false)}
                      limit={550}
                      disabled={!canEditPlanSummaryComments}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ justifyContent: "left", display: "flex", p: 1 }}>
        <NavigationBtn
          nextLink="/diversity"
          prevLink="/teamwork"
          prevBtnTxt={`< Prev`}
          nextBtnTxt={`Next >`}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    summaryComments: state.summaryComments,
    partnerComments: state.partnerComments,
    economic: state.economic,
    economicHistory: state.economicHistory,
    canEdit: canEditSelector(state),
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
    reviewYearText: reviewYearTextSelector(state),
    planYearText: planYearTextSelector(state),
    partnerCommentsEnabled: partnerCommentsEnabledSelector(state),
    canEditProxyUser: canEditProxyUserSelector(state),
    isExceptionUser: isExceptionUserSelector(state),
    isLoadingPcReport: state.economic.isLoadingPcReport,
    isLoadingNccReport: state.economic.isLoadingNccReport,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    summaryCommentsActions: bindActionCreators(
      summaryCommentsActionCreators,
      dispatch
    ),
    partnerCommentsActions: bindActionCreators(
      partnerCommentsActionCreators,
      dispatch
    ),
    economicActions: bindActionCreators(economicActionCreators, dispatch),
    economicHistoryActions: bindActionCreators(
      economicHistoryActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabEconomic);
