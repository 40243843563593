import { createSelector } from "reselect";
import { baseUrl } from "../../utils/authConfig";

const setAdminEmailLoading = "SET_ADMIN_EMAIL_LOADING";
const setPartnerListData = "SET_PARTNER_LIST_DATA";
const setResultData = "SET_RESULT_DATA";

const adminEmailSelector = (state: { adminEmail: any }) => state.adminEmail;

export const currentPartnerOptionSelector = createSelector(
  adminEmailSelector,
  (adminEmail) => {
    const currentPartnerOptions = adminEmail.partnerListData.map((u: any) => ({
      value: u.ntId,
      label: `${u.name} (${u.sbu})`,
      userId: u.userId,
      name: u.name,
      lastNameFirst: u.lastNameFirst,
      isAttorney: u.isAttorney,
    }));
    return currentPartnerOptions;
  }
);

const initialState = {
  isLoading: false,
  partnerListData: [],
  resultData: [],
};

export const actionCreators = {
  loadPartners: () => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.adminEmail.isLoading) {
      return;
    }

    dispatch({
      type: setAdminEmailLoading,
      payload: true,
    });
    const url = `${baseUrl}api/Users/Attorneys`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      const responseData = await response.json();
      dispatch({
        type: setPartnerListData,
        payload: responseData,
      });
    } finally {
      dispatch({
        type: setAdminEmailLoading,
        payload: false,
      });
    }
  },
  sendEmail: (data: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.adminEmail.isLoading) {
      return;
    }

    try {
      dispatch({
        type: setAdminEmailLoading,
        payload: true,
      });
      const url = `${baseUrl}api/Reports/Quarterly`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      const responseData = await response.json();
      dispatch({
        type: setResultData,
        payload: responseData,
      });
    } finally {
      dispatch({
        type: setAdminEmailLoading,
        payload: false,
      });
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case setAdminEmailLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case setPartnerListData: {
      return {
        ...state,
        partnerListData: action.payload,
      };
    }
    case setResultData: {
      return {
        ...state,
        resultData: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
