import { baseUrl } from "../../utils/authConfig";
import diversityUtil from "../../utils/diversityUtil";

const requestDiversityAttyDashType = "REQUEST_DIVERSITY_ATTY_DASH";
const receiveDiversityAttyDashType = "RECEIVE_DIVERSITY_ATTY_DASH";
const receiveDiversityAttyDashErrorType = "RECEIVE_DIVERSITY_ATTY_DASH_ERROR";

const initialState = {
	isLoaded: false,
	isLoading: false,
	data: {
		crmxMAWoman: '',
		crmxMALGBTQ: '',
		crmxMAEth: '',
		crmxWAEth: '',
		crmxWAWoman: '',
		crmxWaLGBTQ: '',
		maxWAEth: '',
		maxWALGBTQ: '',
		maxWAWoman: '',
		pcWoman: '',
		pscUnderRace: '',
		pscLGBTQ: '',
		firmWoman: '',
		firmUnderRace: '',
		firmLGBTQ: ''
	}
};

export const actionCreators = {
	requestDiversityAttyDash: () => async (dispatch: any, getState: any) => {
		var state = getState();

		const { userId } = state.auth;

		if (state.diversity.isLoading) {
			return;
		}

		dispatch({ type: requestDiversityAttyDashType });
		const url = baseUrl + `api/DiversityDashboard/DiversityAttyDash/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveDiversityAttyDashErrorType, response: response });
				return;
			}
			const diversityData = await response.json();
			const diveristyDataConverted = diversityUtil.convertPercentagesFromServer(diversityData);
			dispatch({ type: receiveDiversityAttyDashType, data: diveristyDataConverted });
		} catch (exception) {
			dispatch({ type: receiveDiversityAttyDashErrorType, exception: exception });
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestDiversityAttyDashType) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveDiversityAttyDashType) {
		return {
			...state,
			isLoading: false,
			data: action.data
		};
	}

	if (action.type === receiveDiversityAttyDashErrorType) {
		return {
			...state,
			isLoading: false
		};
	}

	return state;
};