import { useState } from "react";
import { Percent } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { IEconomicScClient } from "../../models/IEconomicScClient";
import ClientAutoComplete from "../common/client-autocomplete/client-autocomplete";
import Input from "../common/input/Input";
import Utils from "../../utils/Utils";
import Format from "../../utils/Format";
import { IClientLookup } from "../../models/IClientLookup";

interface EconomicScClientAddEditProps {
  accessToken: string;
  isNew?: boolean;
  title: string;
  year?: number;
  reviewYear:number;
  canEdit?:boolean;
  client?: IEconomicScClient;
  close(): void;
  save(arg0: IEconomicScClient, isReviewYear: boolean): void;
  isReviewYear: boolean;
}

const EconomicScClientAddEdit: React.FunctionComponent<EconomicScClientAddEditProps> = ({
  accessToken,  
  isNew,
  title,
  year,
  reviewYear,
  canEdit,
  client,
  save,
  close,
  isReviewYear
}): JSX.Element => {

  const [tempScClient, setScClient] = useState(client || {year: year} as IEconomicScClient);
  const[defaultValidationState, setDefaultValdationState] = useState<boolean|null>(null);

  const disabled = client && client.year === reviewYear && !isReviewYear; 

  const valid = Utils.getValidationStateText(tempScClient.clientShared, defaultValidationState);

  const isClientComplete = (client: IEconomicScClient) => {		
		if (client === undefined || client === null) return false;
		const result = Utils.isTextComplete(client.clientNumber) &&
			Utils.isTextComplete(client.clientName)
			&& Utils.isTextComplete(client.clientShared)
		return result;
	}
  
  const onSave = () => {

		if (!canEdit) {
			close();
			return;
		}		
    
		const isScClientComplete = isClientComplete(tempScClient);

		if (!isScClientComplete) {
			setDefaultValdationState(false);
			return;
		}

		save(tempScClient, isReviewYear);
  }

  const handleClientChange = (value : IClientLookup) => {
		if (!canEdit) return;

    setScClient({
      ...tempScClient,
      clientNumber: value.client,
      clientName:value.clientName
    });
	}

  const handleChange = (event:  any) => {
    if (!canEdit) return;

    const { name, value } = event.target;
    setScClient({
      ...tempScClient,
      [name]: value,
    });
  };

  return (
    <div>
      <Dialog open={true}                   
              scroll="paper"
              fullWidth={true}
              maxWidth="xs">
        <DialogTitle id="scroll-dialog-title" sx={{ textAlign: "center", pb: 0 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Typography sx={{ textAlign: "left", fontSize: 22, pb: 2 }}>
                {title}
              </Typography>
            </div>
            <div>
              <IconButton onClick={close} sx={{ textAlign: "right", fontSize: 22 }}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {
            isNew &&
            <ClientAutoComplete accessToken={accessToken} 
                                fullWidth 
                                label="Client Name/Number:" 
                                onChange={handleClientChange} 
                                valid={Utils.getValidationStateText(tempScClient.clientNumber, defaultValidationState)} />
          }
          <div style={{marginTop: '15px'}}>
            <Input  placeholder="Client Number"
                    value={Format.formatString(tempScClient.clientNumber)}
                    disabled
                    fullWidth
                    name="clientNumber"
                    label="Client Number:" 
                    valid={Utils.getValidationStateText(tempScClient.clientNumber, defaultValidationState)} />
          </div>
          <div style={{marginTop: '15px'}}>
            <Input  placeholder="Client Name"
                    value={Format.formatString(tempScClient.clientName)}
                    disabled
                    fullWidth
                    name="clientName"
                    label="Client Name:"
                    valid={Utils.getValidationStateText(tempScClient.clientName, defaultValidationState)} />
          </div>
          <div style={{marginTop: '15px'}} className="economic-client">
            <Typography variant="subtitle2" sx={{fontWeight: 700}}>Percentage of client being shared: </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end',}}>
              <Percent sx={{ background: "#e9ecef", borderRadius:"0.25rem", border: "1px solid #ced4da", padding:"6px" }} />
              <FormControl size="small" fullWidth>
                <Select labelId="sharedClient"
                        id="sharedClient"
                        name="clientShared"
                        disabled={!canEdit || disabled}
                        value={tempScClient.clientShared}
                        onChange={handleChange}
                        className={`${
                          valid === null || valid === undefined
                            ? "inputStyle"
                            : valid === true
                            ? "isValid"
                            : "isInValid"
                        }`} >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="50%">50%</MenuItem>
                  <MenuItem value="100%">100%</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="medium" className="theme-button" onClick={close}>
            Cancel
          </Button>
          {
            canEdit && !disabled &&
            <Button variant="contained" size="medium" className="theme-button" onClick={onSave}>
              Save and Close
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EconomicScClientAddEdit;