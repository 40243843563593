export const addErrorType = "ADD_ERROR";
export const clearErrorTypes = "CLEAR_ERROR";
const initialState = {
  message: null,
  exception: null,
  response: null,
};

export const actionCreators = {
  addError: (message: string, exception: any, response: any) => ({
    type: addErrorType,
    message: message,
    exception: exception,
    response: response,
  }),
  clearError: () => ({ type: clearErrorTypes }),
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  if (action.type === addErrorType) {
    return {
      ...state,
      message: action.message,
      exception: action.exception,
      response: action.response,
    };
  }

  if (action.type === clearErrorTypes) {
    return {
      ...state,
      ...initialState,
    };
  }

  return state;
};
