import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppContainer from "./components/AppContainer";
import "@material-design-icons/font";

import NotSupported from "./components/common/not-supported/not-supported";
const history = createBrowserHistory();

const store = configureStore(history);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const trident = ua.indexOf("Trident/");

  return msie > 0 || trident > 0;
};

isIE()
  ? root.render(<NotSupported />)
  : root.render(
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <AppContainer />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    );
