export const nameFilters = [
    {
      label: "Client Project",
      value: "Client Project",
      id: 1
    },
    {
      label: "Diversity",
      value: "Diversity",
      id: 2
    },
    {
      label: "Economics",
      value: "Economics",
      id: 3
    },
    {
      label: "PCR Planning",
      value: "PCR Planning",
      id: 4
    },
    {
      label: "PCR Comments",
      value: "PCR Comments",
      id: 5
    },
    {
      label: "SBUL Recommendations",
      value: "SBUL Recommendations",
      id: 6
    },
    {
      label: "SBUL Condensed Recommendations",
      value: "SBUL Condensed Recommendations",
      id: 9
    },
 ];