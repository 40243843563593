import { Container, Grid, Typography } from "@mui/material";
import "../admin.scss";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import AdminTable from "../AdminTable/AdminTable";
import ExportXlsx from "../../common/ExportXlxs";
import { connect } from "react-redux";
import { baseUrl } from "../../../utils/authConfig";
import Format from "../../../utils/Format";

 type AdminStatusReportModel = {
  name: string
  sbu: string
  lastLogin: string
  lastSubmit: string
}

const AdminStatusReport: React.FunctionComponent = (
  props: any
): JSX.Element => {
  const columns = useMemo<ColumnDef<AdminStatusReportModel>[]>(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "sbu",
        header: () => "SBU",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "lastLogin",
        header: "Last Login",
        footer: (props) => props.column.id
      },
      {
        accessorKey: "lastSubmit",
        header: "Last Submit",
        footer: (props) => props.column.id
      },
    ],
    []
  );

  const [, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
 
  useEffect(() => {   
    if(props.auth.accessToken){      
      loadUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const loadUsers = async () => {
    const accessToken = props.auth.accessToken;

    try {
      const url = `${baseUrl}api/Users/AttorneysStatusReport`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        setLoading(false);
        return;
      }
      const data = await response.json();
      const userList = data.map((user: any) => {
        return {
          userId: user.userId,
          name: user.lastNameFirst || user.name,
          sbu: user.sbu,
          lastLogin: Format.formatDateTime(user.lastLogin),
          lastSubmit: Format.formatDateTime(user.lastSubmit),
        };
      });
      setUsers(userList);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const defaultSorted = [{ id: "name", desc: false }];

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5">Admin Status Report</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is a list of all of the current users who are required to fill
          out the forms and their statuses:
          <ExportXlsx data={users} filename="StatusReport" />
        </Typography>
      </Grid>
      <Grid>
        <AdminTable
          {...{
            data:users,
            columns,
            showFilter: false,
            showPagination: true,
            defaultSorted
          }}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
	return {
		auth: state.auth
	};
};

export default connect(mapStateToProps,null)(AdminStatusReport);
