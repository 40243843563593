import { baseUrl } from "../../utils/authConfig";
import { reviewYearSelector, planYearSelector } from "../../store/reducers/Settings";

const receiveReport = "RECEIVE_REPORT";
const receiveReportErrorType = "RECEIVE_REPORT_ERROR";
const requestCommentsReport = "REQUEST_COMMENTS_REPORT";
const requestPlanningReport = "REQUEST_PLANNING_REPORT";
const requestPreviousCommentsReport = "REQUEST_PREVIOUS_COMMENTS_REPORT";
const requestPreviousPlanningReport = "REQUEST_PREVIOUS_PLANNING_REPORT";
const receivePreviousReport = "RECEIVE_PREVIOUS_REPORT";
const receivePreviousReportErrorType = "RECEIVE_PREVIOUS_REPORT_ERROR";


const initialState =
{
	isLoading: false,
    isLoadingPrevious: false
};

export const actionCreators = {
	requestPartnerPcrReport: (previousReport: any, reportType: any) => async (dispatch : any, getState : any) => {
		var state = getState();

		if (state.report.isLoading) {
			return;
		}

        var userId = getState().auth.userId;
        var year = 0;
        var accessToken= getState().auth.accessToken;

        if (previousReport === true) {
            if (reportType === "PcrCommentsReport") {
                year = reviewYearSelector(state) - 1;
                dispatch({ type: requestPreviousCommentsReport });
            } else {
                year = planYearSelector(state) - 1;
                dispatch({ type: requestPreviousPlanningReport });
            }
        } else {
            if (reportType === "PcrCommentsReport") {
                year = reviewYearSelector(state);
                dispatch({ type: requestCommentsReport });
            } else {
                year = planYearSelector(state);
                dispatch({ type: requestPlanningReport });
            }
        }

		const url = baseUrl + `api/Reports/PartnerPcrReport/${userId}/${year}/${reportType}`;
		try {
			await fetch(url,
        {
          method: "GET",
          headers: {
            'Content-Type': "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
          credentials: "same-origin"
        })
      .then(async (res : any) => {
        if (res) {
          const filename = res?.headers?.get("content-disposition").split('"')[1];
            
          return { response: await res.blob(), filename: filename }
        }
        return { response: null, filename: '' }
      })
      .then(({ response, filename }) => {
        if (response && filename) {
          var file = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.download = filename;
          link.href = file;
          document.body
            .appendChild(
              link); 
          link.click();
          link.remove();
        }
      });
        if (previousReport === true) {
            dispatch({ type: receivePreviousReport });
        } else {
            dispatch({ type: receiveReport });
        }
		} catch (exception) {
            if (previousReport === true) {
                dispatch({ type: receivePreviousReportErrorType, exception: exception });
            } else {
                dispatch({ type: receiveReportErrorType, exception: exception });
            }
		}
	}
};

export const reducer = (state : any , action : any) => {
	state = state || initialState;

	if (action.type === requestCommentsReport) {
		return {
			...state,
			isLoading: true
		};
	}

    if (action.type === requestPlanningReport) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveReport) {
		return {
			...state,
			isLoading: false
		};
	}

	if (action.type === receiveReportErrorType) {
		return {
			...state,
			isLoading: false
		};
	}

    if (action.type === requestPreviousCommentsReport) {
		return {
			...state,
			isLoadingPrevious: true
		};
	}

    if (action.type === requestPreviousPlanningReport) {
		return {
			...state,
			isLoadingPrevious: true
		};
	}

	if (action.type === receivePreviousReport) {
		return {
			...state,
			isLoadingPrevious: false
		};
	}

	if (action.type === receivePreviousReportErrorType) {
		return {
			...state,
			isLoadingPrevious: false
		};
	}

	return state;
};