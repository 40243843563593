import { useState } from "react";
import {
  Avatar,
  Button,  
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  CircularProgress
} from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink } from 'react-router-dom';
import NavigationBtn from "../common/navigation-btn/navigation-btn";
import Submit from "../common/Submit";
import './summary.scss'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as homeActionCreators} from "../../store/reducers/Home";
import { actionCreators } from "../../store/reducers/Reports";

const TabSummary: React.FunctionComponent = (props: any): JSX.Element => {
  const [open, setOpen] = useState(false);
  
  const handleSubmitOk = () => {
    setOpen(false);
    props.homeActions.updateSubmitDateTime();
    props.homeActions.addSubmitLogItem();
  };

  const printCommentsReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(false, "PcrCommentsReport");
  };

  const printPlanningReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(false, "PcrPlanningReport");
  };

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="summary container">
      <Grid item className="header">
        <Typography variant="h5">Summary</Typography>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Card>
          <CardHeader
            title={
              <Typography className="title">Summary Highlights</Typography>
            }
            className="card-header" />
          <CardContent className="card-content">
            <Typography className="fs-14" sx={{ mb: 1, fontWeight: "700" }}>
              Congratulations! You have successfully completed the Partner
              Contribution Report.
            </Typography>
            <Typography variant="body1" variantMapping={{ body1: "p" }} className="fs-14">
              Just a few more steps to finish up:
            </Typography>
            <Grid container>
              <Grid item className="p-2" xs={4}>
                <Box>
                  <Typography variant="body1" component="p" className="fs-14">
                    1. Please take a moment to print of a copy of your Partner
                    Contribution Report for your records.
                  </Typography>
                  <Stack  direction="row-reverse" justifyContent="center"
                          alignItems="center" spacing={2} className="print">
                    {props.isLoading ? (
                    <IconButton className="noBackground">
                      {" "}
                      <CircularProgress color="success" />{" "}
                    </IconButton>
                    ) : null}
                    <Button size="small" variant="contained" onClick={printPlanningReport}>
                      Print 2024 Report
                    </Button>
                    <Button size="small" variant="contained" onClick={printCommentsReport}>
                      Print 2023 Report
                    </Button>
                    <Avatar
                      alt="Remy Sharp"
                      src="/assets/img/printer.png"
                      sx={{ width: 75, height: 75 }}
                      variant="square" />
                  </Stack>
                </Box>
              </Grid>
              <Grid item className="p-2" xs={4}>
                <Box>
                  <Typography
                    variant="body1"
                    component="p"
                    className="fs-14 text-center">
                    2. Press "Submit" to submit a copy of your report.
                  </Typography>
                  <Stack spacing={2} direction="row" sx={{ my: 2, justifyContent: "center" }}>
                    <IconButton onClick={() => setOpen(true)} >
                      <Avatar alt="Submit" component={RouterLink} to="#"
                              src="/assets/img/submitbutton.png"
                              sx={{ width: 70, height: 70 }} variant="square"/>
                    </IconButton>
                  </Stack>
                </Box>
              </Grid>
              <Grid item className="p-2" xs={4}>
                <Box>
                  <Typography variant="body1" component="p" className="fs-14">
                    3. Done! Close your web browser to exit.
                  </Typography>
                  <Typography variant="body1" component="p" className="fs-14" sx={{ mt: 2 }}>
                    If you need to make additional changes, simply click the
                    "Home" button located at the top of this page OR go to the
                    specific section.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item className="p-1" sx={{ justifyContent: "left", display: "flex" }}>
        <NavigationBtn  nextLink="/" 
                        prevLink="/yearEndReview" 
                        prevBtnTxt={`< Prev`} 
                        nextBtnTxt={`Home >`} />
      </Grid>
      <Submit open={open} onClose={() => setOpen(false)} onOk={handleSubmitOk} />
    </Container>
  );
};


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    report: state.report,
    isLoading: state.report.isLoading
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    homeActions: bindActionCreators(homeActionCreators, dispatch),
    actionCreators: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabSummary);