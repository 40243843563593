import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import AddNewGoal from "../AddNewGoal/AddNewGoal";
import Goal from "../Goal";

interface GoalSectionProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  handleRemoveGoal: any;
  handleUpdateGoal: any;
  handleAddGoal: any;
  goals: any;
  reviewYear: any;
  reviewYearText: any;
  planYear: any;
  planYearText: any;
  partnerCommentsVisible: boolean;
  canEdit: boolean;
  partnerCommentsEnabled: boolean;
  sbuLeaderCommentsEnabled: boolean;
  isExceptionUser: boolean;
  canEditProxyUser: boolean;
}

const GoalSection: React.FunctionComponent<GoalSectionProps> = ({
  handleRemoveGoal,
  handleUpdateGoal,
  handleAddGoal,
  goals,
  reviewYear,
  reviewYearText,
  planYear,
  planYearText,
  partnerCommentsVisible,
  partnerCommentsEnabled,
  sbuLeaderCommentsEnabled,
  canEdit,
  canEditProxyUser,
  isExceptionUser
}): React.ReactElement => {

  const goalsMaxLength = 3;
  const canEditPlanGoal = canEdit || canEditProxyUser;

  const renderReviewGoals = () => {
    const reviewGoals = goals.filter((goal: any) => goal.year === reviewYear);
    return reviewGoals?.map((goal: any) => {
      return (
        <Goal
          key={goal.goalId.toString()}
          canEdit={isExceptionUser}
          goal={goal}
          deleteGoal={handleRemoveGoal}
          updateGoal={handleUpdateGoal}
          partnerCommentsVisible={partnerCommentsVisible}
        />
      );
    });
  };

  const renderPlanGoals = () => {
    const planGoals = goals.filter((goal: any) => goal.year === planYear);
    return planGoals?.map((goal: any) => {
      return (
        <Goal
          key={goal.goalId.toString()}
          canEdit={canEditPlanGoal}
          goal={goal}
          deleteGoal={handleRemoveGoal}
          updateGoal={handleUpdateGoal}
          partnerCommentsEnabled={partnerCommentsEnabled}
          partnerCommentsVisible={partnerCommentsVisible}
          sbuLeaderCommentsEnabled={sbuLeaderCommentsEnabled}
        />
      );
    });
  };

  const renderNewGoal = (year: any) => {
    const planGoals = goals.filter((goal: any) => goal.year === year);
    const newGoalVisible = canEditPlanGoal && planGoals.length < goalsMaxLength;
    if (!newGoalVisible) return;

    return <AddNewGoal addGoal={handleAddGoal} year={year} />;
  };

  return (
    <>
      <Card>
        <CardHeader
          title={<Typography className="title">Qualitative Goals</Typography>}
          className="card-header"
        />
        <CardContent className="card-content">
          <Grid container>
            <Grid item xs={6}>
              <Grid item>
                <Chip label={reviewYearText} className="header-chip" />
              </Grid>
              <Grid item className="p-1">
                <Typography variant="subtitle1" className="fs-14">
                  Goals for current year.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  paddingBottom: "16px",
                }}
              >
                <Stack direction="row" sx={{ m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                      },
                      textAlign: "center",
                    }}
                    className="sticky-note"
                  >
                    {renderReviewGoals()}
                  </Box>
                </Stack>
              </Grid>
             {isExceptionUser ? (
              <Grid
                item
                sx={{
                  paddingBottom: "16px",
                }}
              >
                <Stack direction="row" sx={{ m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                      },
                      textAlign: "center",
                    }}
                    className="add-new-goal"
                  >
                    {renderNewGoal(reviewYear)}
                  </Box>
                </Stack>
              </Grid> ) : null}
            </Grid>
            <Grid
              item
              sx={{
                borderLeft: "2px solid #0033a0",
              }}
              xs={6}
            >
              <Grid item>
                <Chip label={planYearText} className="header-chip" />
              </Grid>
              <Grid item className="p-1">
                <Typography variant="subtitle1" className="fs-18">
                  <strong>Instructions: </strong>Please add 2-3 qualitative goals to complete this section.
                </Typography>
                <Typography variant="subtitle1" className="fs-14">
                  To <b>edit</b> a goal click on the post-it below.
                </Typography>
                <Typography variant="subtitle1" className="fs-14">
                  To <b>add</b> a goal click on the "Add new goal" post-it.
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  paddingBottom: "16px",
                }}
              >
                <Stack direction="row" sx={{ m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                      },
                      textAlign: "center",
                    }}
                    className="sticky-note"
                  >
                    {renderPlanGoals()}
                  </Box>
                </Stack>
              </Grid>
              <Grid
                item
                sx={{
                  paddingBottom: "16px",
                }}
              >
                <Stack direction="row" sx={{ m: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                      },
                      textAlign: "center",
                    }}
                    className="add-new-goal"
                  >
                    {renderNewGoal(planYear)}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default GoalSection;
