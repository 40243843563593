import {
  Autocomplete,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from "@mui/material";
import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";
import "../admin.scss";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import AdminTable from "../AdminTable/AdminTable";
import { baseUrl } from "../../../utils/authConfig";
import Format from "../../../utils/Format";
import { connect } from "react-redux";
import { IAdminReportModel } from "../../../models/IAdminReport";
import { defaultSorted } from "../../../utils/defaultsort";
import { nameFilters } from "../../../utils/namefilter";
import { sortOrders } from "../../../utils/sortorder";
import { planYearSelector, reviewYearSelector } from "../../../store/reducers/Settings";
import { sbuReportFilter } from "../../../utils/sbuReportFilter";
import ReportBackdrop from "../../common/reportbackdrop/reportbackdrop";

const AdminReports: React.FunctionComponent = (props: any): JSX.Element => {

  const columns = useMemo<ColumnDef<IAdminReportModel>[]>(
    () => [
      {
        accessorKey: "name",
        header: () => "Name",
        footer: (props) => props.column.id,
        type: "list",
        options: nameFilters,
      },
      {
        accessorKey: "sbu",
        header: () => "SBU",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "sortOrder",
        header: ()=> "Sort Order",
        footer: (props) => props.column.id,
        type: "list",
        options: sortOrders,
      },
    ],
    []
  );

  const [excludeSbu, setExcludeSbu] = useState(false);
  const [excludedUserOptions, setExcludedUserOptions] = useState([]);
  const [includedUserOptions, setIncludedUserOptions] = useState([]);
  const [excludedUserValues, setExcludedUserValues] = useState([]);
  const [includedUserValues, setIncludedUserValues] = useState([]);
  const [reports, setReports] = useState([]);
  const [showBackdop, setBackdrop] = useState(false);

  useEffect(() => {
    if (props.auth.accessToken) {
        loadUsers();
        loadReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const loadUsers = async () => {
        const accessToken = props.auth.accessToken;
        
        const url = `${baseUrl}api/Users/Attorneys`;
        const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
        });
        if (!response.ok) {
        return;
        }

        let data = await response.json();
        data = data.sort((a: any,b: any) => a.lastNameFirst.localeCompare(b.lastNameFirst));

        const excludedUserOptions = data.map((u: any, index: any) => ({
        key: index,
        value: u.ntId,
        label: `${u.lastNameFirst} (${u.sbu})`,
        userId: u.userId,
        name: u.name,
        lastNameFirst: u.lastNameFirst,
        isAttorney: u.isAttorney,
        }));

        const includedUserOptions = data.map((u: any) => ({
        key: u.userId,
        value: u.ntId,
        label: `${u.lastNameFirst} (${u.sbu})`,
        userId: u.userId,
        name: u.name,
        lastNameFirst: u.lastNameFirst,
        isAttorney: u.isAttorney,
        }));

        setExcludedUserOptions(excludedUserOptions);
        setIncludedUserOptions(includedUserOptions);
  };

  const loadReports = async () => {
      const accessToken = props.auth.accessToken;
        
        const url = `${baseUrl}api/Reports/AdminReport`;
        const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
        });
        if (!response.ok) {
        return;
        }

        let data = await response.json();
        setReports(data);
      }

  const handleReportChange = (event: any, value: any) => {
    if (!value) return;
      openReport(value);
  };

  const openReport = async (report: any) => {

    sbuReportFilter.year = report.name === "PCR Planning" ? props.planYear : props.reviewYear;
    sbuReportFilter.type = report.type;
    sbuReportFilter.sbu = report.sbu;
    sbuReportFilter.sortOrder = sortOrders.find((value) => value.value === report.sortOrder)!.id.toString();
    sbuReportFilter.excludeSbuLeader = excludeSbu;
    sbuReportFilter.excludeUsers = excludedUserValues.map((u: { userId: any; }) => u.userId);
    sbuReportFilter.includeUsers = includedUserValues.map((u: { userId: any; }) => u.userId);

    setBackdrop(true);
    const accessToken = props.auth.accessToken;
    const url = `${baseUrl}api/Reports/SbuReport`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(sbuReportFilter),
      credentials: "same-origin",
    })
      .then(async (res) => {
        if (res) {
          const filename = res?.headers
            ?.get("content-disposition")
            ?.split('"')[1];
          return { response: await res.blob(), filename: filename };
        }
        return { response: null, filename: "" };
      })
      .then(({ response, filename }) => {
        setBackdrop(false);
        if (response && filename) {
          var file = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.download = filename;
          link.href = file;
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
  };

  const handleCheckbox = (event: any) => {
    setExcludeSbu(event.target.checked);
  };

  const handleExcludedUsers = (selectedOptions: any) => {
    setExcludedUserValues(selectedOptions);
  };

  const handleIncludedUsers = (selectedOptions: any) => {
    setIncludedUserValues(selectedOptions);
  };

  const handleRowClick = (rowData: any) => {
    if (!rowData) return;
    openReport(rowData);
  };

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
     <ReportBackdrop show={showBackdop}></ReportBackdrop>    
      <Grid item className="header">
        <Typography variant="h5" sx={{ mb: 2 }}>
          Admin Reports
        </Typography>
        <Typography variant="h5">SBU Reports</Typography>
      </Grid>
      <Grid item sx={{ pl: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ py: 0.5 }}
                id="excludeSbu"
                checked={Format.formatCheckboxInput(excludeSbu)}
                onChange={handleCheckbox}
              />
            }
            label="Exclude SBU Leader from Reports"
          />
        </FormGroup>
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <div style={{ width: "100%", display: "flex" }}>
          <InputLabel className="label">Excluded Users:</InputLabel>
        </div>
        <Select
            options={excludedUserOptions}
            onChange={handleExcludedUsers}
            isClearable
            isMulti
            value={excludedUserValues}
            closeMenuOnSelect={false}
            className="user-react-select"
        />
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <div style={{ width: "100%", display: "flex" }}>
          <InputLabel className="label">Included Users:</InputLabel>
        </div>
        <Select
          options={includedUserOptions}
          onChange={handleIncludedUsers}
          isClearable
          isMulti
          value={includedUserValues}
          closeMenuOnSelect={false}
          className="user-react-select"
        />
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <InputLabel className="label">Reports:</InputLabel>
          <Autocomplete
          id="admin-reports-autocomplete"
          options={reports}
          placeholder="Search for reports"
          getOptionLabel={(option: any) => `${option.name} - ${option.sbu} - ${option.sortOrder}`}
          onChange={handleReportChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              className="group-textfield"
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
             />
         )}
      />
      </Grid>
      <Grid>
        <AdminTable
          {...{
            data: reports,
            columns,
            showFilter: true,
            showPagination: true,
            defaultSorted,
            handleRowClick: handleRowClick,
            rowClickable: true,
          }}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
  };
};

export default connect(mapStateToProps, null)(AdminReports);