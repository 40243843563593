import * as React from "react";

const NotSupported: React.FunctionComponent = (): React.ReactElement => {
  return (
    <>
      <div style={{ justifyContent: "center", display: "flex" }}>
        Partner Contribution Report doesn't support Internet Explorer 11 or
        lower.&nbsp; Please use &nbsp;
        <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
          Chrome
        </a>
        &nbsp;or&nbsp;
        <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
          Edge
        </a>
        .
      </div>
    </>
  );
};

export default NotSupported;
