import { actionCreators as errorActionCreators } from "./Error";
import FetchQueue from "../../utils/FetchQueue";
import { baseUrl } from "../../utils/authConfig";
import { planYearSelector, reviewYearSelector } from "./Settings";

const requestEconomicType = "REQUEST_ECONOMIC";
const receiveEconomicReviewYearType = "RECEIVE_ECONOMIC_REVIEW_YEAR";
const receiveEconomicPlanYearType = "RECEIVE_ECONOMIC_PLAN_YEAR";
const receiveEconomicErrorType = "RECEIVE_ECONOMIC_ERROR";
const requestPostEconomicType = "REQUEST_POST_ECONOMIC";
const receivePostEconomicType = "RECEIVE_POST_ECONOMIC";
const receivePostEconomicPlanYearType = "RECEIVE_POST_ECONOMIC_PLAN_YEAR";
const receivePostEconomicErrorType = "RECEIVE_POST_ECONOMIC_ERROR";
const receiveNccReport = "RECEIVE_NCC_REPORT";
const receiveNccReportErrorType = "RECEIVE_NCC_REPORT_ERROR";
const requestNccReport = "REQUEST_NCC_REPORT";
const receivePcReport = "RECEIVE_PC_REPORT";
const receivePcReportErrorType = "RECEIVE_PC_REPORT_ERROR";
const requestPcReport = "REQUEST_PC_REPORT";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isSaving: false,
  data: [],
  reviewYearEconomic: {},
  planYearEconomic: {},
  isLoadingNccReport: false,
  isLoadingPcReport: false,
};

function convertPercentagesFromServer(data: any): any {
  const result = { ...data };
  if (data.nccDm) result.nccDm = (data.nccDm * 100).toFixed(1);
  if (data.pcDm) result.pcDm = (data.pcDm * 100).toFixed(1);
  if (data.scDm) result.scDm = (data.scDm * 100).toFixed(1);
  if (data.crmDm) result.crmDm = (data.crmDm * 100).toFixed(1);
  if (data.maDm) result.maDm = (data.maDm * 100).toFixed(1);
  return result;
}

function convertPercentagesToServer(data: any): any {
  const result = { ...data };
  if (data.nccDm) result.nccDm = data.nccDm / 100;
  if (data.pcDm) result.pcDm = data.pcDm / 100;
  if (data.scDm) result.scDm = data.scDm / 100;
  if (data.crmDm) result.crmDm = data.crmDm / 100;
  if (data.maDm) result.maDm = data.maDm / 100;
  return result;
}

const saveQueue = new FetchQueue(
  `${baseUrl}api/Economics/`,
  "PUT",
  null,
  function (dispatch: any, response: any) {
    dispatch({ type: receivePostEconomicErrorType, response: response });
    dispatch(
      errorActionCreators.addError(
        "An error occurred while updating economic data.",
        null,
        response
      )
    );
  },
  function (dispatch: any, exception: any) {
    dispatch({ type: receivePostEconomicErrorType, exception: exception });
    dispatch(
      errorActionCreators.addError(
        "An exception occurred while updating economic data.",
        exception,
        null
      )
    );
  }
);

export const actionCreators = {
    requestEconomic: () => async (dispatch: any, getState: any) => {
        var state = getState();
        const { userId } = state.auth;
        const reviewYear = reviewYearSelector(state);
        const planYear = planYearSelector(state);

        if (!userId) {
            return;
        }

        if (state.economic.data.userId === userId) {
            return;
        }
        if (state.economic.isLoading) {
            return;
        }

		dispatch({ type: requestEconomicType });
		const url = `${baseUrl}api/Economics/${userId}`;
		try {
			const response = await fetch(url,{
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveEconomicErrorType, response: response });
				return;
			}
			const data = await response.json();	
			const currentYearEconomic = data.find((c:any) => c.year === reviewYear);
			const planYearEconomic = data.find((c:any) => c.year === planYear);			
			const currentYearEconomicConverted = convertPercentagesFromServer(currentYearEconomic);
			const planYearEconomicConverted = convertPercentagesFromServer(planYearEconomic);			
			dispatch({ type: receiveEconomicReviewYearType, data: currentYearEconomicConverted });
			dispatch({ type: receiveEconomicPlanYearType, data: planYearEconomicConverted });
		} catch (exception) {
			dispatch({ type: receiveEconomicErrorType, exception: exception });
		}
	},
	requestPostEconomic: (data: any) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (!data.economicId) {			
			return;
		}
        const reviewYear = reviewYearSelector(state);
        const planYear = planYearSelector(state);

        if (planYear === data.year)
            dispatch({ type: receiveEconomicPlanYearType, data: data });
        else if (reviewYear === data.year)
            dispatch({ type: receiveEconomicReviewYearType, data: data });
    
        dispatch({ type: requestPostEconomicType, data: data });

        const data2 = convertPercentagesToServer(data);
        const url = `${baseUrl}api/Economics/${data2.economicId}`;
        saveQueue.queue(
            data2.economicId,
            data2,
            state.auth.accessToken,
            dispatch,
            url
        );
    },
    requestNccPcReport: (reportType: any) => async (dispatch : any, getState : any) => {
        var state = getState();

        if (state.economic.isLoading) {
	        return;
        }

        var userId = getState().auth.userId;
        var accessToken= getState().auth.accessToken;

        if (reportType === "PcrNccClientReport") {
            dispatch({ type: requestNccReport });
        } else {
            dispatch({ type: requestPcReport });
        }

        const url = baseUrl + `api/Reports/NccPcPartnerReport/${userId}/${reportType}`;
        try {
	        await fetch(url,
        {
            method: "GET",
            headers: {
            'Content-Type': "application/json",
            "Authorization": `Bearer ${accessToken}`
            },
            credentials: "same-origin"
        })
        .then(async (res : any) => {
        if (res) {
            const filename = res?.headers?.get("content-disposition").split('"')[1];
            
            return { response: await res.blob(), filename: filename }
        }
        return { response: null, filename: '' }
        })
        .then(({ response, filename }) => {
        if (response && filename) {
            var file = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.download = filename;
            link.href = file;
            document.body
            .appendChild(
                link); 
            link.click();
            link.remove();
        }
        });

            if (reportType === "PcrNccClientReport") {
                dispatch({ type: receiveNccReport });
            } else {
                dispatch({ type: receivePcReport });
            }
            
        } catch (exception) {
            
            if (reportType === "PcrNccClientReport") {
                dispatch({ type: receiveNccReportErrorType, exception: exception });
            } else {
                dispatch({ type: receivePcReportErrorType, exception: exception });
            }
            
        }
    },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  if (action.type === requestEconomicType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveEconomicReviewYearType) {
    return {
      ...state,
      isLoading: false,
      reviewYearEconomic: action.data,
    };
  }
  if (action.type === receiveEconomicPlanYearType) {
    return {
      ...state,
      isLoading: false,
      planYearEconomic: action.data,
    };
  }

  if (action.type === receiveEconomicErrorType) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === requestPostEconomicType) {
    const newState = {
      ...state,
      isSaving: true,
      data: action.data,
    };
    return newState;
  }

  if (action.type === receivePostEconomicType) {
    return {
      ...state,
      isSaving: false,
    };
  }

  if (action.type === receivePostEconomicPlanYearType) {
    return {
      ...state,
      isSaving: false,
    };
  }

  if (action.type === receivePostEconomicErrorType) {
    return {
      ...state,
      isSaving: false,
    };
  }

    if (action.type === receiveNccReport) {
	    return {
		    ...state,
		    isLoadingNccReport: false
	    };
    }

    if (action.type === receiveNccReportErrorType) {
	    return {
		    ...state,
		    isLoadingNccReport: false
	    };
    }

        if (action.type === requestNccReport) {
	    return {
		    ...state,
		    isLoadingNccReport: true
	    };
    }

        if (action.type === receivePcReport) {
	    return {
		    ...state,
		    isLoadingPcReport: false
	    };
    }

    if (action.type === receivePcReportErrorType) {
	    return {
		    ...state,
		    isLoadingPcReport: false
	    };
    }

        if (action.type === requestPcReport) {
	    return {
		    ...state,
		    isLoadingPcReport: true
	    };
    }

  return state;
};
