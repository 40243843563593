import { IClientProject } from "../../models/IClientProject";
import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const closeClientProjectType = "CLOSE_CLIENT_PROJECT";
const editClientProjectType = "EDIT_CLIENT_PROJECT";
const requestClientProjectsType = "REQUEST_CLIENT_PROJECTS";
const receiveClientProjectsType = "RECEIVE_CLIENT_PROJECTS";
const receiveClientProjectsErrorType = "RECEIVE_CLIENT_PROJECTS_ERROR";
const requestPostClientProjectType = "REQUEST_POST_CLIENT_PROJECT";
const receivePostClientProjectType = "RECEIVE_POST_CLIENT_PROJECT";
const receivePostClientProjectErrorType = "RECEIVE_POST_CLIENT_PROJECT_ERROR";
const requestPutClientProjectType = "REQUEST_PUT_CLIENT_PROJECT";
const receivePutClientProjectType = "RECEIVE_PUT_CLIENT_PROJECT";
const receivePutClientProjectErrorType = "RECEIVE_PUT_CLIENT_PROJECT_ERROR";
const requestDeleteClientProjectType = "REQUEST_DELETE_CLIENT_PROJECT";
const receiveDeleteClientProjectType = "RECEIVE_DELETE_CLIENT_PROJECT";
const receiveDeleteClientProjectErrorType =
  "RECEIVE_DELETE_CLIENT_PROJECT_ERROR";
const showNewClientProjectType = "SHOW_NEW_CLIENT_PROJECT";
const searchClientType = "SEARCH_CLIENT_DATA";
const searchMatterType = "SEARCH_MATTER_DATA";

const initialState = {
  editClientProjectVisible: false,
  isDeleting: false,
  isLoadedUserId: null,
  isLoading: false,
  isPosting: false,
  isPutting: false,
  newClientProjectVisible: false,
  editingClientProjectId: null,
  clientProjects: [],
  matterSearch: {
    isLoading: false,
    options: [],
    url: "",
  },
  clientSearch: {
    isLoading: false,
    options: [],
    url: "",
  },
};

export const actionCreators = {
  closeClientProject: () => ({ type: closeClientProjectType }),
  editClientProject: (data: IClientProject) => ({
    type: editClientProjectType,
    clientProjectId: data.clientProjectId,
  }),
  showNewClientProject: (visible = true) => ({
    type: showNewClientProjectType,
    visible: visible,
  }),
  loadClientProjects: () => async (dispatch: any, getState: any) => {
    const state = getState();
    const { userId } = state.auth;
    if (state.clientProjects.isLoadedUserId === userId) {
      return;
    }
    if (state.clientProjects.isLoading) {
      return;
    }

    dispatch({ type: requestClientProjectsType });
    const url = `${baseUrl}api/ClientProjects/${userId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({ type: receiveClientProjectsErrorType, response: response });
        return;
      }
      const responseData = await response.json();
      dispatch({
        type: receiveClientProjectsType,
        data: responseData,
        userId: userId,
      });
    } catch (exception) {
      dispatch({ type: receiveClientProjectsErrorType, exception: exception });
    }
  },
  addClientProject:
    (data: IClientProject) => async (dispatch: any, getState: any) => {
      const state = getState();
      if (state.clientProjects.isPosting) {
        return;
      }

      const { userId } = state.auth;
      const data3 = { ...data, userId: userId };
      dispatch({ type: requestPostClientProjectType, data: data });
      const url = `${baseUrl}api/ClientProjects`;
      try {
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(data3),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: receivePostClientProjectErrorType,
            response: response,
          });
          dispatch(
            errorActionCreators.addError(
              "An error occurred while adding a clientProject.",
              null,
              response
            )
          );
          return;
        }
        const responseData = await response.json();
        dispatch({ type: receivePostClientProjectType, data: responseData });
      } catch (exception) {
        dispatch({
          type: receivePostClientProjectErrorType,
          exception: exception,
        });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while adding a clientProject.",
            exception,
            null
          )
        );
      }
    },
  removeClientProject:
    (data: number) => async (dispatch: any, getState: any) => {
      const state = getState();
      if (state.clientProjects.isDeleting) {
        return;
      }

      dispatch({ type: requestDeleteClientProjectType, data: data });
      const url = `${baseUrl}api/ClientProjects/${data}`;
      try {
        const response = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: receiveDeleteClientProjectErrorType,
            response: response,
          });
          dispatch(
            errorActionCreators.addError(
              "An error occurred while removing a clientProject.",
              null,
              response
            )
          );
          return;
        }
        const responseData = await response.json();
        dispatch({ type: receiveDeleteClientProjectType, data: responseData });
      } catch (exception) {
        dispatch({
          type: receiveDeleteClientProjectErrorType,
          exception: exception,
        });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while removing a clientProject.",
            exception,
            null
          )
        );
      }
    },
  updateClientProject:
    (data: IClientProject) => async (dispatch: any, getState: any) => {
      const state = getState();
      if (state.clientProjects.isPutting) {
        return;
      }

      dispatch({ type: requestPutClientProjectType, data: data });
      const url = `${baseUrl}api/ClientProjects/${data.clientProjectId}`;
      try {
        const response = await fetch(url, {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: receivePutClientProjectErrorType,
            response: response,
          });
          dispatch(
            errorActionCreators.addError(
              "An error occurred while updating a clientProject.",
              null,
              response
            )
          );
          return;
        }
        const responseData = await response.json();
        dispatch({ type: receivePutClientProjectType, data: responseData });
      } catch (exception) {
        dispatch({
          type: receivePutClientProjectErrorType,
          exception: exception,
        });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while updating a clientProject.",
            exception,
            null
          )
        );
      }
    },
  searchMatter:
    (query: string, clientNumber: string | null = null) =>
    async (dispatch: any, getState: any) => {
      const state = getState();
      var search = query.trim();
      const url = `${baseUrl}api/ClientMatters/${clientNumber}/${search}`;
      dispatch({
        type: searchMatterType,
        data: {
          ...state.clientProjects.matterSearch,
          isLoading: true,
          url: url,
        },
      });
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: searchMatterType,
            data: {
              ...state.clientProjects.matterSearch,
              isLoading: false,
            },
          });
          return;
        }
        const data = await response.json();
        const options = data.map((clientMatter: any) => {
          return {
            ...clientMatter,
            id: clientMatter.id,
            label: `${clientMatter.matter} - ${clientMatter.matterName}`,
          };
        });
        dispatch({
          type: searchMatterType,
          data: {
            ...state.clientProjects.matterSearch,
            isLoading: false,
            options: options,
          },
        });
      } catch (e) {
        dispatch({
          type: searchMatterType,
          data: {
            ...state.clientProjects.matterSearch,
            isLoading: false,
          },
        });
      }
    },
  searchClient: (query: string, matterNumber: string | null = null) => async (dispatch: any, getState: any) => {
    const state = getState();
    var search = query.trim();
    const url = `${baseUrl}api/ClientMatters/ClientLookup/${search}/${matterNumber}`;

    dispatch({
      type: searchClientType,
      data: {
        ...state.clientProjects.clientSearch,
        isLoading: true,
        url: url,
      },
    });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({
          type: searchClientType,
          data: {
            ...state.clientProjects.clientSearch,
            isLoading: false,
          },
        });
        return;
      }
      const data = await response.json();
      const options = data.map((cl: any) => {
        return {
          ...cl,
          id: cl.client,
          label: `${cl.client} - ${cl.clientName}`,
        };
      });

      dispatch({
        type: searchClientType,
        data: {
          ...state.clientProjects.clientSearch,
          isLoading: false,
          options: options,
        },
      });
    } catch (e) {
      dispatch({
        type: searchClientType,
        data: {
          ...state.clientProjects.clientSearch,
          isLoading: false,
        },
      });
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case closeClientProjectType: {
      return {
        ...state,
        editClientProjectVisible: false,
        newClientProjectVisible: false,
      };
    }
    case showNewClientProjectType: {
      return {
        ...state,
        newClientProjectVisible: action.visible,
      };
    }

    case requestPostClientProjectType: {
      return {
        ...state,
        isPosting: true,
        newClientProjectVisible: false,
      };
    }

    case receivePostClientProjectType: {
      return {
        ...state,
        isPosting: false,
        clientProjects: [...state.clientProjects, action.data],
      };
    }

    case receivePostClientProjectErrorType: {
      return {
        ...state,
        isPosting: false,
      };
    }

    case requestPutClientProjectType: {
      return {
        ...state,
        isPutting: true,
        editClientProjectVisible: false,
      };
    }

    case receivePutClientProjectType: {
      return {
        ...state,
        isPutting: false,
        clientProjects: state.clientProjects.map(
          (clientProject: IClientProject) =>
            clientProject.clientProjectId === action.data.clientProjectId
              ? { ...clientProject, ...action.data }
              : clientProject
        ),
      };
    }

    case receivePutClientProjectErrorType: {
      return {
        ...state,
        isPutting: false,
      };
    }

    case requestDeleteClientProjectType: {
      return {
        ...state,
        isDeleting: true,
      };
    }

    case receiveDeleteClientProjectType: {
      return {
        ...state,
        isDeleting: false,
        clientProjects: state.clientProjects.filter(
          (clientProject: IClientProject) =>
            clientProject.clientProjectId !== action.data.clientProjectId
        ),
      };
    }

    case receiveDeleteClientProjectErrorType: {
      return {
        ...state,
        isDeleting: false,
      };
    }

    case editClientProjectType: {
      return {
        ...state,
        editClientProjectVisible: true,
        editingClientProjectId: action.clientProjectId,
      };
    }

    case requestClientProjectsType: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case receiveClientProjectsType: {
      return {
        ...state,
        isLoadedUserId: action.userId,
        isLoading: false,
        clientProjects: action.data,
      };
    }

    case receiveClientProjectsErrorType: {
      return {
        ...state,
        isLoadedUserId: null,
        isLoading: false,
        clientProjects: [],
      };
    }
    
    case searchClientType: {
      return {
        ...state,
        clientSearch: {
          ...action.data,
        },
      };
    }

    case searchMatterType: {
      return {
        ...state,
        matterSearch: {
          ...action.data,
        },
      };
    }
    default:
      return { ...state };
  }
};
