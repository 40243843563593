import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { actionCreators } from "../../store/reducers/Timetable";
import {
  planYearSelector,
  reviewYearSelector,
} from "../../store/reducers/Settings";

// eslint-disable-next-line no-empty-pattern
const Timetable: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.actionCreators.requestTimetable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken]);

  function formatData(item: any, index: number) {
    if (item.value.includes("@huschblackwell.com")) {
      var emailPosition = item.value.indexOf("@huschblackwell.com");
      var endPosition = item.value
        .substring(0, emailPosition)
        .lastIndexOf("\r\n");
      var nonEmailString = item.value.substring(0, endPosition);
      var emailAddress =
        "mailto:" + item.value.substring(endPosition, item.value.length - 1);
      var emailstring = (
        <a href={emailAddress}>
          {emailAddress.substring(
            emailAddress.indexOf(":") + 1,
            emailAddress.length
          )}
        </a>
      );
      var endString = ".";

      return (
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", marginTop: "5px" }}
          key={index}
        >
          <strong>{item.dateString}</strong> {nonEmailString} {emailstring}
          {endString}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", marginTop: "5px" }}
          key={index}
        >
          <strong>{item.dateString}</strong> {item.value}
        </Typography>
      );
    }
  }

  const timeTableEnteries =
    props.timetable && props.timetable.data
      ? props.timetable.data.map((item: any, index: number) => {
          return formatData(item, index);
        })
      : null;

  return (
    <Card>
      <CardHeader
        title={
          <Typography sx={{ color: "white", fontSize: "14px" }}>
            Expected Timetable
          </Typography>
        }
        className="card-header"
      />
      <CardContent
        className="card-content"
      >
        <Typography variant="body1" component="p" sx={{ fontSize: "14px" }}>
          The expected timetable for the {props.reviewYear}/{props.planYear}{" "}
          Compensation Review process is:
        </Typography>
        {timeTableEnteries}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    timetable: state.timetable,
    auth: state.auth,
    isLoading: state.timetable.isLoading,
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actionCreators: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timetable);
