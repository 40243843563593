import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import Project from "../Project";

interface ProjectSectionProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  handleAddProject: any;
  clientProjects: any;
  partnerCommentsEnabled: boolean;
  handleUpdateProject: any;
  handleRemoveProject: any;
}

const ProjectSection: React.FunctionComponent<ProjectSectionProps> = ({
  handleAddProject,
  clientProjects,
  partnerCommentsEnabled,
  handleUpdateProject,
  handleRemoveProject,
}): React.ReactElement => {
  const renderProjects = () => {
    return clientProjects.map((project: any) => {
      return (
        <Project
          key={project.clientProjectId.toString()}
          canEdit={partnerCommentsEnabled}
          clientProject={project}
          updateProject={handleUpdateProject}
          deleteProject={handleRemoveProject}
        />
      );
    });
  };
  return (
    <>
      <Card>
        <CardHeader
          title={<Typography className="title">Projects</Typography>}
          className="card-header"
        />
        <CardContent className="card-content">
          <Typography
            variant="body1"
            component="p"
            className="fs-18"
            sx={{ my: 1 }}
          >
            <strong>Instructions: </strong>Please list (along with client/matter number) and briefly describe
            the top 5-10 client projects you worked on in 2023.
          </Typography>
          <Grid container spacing={2} sx={{ mb: 1 }} className="project">
            {renderProjects()}
          </Grid>
          {partnerCommentsEnabled && clientProjects.length < 10 && (
            <Grid item sx={{ textAlign: "left", p: 0.5, pt: 2 }}>
              <Button
                size="small"
                variant="contained"
                className="add-projectButton"
                onClick={handleAddProject}
              >
                Add Project
              </Button>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectSection;
