import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined";

const Documentation: React.FunctionComponent = (props: any): JSX.Element => {
  return (
    <Card sx={{ marginTop: "20px" }}>
      <CardHeader title={<Typography className="title">Documentation</Typography>}
                  className="card-header" />
      <CardContent className="card-content">
        <Stack spacing={1} direction="row" sx={{ my: 1 }}>
          <IconButton
            href="2023-2024 Partner Compensation Review Process and Timetable.pdf"
            target="_blank"
          >
            <Avatar
              alt="Memo"
              src="/assets/img/memo.png"
              sx={{ width: 110, height: 110 }}
              variant="square"
            />
          </IconButton>
          <Stack spacing={1} direction="column">
            <IconButton 
              href="https://web.microsoftstream.com/video/c89687f9-5237-4274-a313-d1e59f33481e"
              target="_blank">
              <Avatar
                sx={{
                  width: 110,
                  height: 110,
                  backgroundColor: "transparent",
                  color: "#717171",
                }}
                variant="square"
              >
                <VideoFileOutlinedIcon sx={{ fontSize: 85 }} />
              </Avatar>
            </IconButton>
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: 600,
                textAlign: "center",
                mt: 0,
              }}
            >
              Help Video
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Documentation;
