import { baseUrl } from "../../utils/authConfig";

const requestTimetableType = "REQUEST_TIMETABLE";
const receiveTimetableType = "RECEIVE_TIMETABLE";
const receiveTimetableErrorType = "RECEIVE_TIMETABLE_ERROR";

const initialState =
{
	isFetching: false,
	data: []
};

export const actionCreators = {
	requestTimetable: () => async (dispatch : any, getState : any) => {
		var state = getState();

		if (state.timetable.isLoading) {
			return;
		}

		dispatch({ type: requestTimetableType });
		const url = baseUrl +  `api/TimetableEntries`;
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					"Authorization": `Bearer ${getState().auth.accessToken}`,
					"Content-Type": 'application/json'
				}
			});
			if (!response.ok) {
				dispatch({ type: receiveTimetableErrorType, response: response });
				return;
			}

			const timetable = await response.json();

			dispatch({ type: receiveTimetableType, data: timetable });
		} catch (exception) {
			dispatch({ type: receiveTimetableErrorType, exception: exception });
		}
	}
};

export const reducer = (state : any , action : any) => {
	state = state || initialState;

	if (action.type === requestTimetableType) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveTimetableType) {
		return {
			...state,
			isLoading: false,
			data: action.data
		};
	}

	if (action.type === receiveTimetableErrorType) {
		return {
			...state,
			isLoading: false
		};
	}

	return state;
};