import { Grid, Typography, FormControl, Button } from "@mui/material";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicPcClient } from "../../models/IEconomicPcClient";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import PcClientCard from "./PcClientCard";
import EconomicPcClientAddEdit from "./EconomicPcClientAddEdit";
import { actionCreators as economicPcActionCreators } from "../../store/reducers/EconomicPcClients";
import { actionCreators as proliferationAreasActionCreators } from "../../store/reducers/ProliferationAreas";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import EconomicHistory from "./EconomicHistory";
import {
  canEditSelector,
  reviewYearSelector,
} from "../../store/reducers/Settings";

interface EconomicPcClientProps extends PropsFromRedux {
  year?: number;
  disabled?: boolean;
  canShowClientsSection?: boolean;
  economic: IEconomic;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
  isReviewYear: boolean;
}

const EconomicPcClient: React.FunctionComponent<EconomicPcClientProps> = (
  props
): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.economicPcActions.loadEconomicPcClients();
      props.proliferationAreasActions.loadProliferationAreas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const handleOnChange = (event: any) => {
    if (props.disabled) return;

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const clientsForYear =
    props.economicPcClients.clients &&
    props.economicPcClients.clients
      .filter((client: IEconomicPcClient) => client.year === props.year)
      .map((client: IEconomicPcClient) => (
        <PcClientCard
          key={client.economicPcClientId.toString()}
          client={client}
          disabled={props.disabled}
          edit={props.isReviewYear ? props.economicPcActions.editEconomicPcClientReviewYear : props.economicPcActions.editEconomicPcClient}
          remove={props.economicPcActions.removeEconomicPcClient}
        />
      ));

  const showPcClients = clientsForYear && clientsForYear.length > 0;

  let economicPcClient = undefined;
  if (props.economicPcClients.editEconomicPcClientVisible) {
    economicPcClient = props.economicPcClients.clients.find(
      (client: IEconomicPcClient) =>
        client.economicPcClientId ===
        props.economicPcClients.editingEconomicPcClientId
    );
  }

  let economicPcClientReviewYear = undefined;
  if (props.economicPcClients.editEconomicPcClientReviewYearVisible) {
    economicPcClientReviewYear = props.economicPcClients.clients.find(
      (client: IEconomicPcClient) =>
        client.economicPcClientId ===
        props.economicPcClients.editingEconomicPcClientId
    );
  }

  return (
    <div>
      <Grid container>
        <EconomicHistory
          label="Revenue Dollars Collected:"
          section="pc"
          isRevenue
          economicHistories={props.economicHistories}
        />
        <FormControl fullWidth>
          <Input
            placeholder="Revenue"
            value={Format.formatCurrencyInput(props.economic.pcRevenue)}
            icon="attach_money"
            fullWidth
            disabled={props.disabled}
            type="number"
            name="pcRevenue"
            onChange={handleOnChange}
            valid={Utils.getValidationStateRevenue(props.economic.pcRevenue)}
          />
        </FormControl>
      </Grid>
      {(showPcClients || props.canShowClientsSection) && props.isReviewYear && (
        <React.Fragment>
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography
              variant="h6"
              className="fs-14"
              sx={{ fontWeight: "700" }}
            >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Add one or more Clients.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {!props.disabled ? (
              <Grid item xs={12} sx={{ p: 0.5 }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className="addClientButton"
                  onClick={() =>
                    props.economicPcActions.showNewEconomicPcClientReviewYear()
                  }
                >
                  Add PC Client
                </Button>
              </Grid>
            ) : null}
            {props.economicPcClients.newEconomicPcClientReviewYearVisible && (
              <EconomicPcClientAddEdit
                accessToken={props.auth.accessToken}
                isNew
                year={props.year}
                reviewYear={props.reviewYear}
                save={props.economicPcActions.addEconomicPcClient}
                title="Add PC Client"
                canEdit={!props.disabled}
                proliferationAreas={props.proliferationAreas.proliferationAreas}
                close={() => props.economicPcActions.closeEconomicPcClientReviewYear()}
                isReviewYear={props.isReviewYear}
              />
            )}
            {props.economicPcClients.editEconomicPcClientReviewYearVisible && (
              <EconomicPcClientAddEdit
                accessToken={props.auth.accessToken}
                year={props.year}
                reviewYear={props.reviewYear}
                client={economicPcClientReviewYear}
                save={props.economicPcActions.updateEconomicPcClient}
                title="Edit PC Client"
                proliferationAreas={props.proliferationAreas.proliferationAreas}
                close={() => props.economicPcActions.closeEconomicPcClientReviewYear()}
                canEdit={!props.disabled}
                isReviewYear={props.isReviewYear}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
      {(showPcClients || props.canShowClientsSection) && !props.isReviewYear && (
        <React.Fragment>
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography
              variant="h6"
              className="fs-14"
              sx={{ fontWeight: "700" }}
            >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Add one or more Clients.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {!props.disabled ? (
              <Grid item xs={12} sx={{ p: 0.5 }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className="addClientButton"
                  onClick={() =>
                    props.economicPcActions.showNewEconomicPcClient()
                  }
                >
                  Add PC Client
                </Button>
              </Grid>
            ) : null}
            {props.economicPcClients.newEconomicPcClientVisible && (
              <EconomicPcClientAddEdit
                accessToken={props.auth.accessToken}
                isNew
                year={props.year}
                reviewYear={props.reviewYear}
                save={props.economicPcActions.addEconomicPcClient}
                title="Add PC Client"
                canEdit={!props.disabled}
                proliferationAreas={props.proliferationAreas.proliferationAreas}
                close={() => props.economicPcActions.closeEconomicPcClient()}
                isReviewYear={props.isReviewYear}
              />
            )}
            {props.economicPcClients.editEconomicPcClientVisible && (
              <EconomicPcClientAddEdit
                accessToken={props.auth.accessToken}
                year={props.year}
                reviewYear={props.reviewYear}
                client={economicPcClient}
                save={props.economicPcActions.updateEconomicPcClient}
                title="Edit PC Client"
                proliferationAreas={props.proliferationAreas.proliferationAreas}
                close={() => props.economicPcActions.closeEconomicPcClient()}
                canEdit={!props.disabled}
                isReviewYear={props.isReviewYear}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    economicPcClients: state.economicPcClients,
    proliferationAreas: state.proliferationAreas,
    canEdit: canEditSelector(state),
    reviewYear: reviewYearSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    economicPcActions: bindActionCreators(economicPcActionCreators, dispatch),
    proliferationAreasActions: bindActionCreators(
      proliferationAreasActionCreators,
      dispatch
    ),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EconomicPcClient);
