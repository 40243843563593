import {
  Autocomplete,
  Button,
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { actionCreators as adminExceptionUserActionCreators } from "../../../store/reducers/AdminExceptionUser";
import AdminTable from "../AdminTable/AdminTable";
import "../admin.scss";
import { connect } from "react-redux";
import CheckboxSelection from "../AdminTable/CheckboxSelection/CheckboxSelection";
import { bindActionCreators } from "redux";
import { IExceptionUsersModel } from "../../../models/IExceptionUsersModel";

const ExceptionUsers: React.FunctionComponent = (props: any): JSX.Element => {
  const headers = useMemo<ColumnDef<IExceptionUsersModel>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <div style={{ float: "left" }}>
            <CheckboxSelection
              {...{
                checked: table.getIsAllRowsSelected(),
                label: table.getIsAllRowsSelected()
                  ? "Deselect All"
                  : "Select All",
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <CheckboxSelection
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        maxSize: 35,
      },
      {
        accessorKey: "lastNameFirst",
        header: () => "Name",
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [exceptionUsersToBeAdded, setExceptionUserToBeAdded] = useState({});
  const [exceptionUsersToBeRemoved, setExceptionUserToBeRemoved] = useState({});

  useEffect(() => {
    if (props.auth.accessToken) {
      props.adminExceptionUserActions.loadUsers();
      props.adminExceptionUserActions.loadExceptionUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const handleUserChange = (event: any, value: any) => {
    if (!value) return;
    props.adminExceptionUserActions.addExceptionUser([value.userId]);
  };

  const handleUsersRowSelection = (selectedRows: any) => {
    setExceptionUserToBeAdded(selectedRows);
  };

  const handleExceptionUsersRowSelection = (selectedRows: any) => {
    setExceptionUserToBeRemoved(selectedRows);
  };

  const handleAddExceptionUser = () => {
    const userIds = getUserIds(exceptionUsersToBeAdded);
    props.adminExceptionUserActions.addExceptionUser(userIds);
  };

  const handleRemoveExceptionUser = () => {
    const userIds = getUserIds(exceptionUsersToBeRemoved);
    props.adminExceptionUserActions.removeExceptionUser(userIds);
  };

  const getUserIds = (selectedRows: any) => {
    return selectedRows.map((item: any) => item.original.userId);
  };

  const defaultSorted = [{ id: "lastNameFirst", desc: false }];

  const filteredUsers = props.usersListData.filter(
    (user: any) =>
      !props.exceptionUserListData.find(
        (exceptionUser: any) => exceptionUser.userId === user.userId
      )
  );

  const options = [...props.usersListData] as IExceptionUsersModel[];
  options.sort((a: IExceptionUsersModel, b: IExceptionUsersModel) => {
    var aName = a.lastNameFirst.toLowerCase();
    var bName = b.lastNameFirst.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  });

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5">Exception Users</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is the list of exception users:
        </Typography>
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <InputLabel className="label">Name:</InputLabel>
        <Autocomplete
          id="admin-staff-autocomplete"
          options={options}
          getOptionLabel={(option: IExceptionUsersModel) => option.lastNameFirst}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              className="group-textfield"
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Button
              size="medium"
              className="addUserBtn"
              onClick={handleAddExceptionUser}
              disabled={Object.keys(exceptionUsersToBeAdded).length <= 0}
              sx={{ mx: 1, mb: 0.5, float: "right" }}
              variant="contained"
              startIcon={<PersonIcon />}
            >
              Add Exception User
            </Button>
          </Grid>
          <Grid>
            <AdminTable
              {...{
                data: filteredUsers,
                columns: headers,
                showFilter: true,
                showPagination: true,
                defaultSorted,
                rowClickable: false,
                canSelectRow: true,
                handleRowSelection: handleUsersRowSelection,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Button
              size="medium"
              color="error"
              onClick={handleRemoveExceptionUser}
              className="removeUserBtn"
              disabled={Object.keys(exceptionUsersToBeRemoved).length <= 0}
              sx={{ mx: 1, mb: 1, float: "right" }}
              variant="contained"
              startIcon={<PersonIcon />}
            >
              Remove Exception User
            </Button>
          </Grid>
          <Grid>
            <AdminTable
              {...{
                data: props.exceptionUserListData,
                columns: headers,
                showFilter: true,
                showPagination: true,
                defaultSorted,
                rowClickable: false,
                canSelectRow: true,
                handleRowSelection: handleExceptionUsersRowSelection,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    exceptionUserListData: state.adminExceptionUser.exceptionUserListData,
    usersListData: state.adminExceptionUser.usersListData,
    isLoading: state.adminExceptionUser.isLoading,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    adminExceptionUserActions: bindActionCreators(
      adminExceptionUserActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionUsers);
