import Utils from "../utils/Utils";

const diversityUtil = {
    convertPercentagesFromServer(diversityData: any) {

	const result = { ...diversityData };

		if (diversityData.crmxMAWoman) { result.crmxMAWoman = Utils.isNumeric(diversityData.crmxMAWoman) ? Number(diversityData.crmxMAWoman * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxMAWoman = '-' };

		if (diversityData.crmxMALGBTQ) { result.crmxMALGBTQ = Utils.isNumeric(diversityData.crmxMALGBTQ) ? Number(diversityData.crmxMALGBTQ * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxMALGBTQ = '-' };

		if (diversityData.crmxMAEth) { result.crmxMAEth = Utils.isNumeric(diversityData.crmxMAEth) ? Number(diversityData.crmxMAEth * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxMAEth = '-' };

		if (diversityData.crmxWAEth) { result.crmxWAEth = Utils.isNumeric(diversityData.crmxWAEth) ? Number(diversityData.crmxWAEth * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxWAEth = '-' };

		if (diversityData.crmxWAWoman) { result.crmxWAWoman = Utils.isNumeric(diversityData.crmxWAWoman) ? Number(diversityData.crmxWAWoman * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxWAWoman = '-'};

		if (diversityData.crmxWaLGBTQ) { result.crmxWaLGBTQ = Utils.isNumeric(diversityData.crmxWaLGBTQ) ? Number(diversityData.crmxWaLGBTQ * 100).toFixed(2).toString() + '%' : '-' }
		else { result.crmxWaLGBTQ = '-' };

		if (diversityData.maxWAEth) { result.maxWAEth = Utils.isNumeric(diversityData.maxWAEth) ? Number(diversityData.maxWAEth * 100).toFixed(2).toString() + '%' : '-' }
		else { result.maxWAEth = '-' };

		if (diversityData.maxWALGBTQ) { result.maxWALGBTQ = Utils.isNumeric(diversityData.maxWALGBTQ) ? Number(diversityData.maxWALGBTQ * 100).toFixed(2).toString() + '%' : '-' }
		else { result.maxWALGBTQ = '-' };

		if (diversityData.maxWAWoman) { result.maxWAWoman = Utils.isNumeric(diversityData.maxWAWoman) ? Number(diversityData.maxWAWoman * 100).toFixed(2).toString() + '%' : '-' }
		else { result.maxWAWoman = '-' };

		if (diversityData.pscWoman) { result.pscWoman = Utils.isNumeric(diversityData.pscWoman) ? Number(diversityData.pscWoman * 100).toFixed(2).toString() + '%' : '-' }
		else { result.pscWoman = '-' };

		if (diversityData.pscUnderRace) { result.pscUnderRace = Utils.isNumeric(diversityData.pscUnderRace) ? Number(diversityData.pscUnderRace * 100).toFixed(2).toString() + '%' : '-' }
		else { result.pscUnderRace = '-' };

		if (diversityData.pscLGBTQ) { result.pscLGBTQ = Utils.isNumeric(diversityData.pscLGBTQ) ? Number(diversityData.pscLGBTQ * 100).toFixed(2).toString() + '%' : '-' }
		else { result.pscLGBTQ = '-' };

		if (diversityData.firmWoman) { result.firmWoman = Utils.isNumeric(diversityData.firmWoman) ? Number(diversityData.firmWoman * 100).toFixed(2).toString() + '%' : '-' }
		else { result.firmWoman = '-' };

		if (diversityData.firmUnderRace) { result.firmUnderRace = Utils.isNumeric(diversityData.firmUnderRace) ? Number(diversityData.firmUnderRace * 100).toFixed(2).toString() + '%' : '-' }
		else { result.firmUnderRace = '-' };

		if (diversityData.firmLGBTQ) { result.firmLGBTQ = Utils.isNumeric(diversityData.firmLGBTQ) ? Number(diversityData.firmLGBTQ * 100).toFixed(2).toString() + '%' : '-' }
		else { result.firmLGBTQ = '-' };

		return result;
	}
};


export default diversityUtil;