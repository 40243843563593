import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import "./teamWork.scss";
import { ITeam } from "../../models/ITeam";
import TeamDisplay from "./TeamDisplay";
import TeamEditAdd from "./TeamEditAdd";
import TextArea from "../common/textarea/TextArea";
import {
  canEditProxyUserSelector,
  canEditSelector,
  canEditTeamSelector,
  partnerCommentsEnabledSelector,
  planYearSelector,
  planYearTextSelector,
  reviewYearSelector,
  reviewYearTextSelector,
  isExceptionUserSelector,
} from "../../store/reducers/Settings";
import { actionCreators as teamworkActionCreators } from "../../store/reducers/Teamwork";
import { actionCreators as summaryCommentsActionCreators } from "../../store/reducers/SummaryComments";
import { actionCreators as partnerCommentsActionCreators } from "../../store/reducers/PartnerComments";
import Format from "../../utils/Format";
import NavigationBtn from "../common/navigation-btn/navigation-btn";

// eslint-disable-next-line no-empty-pattern
const TabTeamwork: React.FunctionComponent = (props : any) : JSX.Element => {

  useEffect(() => {   
    if(props.auth.accessToken){      
      props.teamworkActions.loadTeams();
      props.summaryCommentsActions.loadSummaryComments();
      props.partnerCommentsActions.loadPartnerComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const canEditPlanSummaryComments = props.canEditProxyUser || props.canEditTeam;
  const canEditPlanTeam = props.canEditProxyUser || props.canEditTeam;

  const teamsForCurrentYear = props.teamwork.teams
    ? props.teamwork.teams
        .filter((team: ITeam) => team.year === props.reviewYear)
        .map((team: ITeam) => (
          <TeamDisplay
            key={team.teamId.toString()}
            team={team}
            editTeam={props.teamworkActions.editTeam}
            removeTeam={props.teamworkActions.removeTeam}
            canEdit={canEditPlanTeam}
          />
        ))
    : null;
  const teamsForNextYear = props.teamwork.teams
    ? props.teamwork.teams
        .filter((team: ITeam) => team.year === props.planYear)
        .map((team: ITeam) => (
          <TeamDisplay
            key={team.teamId.toString()}
            team={team}
            editTeam={props.teamworkActions.editTeam}
            removeTeam={props.teamworkActions.removeTeam}
            canEdit={canEditPlanTeam}
          />
        ))
    : null;

  const summaryCommentForCurrentYear =
    props.summaryComments.summaryCommentCurrentYear;
  const summaryCommentForPlanYear =
    props.summaryComments.summaryCommentPlanYear;
  const partnerCommentsReviewYear = props.partnerComments.data;

  const handleSummaryCommentsPlan = (event: any, isCurrentYear: boolean) => {
    if (!canEditPlanSummaryComments) return;

      if (!isCurrentYear) {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentPlanYear,
                  teamworkSummaryComments: event.target.value,
              },
              isCurrentYear
          );
      } else {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentCurrentYear,
                  teamworkSummaryComments: event.target.value,
              },
              isCurrentYear
          );
      }
  };

  const handlePartnerComments = (event: any) => {
    if (!props.partnerCommentsEnabled) return;
    const { name } = event.target;
    props.partnerCommentsActions.savePartnerComments({
      ...props.partnerComments.data,
      [name]: event.target.value,
    });
  };

  let team2 = {};
  if (props.teamwork.editTeamVisible) {
    team2 = props.teamwork.teams.find(
      (team: ITeam) => team.teamId === props.teamwork.editingTeamId
    );
  }

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="team-work container">
      <Grid item className="header">
        <Typography variant="h5">Teamwork & Collaboration</Typography>
        <Typography variant="subtitle1" className="fs-18">
          <strong>Instructions: </strong>This section is intended to capture your individual plans and goals
          related to 1-3 client initiatives targeted at producing revenue. This
          section should not include teams focused on internal management or
          other non-revenue producing endeavors.
        </Typography>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Card>
          <CardContent className="card-content">
            <Grid container className="clients">
              <Grid item xs={6}>
                <Grid item>
                  <Chip label={props.reviewYearText} className="header-chip" />
                </Grid>
                <Grid container>{teamsForCurrentYear}</Grid>
                <Grid item className="p-2">
                  {teamsForCurrentYear.length < 3 && canEditPlanTeam && (
                    <Button
                      variant="contained"
                      className="addClientButton"
                      onClick={() => {
                        props.teamworkActions.showNewTeam();
                        props.teamworkActions.setYear(props.reviewYear);
                      }}
                    >
                      Add Client Team to your Plan
                    </Button>
                  )}
                  {props.teamwork.newTeamVisible && (
                    <TeamEditAdd
                      title="Add New Team"
                      team={team2}
                      closeTeam={props.teamworkActions.closeTeam}
                      saveTeam={props.teamworkActions.addTeam}
                      accessToken={props.auth.accessToken}
                      canEdit={canEditPlanTeam}
                    />
                  )}
                  {props.teamwork.editTeamVisible && (
                    <TeamEditAdd
                      title="Edit Team"
                      team={team2}
                      closeTeam={props.teamworkActions.closeTeam}
                      saveTeam={props.teamworkActions.updateTeam}
                      accessToken={props.auth.accessToken}
                      canEdit={canEditPlanTeam}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item sx={{ borderLeft: "2px solid #0033a0" }} xs={6}>
                <Grid item>
                  <Chip label={props.planYearText} className="header-chip" />
                </Grid>
                <Grid container>{teamsForNextYear}</Grid>
                <Grid item className="p-2">
                  {teamsForNextYear.length < 3 && canEditPlanTeam && (
                    <Button
                      variant="contained"
                      className="addClientButton"
                      onClick={() => {
                        props.teamworkActions.showNewTeam();
                        props.teamworkActions.setYear(props.planYear);
                      }}
                    >
                      Add Client Team to your Plan
                    </Button>
                  )}
                  {props.teamwork.newTeamVisible && (
                    <TeamEditAdd
                      title="Add New Team"
                      team={team2}
                      closeTeam={props.teamworkActions.closeTeam}
                      saveTeam={props.teamworkActions.addTeam}
                      accessToken={props.auth.accessToken}
                      canEdit={canEditPlanTeam}
                    />
                  )}
                  {props.teamwork.editTeamVisible && (
                    <TeamEditAdd
                      title="Edit Team"
                      team={team2}
                      closeTeam={props.teamworkActions.closeTeam}
                      saveTeam={props.teamworkActions.updateTeam}
                      accessToken={props.auth.accessToken}
                      canEdit={canEditPlanTeam}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography className="title">
                    Teamwork & Collaboration Summary
                  </Typography>
                }
                className="card-header"
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item className="p-1" xs={6}>
                    <TextArea
                      label="Teamwork & Collaboration Comments: (Max 550 characters)"
                      value={Format.formatString(
                        summaryCommentForCurrentYear &&
                          summaryCommentForCurrentYear.teamworkSummaryComments
                      )}
                      name="teamworkSummaryCommentsCurrentYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, true)}
                      limit={550}
                      disabled={!props.isExceptionUser}
                    />
                    <TextArea
                      label="Partner Comments: (Max 1100 characters)"
                      value={Format.formatString(
                        partnerCommentsReviewYear &&
                          partnerCommentsReviewYear.teamworkComments
                      )}
                      name="teamworkComments"
                      onChange={handlePartnerComments}
                      limit={1100}
                      disabled={!props.partnerCommentsEnabled}
                    />
                  </Grid>
                  <Grid
                    item
                    className="p-1"
                    sx={{ borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <TextArea
                      label="Teamwork & Collaboration Comments: (Max 550 characters)"
                      value={Format.formatString(
                        summaryCommentForPlanYear &&
                          summaryCommentForPlanYear.teamworkSummaryComments
                      )}
                      name="teamworkSummaryCommentsPlanningYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, false)}
                      limit={550}
                      disabled={!canEditPlanSummaryComments}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ justifyContent: "left", display: "flex", p: 1 }}>
        <NavigationBtn
          nextLink="/economic"
          prevLink="/"
          prevBtnTxt={`< Prev`}
          nextBtnTxt={`Next >`}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    teamwork: state.teamwork,
    summaryComments: state.summaryComments,
    isLoading: state.teamwork.isLoading,
    partnerComments: state.partnerComments,
    canEditTeam: canEditTeamSelector(state),
    canEdit: canEditSelector(state),
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
    reviewYearText: reviewYearTextSelector(state),
    planYearText: planYearTextSelector(state),
    partnerCommentsEnabled: partnerCommentsEnabledSelector(state),
    canEditProxyUser: canEditProxyUserSelector(state),
    isExceptionUser: isExceptionUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    teamworkActions: bindActionCreators(teamworkActionCreators, dispatch),
    summaryCommentsActions: bindActionCreators(
      summaryCommentsActionCreators,
      dispatch
    ),
    partnerCommentsActions: bindActionCreators(
      partnerCommentsActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabTeamwork);
