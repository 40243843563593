import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useState } from "react";
import Confirmation from "../common/Confirmation";

interface ProjectProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  clientProject: any;
  canEdit: Boolean;
  deleteProject: any;
  updateProject: any;
}

const Project: React.FunctionComponent<ProjectProps> = ({
  clientProject,
  canEdit,
  deleteProject,
  updateProject,
}): React.ReactElement => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if (!canEdit) return;
    setShowConfirmation(true);
  };

  const handleUpdate = (e: any) => {
    e.stopPropagation();
    updateProject(clientProject);
  };

  const handleDeleteOk = () => {
    setShowConfirmation(false);
    deleteProject(clientProject.clientProjectId);
  };

  const confirmationText =
    "Do you really want to delete this project?  This process cannot be undone.";

  return (
    <>
      <Grid item xs={2}>
        <Card className="card" onClick={handleUpdate}>
          <CardHeader
            title={
              <Typography className="title">
                {clientProject.clientName}
              </Typography>
            }
            action={
              canEdit && (
                <IconButton onClick={handleDelete}>
                  <HighlightOffIcon
                    color="error"
                    sx={{ fontSize: "18px", mt: "8px" }}
                  />
                </IconButton>
              )
            }
            className="card-header"
          />
          <CardContent className="card-content">
            <Typography gutterBottom>
              <b>
                {clientProject.clientNumber}-{clientProject.matterNumber}
              </b>
            </Typography>
            <Typography gutterBottom>{clientProject.description}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Confirmation
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onOk={handleDeleteOk}
        title={confirmationText}
      />
    </>
  );
};

export default Project;
