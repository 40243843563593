import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as sbuLeaderCommentsActionCreators } from "../../store/reducers/SbuLeaderComments";
import Format from "../../utils/Format";
import TextArea from "../common/textarea/TextArea";
import {
  planYearSelector,
  reviewYearSelector,
  sbuLeaderCommentsEnabledSelector,
} from "../../store/reducers/Settings";
import utils from "../../utils/Utils";
import NavigationBtn from "../common/navigation-btn/navigation-btn";

const TabSbuLeader: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.sbuLeaderCommentsActions.loadSbuLeaderComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const [sbuComment, setSbuComment] = useState({} as any);

  useEffect(() => {
    setSbuComment(props.sbuLeaderComments.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sbuLeaderComments.data]);

  const [validationError, setValidationError] = useState({
    baseCompRecommendations: null,
    bonusRecommendations: null,
  });

  const maxNumberLength = 7;

  const handleStatusChange = (event: any) => {
    const { name, value } = event.target;
    props.sbuLeaderCommentsActions.saveSbuLeaderComments({
      ...props.sbuLeaderComments.data,
      [name]: value,
    });
  };

  const handleSbuLeaderComments = (event: any) => {
    const { name } = event.target;
    props.sbuLeaderCommentsActions.saveSbuLeaderComments({
      ...props.sbuLeaderComments.data,
      [name]: event.target.value,
    });
  };

  const handleNumberFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = event.target.value;
    value = value.replace(/[^\d.]/g, "");
    const { name } = event.target;
    if (!maxLengthValidation(value)) {
      return;
    }
    setSbuComment({ ...sbuComment, [name]: Format.formatNaturalNumber(value) });

    if (!utils.isDefined(value)) {
      setValidationError({ ...validationError, [name]: true });
      return;
    }

    setValidationError({ ...validationError, [name]: false });
    props.sbuLeaderCommentsActions.saveSbuLeaderComments({
      ...props.sbuLeaderComments.data,
      [name]: Format.formatNaturalNumber(value),
    });
  };

  const maxLengthValidation = (value: any) => {
    if (value.toString().length > maxNumberLength) {
      return false;
    }
    return true;
  };

  const renderUnauthorized = () => {
    return (
      <div>
        <p>You are not authorized to view this information.</p>
      </div>
    );
  };

  const renderDefault = () => {
    return (
      <>
        <Grid item sx={{ p: 1 }}>
          <Card>
            <CardHeader
              title={<Typography className="title">Recommendations</Typography>}
              className="card-header"
            />
            <CardContent className="card-content">
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    className="fs-14"
                    sx={{ mb: 1, fontWeight: "700" }}
                  >
                    Recommendation for {props.planYear} Base Comp.
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ p: 1 }}>
                  <div className="input">
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <h6 className="custom-label">
                          Enter Base Comp Amount
                          <span className="text-danger">&nbsp;&nbsp;*</span>
                        </h6>
                      </div>
                    </div>

                    <div className="input-parent">
                     <span className="material-icons start-icon">{"attach_money"}</span>
                      <input
                        type="text"
                        name="baseCompRecommendations"
                        placeholder="Base Comp Amount"
                        onChange={handleNumberFieldChange}
                        value={Format.formatNaturalNumber(
                          sbuComment.baseCompRecommendations
                        )}
                        className={`inputStyle ${
                          validationError.baseCompRecommendations === null ||
                          validationError.baseCompRecommendations === undefined
                            ? ""
                            : validationError.baseCompRecommendations === true
                            ? "isInValid"
                            : "isValid"
                        }`}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={9} sx={{ p: 1 }}>
                  <TextArea
                    isRequired
                    label="Comments on Partner Base  (Max 1100 characters)"
                    value={Format.formatString(sbuComment.partnerBaseComments)}
                    name="partnerBaseComments"
                    onChange={handleSbuLeaderComments}
                    limit={1100}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    className="fs-14"
                    sx={{ mb: 1, fontWeight: "700" }}
                  >
                    Recommendation for {props.reviewYear} Bonus Comp.
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ p: 1 }}>
                  <div className="input">
                    <div style={{ display: "flex" }}>
                      <div style={{ flexGrow: 1 }}>
                        <h6 className="custom-label">
                          Enter Bonus Amount
                          <span className="text-danger">&nbsp;&nbsp;*</span>
                        </h6>
                      </div>
                    </div>

                    <div className="input-parent">
                      <span className="material-icons start-icon">{"attach_money"}</span>
                      <input
                        type="text"
                        name="bonusRecommendations"
                        placeholder="Bonus Amount"
                        onChange={handleNumberFieldChange}
                        value={Format.formatNaturalNumber(
                          sbuComment.bonusRecommendations
                        )}
                        className={`inputStyle ${
                          validationError.bonusRecommendations === null ||
                          validationError.bonusRecommendations === undefined
                            ? ""
                            : validationError.bonusRecommendations === true
                            ? "isInValid"
                            : "isValid"
                        }`}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={9} sx={{ p: 1 }}>
                  <TextArea
                    isRequired
                    label="Comments on Partner Bonus  (Max 1100 characters)"
                    value={Format.formatString(sbuComment.partnerBonusComments)}
                    name="partnerBonusComments"
                    onChange={handleSbuLeaderComments}
                    limit={1100}
                  />
                </Grid>
              </Grid>
              <Grid container>
              <Grid item xs={12}>
                  <Typography
                    className="fs-14"
                    sx={{ mb: 1, fontWeight: "700" }}
                  >
                    Recommendation for {props.planYear} Partner Status.
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ p: 1 }}>
                  <FormControl
                    fullWidth
                    size="small"
                  >
                    <Typography
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                      className="custom-label"
                      variant="subtitle2"
                    >
                      Select Partner Status
                    </Typography>
                    <Select
                      labelId="partner-status-id"
                      id="partner-status-select"
                      value={sbuComment.partnerStatus || "No Change"}
                      onChange={handleStatusChange}
                      name="partnerStatus"
                    >
                      <MenuItem value="No Change">No Change</MenuItem>
                      <MenuItem value="Equity">Equity</MenuItem>
                      <MenuItem value="Fixed Partner">Fixed Partner</MenuItem>
                      <MenuItem value="Senior Counsel/Of Counsel">
                        Senior Counsel/Of Counsel
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9} sx={{ p: 1 }}>
                  <TextArea
                    label="Comments on Partner Considered for Change in Status (Max 1100 characters)"
                    name="equityStatusComments"
                    value={Format.formatString(sbuComment.equityStatusComments)}
                    onChange={handleSbuLeaderComments}
                    limit={1100}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          className="p-1"
          sx={{ justifyContent: "left", display: "flex" }}
        >
          <NavigationBtn
            nextLink="/summary"
            prevLink="/goals"
            prevBtnTxt={`< Prev`}
            nextBtnTxt={`Next >`}
          />
        </Grid>
      </>
    );
  };

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="sbu-leader container">
      <Grid item className="header">
        <Typography variant="h5">SBU Leader Use Only</Typography>
        <Typography variant="subtitle1" className="fs-14">
          This section is intended for SBU Leader use only..
        </Typography>
      </Grid>
      {props.sbuLeaderCommentsEnabled ? renderDefault() : renderUnauthorized()}
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    sbuLeaderComments: state.sbuLeaderComments,
    sbuLeaderCommentsEnabled: sbuLeaderCommentsEnabledSelector(state),
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    sbuLeaderCommentsActions: bindActionCreators(
      sbuLeaderCommentsActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabSbuLeader);
