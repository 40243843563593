import { baseUrl } from "../../utils/authConfig";

const setAdminExceptionUserLoading = "SET_ADMIN_EXCEPTION_USER_LOADING";
const setExceptionUserListData = "SET_EXCEPTION_USER_LIST_DATA";
const setUsersListData = "SET_USERS_LIST_DATA";

const initialState = {
  isLoading: false,
  exceptionUserListData: [],
  usersListData: [],
};

export const actionCreators = {
  loadExceptionUsers: () => async (dispatch: any, getState: any) => {
    dispatch({
      type: setAdminExceptionUserLoading,
      payload: true,
    });

    const url = `${baseUrl}api/ExceptionUsers/All`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      const responseData = await response.json();
      dispatch({
        type: setExceptionUserListData,
        payload: responseData,
      });
    } finally {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: false,
      });
    }
  },
  addExceptionUser: (userIds: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.adminExceptionUser.isLoading) {
      return;
    }
    try {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: true,
      });
      const url = `${baseUrl}api/ExceptionUsers/Add`;
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(userIds),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      dispatch(actionCreators.loadExceptionUsers());
    } finally {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: false,
      });
    }
  },
  removeExceptionUser: (userIds: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.adminExceptionUser.isLoading) {
      return;
    }

    try {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: true,
      });
      const url = `${baseUrl}api/ExceptionUsers/Remove`;
      const response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(userIds),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      dispatch(actionCreators.loadExceptionUsers());
    } finally {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: false,
      });
    }
  },
  loadUsers: () => async (dispatch: any, getState: any) => {
    dispatch({
      type: setAdminExceptionUserLoading,
      payload: true,
    });
    try {
      const url = `${baseUrl}api/Users/All`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      dispatch({
        type: setUsersListData,
        payload: data,
      });
    } finally {
      dispatch({
        type: setAdminExceptionUserLoading,
        payload: false,
      });
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case setAdminExceptionUserLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case setExceptionUserListData: {
      return {
        ...state,
        exceptionUserListData: action.payload,
      };
    }
    case setUsersListData: {
      return {
        ...state,
        usersListData: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
