import { useEffect } from "react";
import "./App.css";
import "./assets/css/scss/main.scss";
import { Route, Routes } from "react-router-dom";
import TabHome from "./components/Home/TabHome";
import Layout from "./components/Layout";
import TabDiversity from "./components/Diversity/TabDiversity";
import TabEconomic from "./components/Economics/TabEconomic";
import TabGoals from "./components/Goals/TabGoals";
import TabTeamwork from "./components/Teamwork/TabTeamwork";
import TabYearEndReview from "./components/YearEnd/TabYearEndReview";
import TabSbuLeader from "./components/SBULeader/TabSbuLeader";
import React from "react";
import TabSummary from "./components/Summary/TabSummary";
import AdminEmail from "./components/Admin/Email/AdminEmail";
import AdminReports from "./components/Admin/Reports/AdminReports";
import AdminSettings from "./components/Admin/Settings/AdminSettings";
import AdminStaff from "./components/Admin/Staff/AdminStaff";
import AdminStatusReport from "./components/Admin/StatusReport/AdminStatusReport";
import AdminUsers from "./components/Admin/Users/AdminUsers";
import AdminUser from "./components/Admin/User/AdminUser";
import ExceptionUsers from "./components/Admin/ExceptionUsers/ExceptionUsers";
import ProxyUsers from "./components/Admin/ProxyUsers/ProxyUsers";

const fiveMinutes = 5 * 60 * 1000;

function App(props: any) {
  useEffect(() => {
    setInterval(checkAccount, fiveMinutes);
  });

  useEffect(() => {
    return () => {
      clearInterval();
    };
  }, []);

  function checkAccount() {
    if (localStorage.getItem("tokenTimeout") != null) {
      if (
        Date.now() >
        new Date(
          JSON.parse(localStorage.getItem("tokenTimeout") || "")
        ).getTime() -
          fiveMinutes
      ) {
        handleAquireToken();
      }
    }
  }

  function handleAquireToken() {
    props.acquireToken().then((response: any) => {
      if (response) {
        props.updateToken(response.accessToken);
        localStorage.setItem(
          "tokenTimeout",
          JSON.stringify(response.expiresOn)
        );
      }
    });
  }

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<TabHome />} />
          <Route path="/teamwork" element={<TabTeamwork />} />
          <Route path="/economic" element={<TabEconomic />} />
          <Route path="/goals" element={<TabGoals />} />
          <Route path="/diversity" element={<TabDiversity />} />
          <Route path="/yearEndReview" element={<TabYearEndReview />} />
          <Route path="/sbuLeader" element={<TabSbuLeader />} />
          <Route path="/summary" element={<TabSummary />} />
          <React.Fragment>
            <Route path="/admin/email" element={<AdminEmail />} />
            <Route path="/admin/reports" element={<AdminReports />} />
            <Route path="/admin/settings" element={<AdminSettings />} />
            <Route path="/admin/staff" element={<AdminStaff />} />
            <Route path="/admin/statusReport" element={<AdminStatusReport />} />
            <Route path="/admin/users" element={<AdminUsers />} />
            <Route path="/admin/user/:userId" element={<AdminUser />} />
            <Route path="/admin/exceptionUsers" element={<ExceptionUsers />} />
            <Route path="/admin/proxyUsers" element={<ProxyUsers />} />
          </React.Fragment>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
