import { actionCreators as economicActionCreators } from "./Economic";
import { actionCreators as economicCrmActionCreators } from "./EconomicCrmClients";
import { actionCreators as economicPcActionCreators } from "./EconomicPcClients";
import { actionCreators as economicScActionCreators } from "./EconomicScClients";
import { actionCreators as goalsActionCreators } from "./Goals";
import { actionCreators as teamworkActionCreators } from "./Teamwork";
import { actionCreators as partnerCommentsActionCreators } from "./PartnerComments";
import { actionCreators as summaryCommentsActionCreators } from "./SummaryComments";
import { actionCreators as sbuLeaderCommentsActionCreators } from "./SbuLeaderComments";
import { actionCreators as clientProjectsActionCreators } from "./ClientProjects";
import { actionCreators as headerActionCreators } from "./Header";
import { updateUserId } from "./Auth";
import { proxyUserId as proxyUserIdKey } from "../../utils/storage-key";

const setProxyUser = "SET_PROXY_USER";
const setProxyUsers = "SET_PROXY_USERS";
const updateProxyUserType = "UPDATE_PROXY_USER_TYPE";
const setLoadingStatus = "SET_LOADING_STATUS";
const setProxyLoadingStatus = "SET_PROXY_LOADING_STATUS";

const initialState = {
  userId: null,
  currentUserId: null,
  isAttorney: null,
  name: null,
  ntId: null,
  proxyUsers: [],
  isLoading: false,
};

export const actionCreators = {
  setProxyUser:
    (id: string, currentSelectedUser: any) => async (dispatch: any) => {
      const proxyUserId = parseInt(id, 10);
      sessionStorage.setItem(proxyUserIdKey, proxyUserId.toString());
      dispatch({ type: setProxyLoadingStatus, isProxyLoading: true });
      dispatch({ type: setProxyUser, proxyUser: currentSelectedUser });
      dispatch(updateUserId(proxyUserId));
      dispatch(headerActionCreators.requestHeader());
      dispatch(teamworkActionCreators.loadTeams());
      dispatch(goalsActionCreators.loadGoals());
      dispatch(economicActionCreators.requestEconomic());
      dispatch(economicCrmActionCreators.loadEconomicCrmClients());
      dispatch(economicPcActionCreators.loadEconomicPcClients());
      dispatch(economicScActionCreators.loadEconomicScClients());
      dispatch(summaryCommentsActionCreators.loadSummaryComments());
      dispatch(partnerCommentsActionCreators.loadPartnerComments());
      dispatch(sbuLeaderCommentsActionCreators.loadSbuLeaderComments());
      dispatch(clientProjectsActionCreators.loadClientProjects());
      dispatch({ type: setProxyLoadingStatus, isProxyLoading: false });
    },
  setProxyUsers: (proxyUsers: any) => ({
    type: setProxyUsers,
    proxyUsers: proxyUsers,
  }),
  updateProxyUser: (userId: any, isAttorney: any) => ({
    type: updateProxyUserType,
    userId: userId,
    isAttorney: isAttorney,
  }),
  setLoading: (status: any) => ({ type: setLoadingStatus, isLoading: status }),
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case setProxyUser:
      return {
        ...state,
        proxyUser: action.proxyUser,
      };

    case updateProxyUserType: {
      const proxyUsers = [...state.proxyUsers];
      const proxyUser = proxyUsers.find(
        (user) => user.userId === action.userId
      );
      if(!proxyUser) return {...state, isAttorney: action.isAttorney};
      proxyUser.isAttorney = action.isAttorney;
      return {
        ...state,
        proxyUsers: proxyUsers,
      };
    }

    case setProxyUsers: {
      const proxyUsers = [...action.proxyUsers];
      proxyUsers.sort((a, b) => {
        var aName = (a.lastNameFirst || a.name).toLowerCase();
        var bName = (b.lastNameFirst || b.name).toLowerCase();
        return aName.localeCompare(bName);
      });
      return {
        ...state,
        proxyUsers: proxyUsers,
      };
    }

    case setLoadingStatus:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case setProxyLoadingStatus:
      return {
        ...state,
        isProxyLoading: action.isProxyLoading,
      };
    default:
      return state;
  }
};
