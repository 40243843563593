class FetchQueue {
  constructor(url, method = "POST", okAction, errorAction, exceptionAction) {
    this.url = url;
    this.method = method;
    this.okAction = okAction;
    this.errorAction = errorAction;
    this.exceptionAction = exceptionAction;
  }

  _queue = [];
  _queueRunning = false;

  queue(key, data, token, dispatch = null, url = this.url) {
    let entry = {
      key: key,
      data: data,
      dispatch: dispatch,
      url: url,
      token: token,
    };
    const queueIndex = this._queue.findIndex((q) => q.key === key);
    if (queueIndex > -1) this._queue[queueIndex] = entry;
    else this._queue.push(entry);
    this.send();
  }

  async send() {
    if (this._queueRunning) return;
    this._queueRunning = true;
    while (this._queue.length > 0) {
      const entry = this._queue.shift();
      const data = entry.data;
      try {
        const url = entry.url;
        const options = {
          method: this.method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${entry.token}`,
          },
          credentials: "same-origin",
        };
        if (this.method !== "GET" && this.method !== "HEAD")
          options.body = JSON.stringify(data);
        const response = await fetch(url, options);
        if (!response.ok) {
          if (this.errorAction) this.errorAction(entry.dispatch, response);
        } else if (this.okAction) {
          const responseData = await response.json();
          this.okAction(entry.dispatch, responseData);
        }
      } catch (exception) {
        if (this.exceptionAction)
          this.exceptionAction(entry.dispatch, exception);
      }
    }
    this._queueRunning = false;
  }
}

export default FetchQueue;
