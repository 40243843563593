import { baseUrl } from "../../utils/authConfig";

const requestHeaderType = "REQUEST_HEADER";
const receiveHeaderType = "RECEIVE_HEADER";
const receiveHeaderErrorType = "RECEIVE_HEADER_ERROR";
const initialState = 
	{
		isFetching: false,
		headerId: null,
		userId: null,
		name: "",
		planYear: 2021,
		office: "",
		sbu: "",
		psc: "",
		hireDate: null
	};

export const actionCreators = {
	requestHeader: () => async (dispatch: any, getState: any) => {
		var state = getState();
		const { userId } = state.auth;
		if (state.header.userId === userId) {
			return;
		}
		if (state.header.isLoading) {
			return;
		}
		dispatch({ type: requestHeaderType });
		const url = baseUrl + `api/Headers/${userId}`;	
		try {
			const response = await fetch(url,{
				method: 'GET',
				headers: {
						"Authorization": `Bearer ${getState().auth.accessToken}`,
						"Content-Type": 'application/json',
				}
			});
			if (!response.ok) {
				dispatch({ type: receiveHeaderErrorType, response: response });
				return;
			}
			const header = await response.json();
			dispatch({ type: receiveHeaderType, header });
		} catch (exception) {
			dispatch({ type: receiveHeaderErrorType, exception: exception });
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestHeaderType) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveHeaderType) {
		return {
			...state,
			isLoading: false,
			headerId: action.header.headerId,
			userId: action.header.userId,
			name: action.header.name,
			planYear: action.header.planYear,
			office: action.header.office,
			sbu: action.header.sbu,
			psc: action.header.psc,
			photoName: action.header.photoName,
		    sbuLeader: action.header.sbuLeader,
		    sbuMd: action.header.sbuMd,
			hireDate: action.header.hireDate
		};
	}

	if (action.type === receiveHeaderErrorType) {
		return {
			...state,
			isLoading: false
		};
	}

	return state;
};