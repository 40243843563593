import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import TextArea from "../common/textarea/TextArea";
import "./yearEndReview.scss";
import Format from "../../utils/Format";
import { actionCreators as partnerCommentsActionCreators } from "../../store/reducers/PartnerComments";
import {
  canEditProxyUserSelector,
  canEditSelector,
  partnerCommentsEnabledSelector,
  reviewYearTextSelector,
} from "../../store/reducers/Settings";

// eslint-disable-next-line no-empty-pattern
const TabYearEndReview: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.partnerCommentsActions.loadPartnerComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const partnerCommentsReviewYear = props.partnerComments.data;

  const handlePartnerComments = (event: any) => {
    if (!props.partnerCommentsEnabled) return;

    const { name } = event.target;
    props.partnerCommentsActions.savePartnerComments({
      ...props.partnerComments.data,
      [name]: event.target.value,
    });
  };

  return (
    <Container
      maxWidth={false}
      sx={{ my: 2 }}
      className="year-end-review container"
    >
      <Grid item className="header">
        <Typography variant="h5">Year End Review</Typography>
      </Grid>
      <Grid item className="p-1">
        <Card>
          <CardHeader
            title={
              <Typography className="title">
                Year End Review Highlights
              </Typography>
            }
            className="card-header"
          />
          <CardContent className="card-content">
            <Grid item>
              <Chip label={props.reviewYearText} className="header-chip" />
            </Grid>
            <Grid item className="p-1">
              <TextArea
                label="Please provide any information that the Comp Committee and the SBU Leadership Team should know about your performance that is not apparent from the metrics in the PCR. (Max 1100 Characters)"
                value={Format.formatString(
                  partnerCommentsReviewYear &&
                    partnerCommentsReviewYear.yearEndSummaryComments
                )}
                name="yearEndSummaryComments"
                onChange={handlePartnerComments}
                limit={1100}
                disabled={!props.partnerCommentsEnabled}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        className="p-1"
        sx={{ justifyContent: "left", display: "flex" }}
      >
        <ButtonGroup variant="contained" size="medium" className="btnGroup">
          <Button component={RouterLink} to="/goals">
            {"< "}Prev
          </Button>
          <Button component={RouterLink} to="/summary">
            Next{" >"}
          </Button>
        </ButtonGroup>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    partnerComments: state.partnerComments,
    reviewYearText: reviewYearTextSelector(state),
    partnerCommentsEnabled: partnerCommentsEnabledSelector(state),
    canEdit: canEditSelector(state),
    canEditProxyUser: canEditProxyUserSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    partnerCommentsActions: bindActionCreators(
      partnerCommentsActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabYearEndReview);
