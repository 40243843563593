import {
  Grid,
  Chip,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import "./diversity.scss";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: "none",
    padding: "2px 5px",
    fontSize: "12px",
    lineHeight: "1rem",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    padding: "2px 5px",
    fontSize: "12px",
    lineHeight: "0.8rem",
  },
}));

interface DiversityDashboardProps {
  label: string;
  showDashboard: boolean;
  diversityData: any;
}

const DiversityDashboard: React.FunctionComponent<DiversityDashboardProps> = ({
  label,
  showDashboard,
  diversityData,
}): JSX.Element => {
  return (
    <Grid item xs={6}>
      <Grid item>
        <Chip label={label} className="header-chip" />
      </Grid>
      {showDashboard ? (
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "16px",
          }}
        >
          <TableContainer component={Paper} className="table-container">
            <Table size="small" sx={{ mb: 4 }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    colSpan={5}
                    sx={{
                      textAlign: "center",
                      paddingBottom: "24px !important",
                      fontSize: "16px !important",
                    }}
                  >
                    Diversity Stats
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Designation</StyledTableCell>
                  <StyledTableCell align="center">LGBTQ+</StyledTableCell>
                  <StyledTableCell align="center">
                    Underrepresented Race
                  </StyledTableCell>
                  <StyledTableCell align="center">Women</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow
                    key="CrmMa"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      CRM x MA
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.crmxMALGBTQ}
                    </StyledTableCell>
                    <StyledTableCell align="center">{diversityData.crmxMAEth}</StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.crmxMAWoman}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    key="CrmWorking"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      CRM x Working
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.crmxWaLGBTQ}
                    </StyledTableCell>
                    <StyledTableCell align="center">{diversityData.crmxWAEth}</StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.crmxWAWoman}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    key="MaWorking"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      MA x Working
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.maxWALGBTQ}
                    </StyledTableCell>
                    <StyledTableCell align="center">{diversityData.maxWAEth}</StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.maxWAWoman}
                    </StyledTableCell>
                  </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <StyledTableCell colSpan={4}>
                    <hr className="theme-border" />
                  </StyledTableCell>
                </TableRow>
                  <TableRow
                    key="PscBenchmark"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row" sx={{fontWeight: '600'}}>
                      PSC Benchmark
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.pscLGBTQ}
                    </StyledTableCell>
                    <StyledTableCell align="center">{diversityData.pscUnderRace}</StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.pscWoman}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow
                    key="FirmStats"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <StyledTableCell component="th" scope="row" sx={{fontWeight: '600'}}>
                      Total Firm Stats
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.firmLGBTQ}
                    </StyledTableCell>
                    <StyledTableCell align="center">{diversityData.firmUnderRace}</StyledTableCell>
                    <StyledTableCell align="center">
                      {diversityData.firmWoman}
                    </StyledTableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid></Grid>
      )}
    </Grid>
  );
};

export default DiversityDashboard;
