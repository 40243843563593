import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import "./goals.scss";
import { actionCreators as summaryCommentsActionCreators } from "../../store/reducers/SummaryComments";
import { actionCreators as partnerCommentsActionCreators } from "../../store/reducers/PartnerComments";
import { actionCreators as goalsActionsCreators } from "../../store/reducers/Goals";
import { actionCreators as clientProjectsActionCreators } from "../../store/reducers/ClientProjects";
import {
  reviewYearSelector,
  planYearSelector,
  reviewYearTextSelector,
  planYearTextSelector,
  canEditSelector,
  sbuLeaderCommentsEnabledSelector,
  partnerCommentsEnabledSelector,
  partnerCommentsVisibleSelector,
  isExceptionUserSelector,
  canEditProxyUserSelector,
} from "../../store/reducers/Settings";
import AddNewProject from "./AddNewProject/AddNewProject";
import { IClientProject } from "../../models/IClientProject";
import NavigationBtn from "../common/navigation-btn/navigation-btn";
import GoalSummarySection from "./Sections/GoalSummary";
import ProjectSection from "./Sections/Projects";
import GoalSection from "./Sections/Goals";

const TabGoals: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.summaryCommentsActions.loadSummaryComments();
      props.partnerCommentsActions.loadPartnerComments();
      props.goalsActions.loadGoals();
      props.clientProjectsActions.loadClientProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const summaryCommentForCurrentYear =
    props.summaryComments.summaryCommentCurrentYear;
  const summaryCommentForPlanYear =
    props.summaryComments.summaryCommentPlanYear;
  const partnerCommentsReviewYear = props.partnerComments.data;
  const canEdit = props.canEdit || props.canEditProxyUser;

  const handleSummaryCommentsPlan = (event: any, isCurrentYear: boolean) => {
      if (!isCurrentYear) {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentPlanYear,
                  goalSummaryComments: event.target.value,
              },
              isCurrentYear
          );
      } else {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentCurrentYear,
                  goalSummaryComments: event.target.value,
              },
              isCurrentYear
          );
      }
  };

  const handlePartnerComments = (event: any) => {
    const { name } = event.target;
    props.partnerCommentsActions.savePartnerComments({
      ...props.partnerComments.data,
      [name]: event.target.value,
    });
  };

  const handleAddGoal = async (goal: any) => {
    if (!canEdit) return;
    await props.goalsActions.addGoal(goal);
  };

  const handleRemoveGoal = async (goalId: any) => {
    props.goalsActions.removeGoal(goalId);
  };

  const handleUpdateGoal = async (data: any) => {
    props.goalsActions.updateGoal(data);
  };

  let clientProject = {};
  if (props.clientProjects.editClientProjectVisible) {
    clientProject = props.clientProjects.clientProjects.find(
      (client: IClientProject) =>
        client.clientProjectId === props.clientProjects.editingClientProjectId
    ) as IClientProject;
  }

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="goals container">
      <Grid item className="header">
        <Typography variant="h5">Qualitative Goals</Typography>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <ProjectSection
          clientProjects={props.clientProjects.clientProjects}
          partnerCommentsEnabled={props.partnerCommentsEnabled}
          handleAddProject={props.clientProjectsActions.showNewClientProject}
          handleRemoveProject={props.clientProjectsActions.removeClientProject}
          handleUpdateProject={props.clientProjectsActions.editClientProject}
        />
      </Grid>
      <Grid item sx={{ px: 1 }} className="sticky-notes">
        <GoalSection
          canEdit={props.canEdit}
          goals={props.goals.goals}
          handleAddGoal={handleAddGoal}
          handleRemoveGoal={handleRemoveGoal}
          handleUpdateGoal={handleUpdateGoal}
          partnerCommentsEnabled={props.partnerCommentsEnabled}
          partnerCommentsVisible={props.partnerCommentsVisible}
          sbuLeaderCommentsEnabled={props.sbuLeaderCommentsEnabled}
          planYear={props.planYear}
          planYearText={props.planYearText}
          reviewYear={props.reviewYear}
          reviewYearText={props.reviewYearText}
          isExceptionUser={props.isExceptionUser}
          canEditProxyUser={props.canEditProxyUser}
        />
      </Grid>
      <Grid item sx={{ px: 1 }} className="goal-summary">
        <Grid container>
          <Grid item xs={12}>
            <GoalSummarySection
                          goalComments={
                              partnerCommentsReviewYear &&
                              partnerCommentsReviewYear.goalComments
                          }
                          goalSummaryCommentsPlanYear={
                              summaryCommentForPlanYear &&
                              summaryCommentForPlanYear.goalSummaryComments
                          }
                          goalSummaryCommentsCurrentYear={
                              summaryCommentForCurrentYear &&
                              summaryCommentForCurrentYear.goalSummaryComments
                          }
                          handlePartnerComments={handlePartnerComments}
                          handleSummaryCommentsPlan={handleSummaryCommentsPlan}
                          canEdit={props.canEdit}
                          canEditProxyUser={props.canEditProxyUser}
                          partnerCommentsEnabled={props.partnerCommentsEnabled}
                          isExceptionUser={props.isExceptionUser}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="p-1"
        sx={{ justifyContent: "left", display: "flex" }}
      >
        <NavigationBtn
          prevLink="/diversity"
          nextLink="/yearEndReview"
          prevBtnTxt={`< Prev`}
          nextBtnTxt={`Next >`}
        />
      </Grid>
      {props.clientProjects.newClientProjectVisible && (
        <AddNewProject
          canEdit={props.canEdit || props.partnerCommentsEnabled}
          partnerCommentsEnabled={props.partnerCommentsEnabled}
          close={props.clientProjectsActions.closeClientProject}
          save={props.clientProjectsActions.addClientProject}
          title="Add Project"
          isNew={true}
          accessToken={props.auth.accessToken}
        />
      )}
      {props.clientProjects.editClientProjectVisible && (
        <AddNewProject
          canEdit={props.canEdit || props.partnerCommentsEnabled}
          partnerCommentsEnabled={props.partnerCommentsEnabled}
          close={props.clientProjectsActions.closeClientProject}
          save={props.clientProjectsActions.updateClientProject}
          clientProject={clientProject}
          title="Edit Project"
          isNew={false}
          accessToken={props.auth.accessToken}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    goals: state.goals,
    summaryComments: state.summaryComments,
    partnerComments: state.partnerComments,
    clientProjects: state.clientProjects,
    partnerCommentsEnabled: partnerCommentsEnabledSelector(state),
    partnerCommentsVisible: partnerCommentsVisibleSelector(state),
    canEdit: canEditSelector(state),
    isExceptionUser: isExceptionUserSelector(state),
    sbuLeaderCommentsEnabled: sbuLeaderCommentsEnabledSelector(state),
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
    reviewYearText: reviewYearTextSelector(state),
    planYearText: planYearTextSelector(state),
    canEditProxyUser: canEditProxyUserSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    summaryCommentsActions: bindActionCreators(
      summaryCommentsActionCreators,
      dispatch
    ),
    partnerCommentsActions: bindActionCreators(
      partnerCommentsActionCreators,
      dispatch
    ),
    goalsActions: bindActionCreators(goalsActionsCreators, dispatch),
    clientProjectsActions: bindActionCreators(
      clientProjectsActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabGoals);
