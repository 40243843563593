import {
  Autocomplete,
  Button,
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import AdminTable from "../AdminTable/AdminTable";
import "../admin.scss";
import { connect } from "react-redux";
import { baseUrl } from "../../../utils/authConfig";

type AdminUserModel = {
  lastNameFirst: string;
  attorney: string;
  sbu: string;
  psc: string;
  office: string;
};

const AdminUsers: React.FunctionComponent = (props: any): JSX.Element => {
  const columns = useMemo<ColumnDef<AdminUserModel>[]>(
    () => [
      {
        accessorKey: "lastNameFirst",
        header: () => "Name",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "sbu",
        header: () => "SBU",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "psc",
        header: "PSC",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "office",
        header: "Office",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "isAttorney",
        header: "Attorney",
        footer: (props) => props.column.id,
        cell: (data: any) => <span>{data.getValue() ? "Attorney" : ""}</span>,
        type: "list",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
    ],
    []
  );

  const [, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.auth.accessToken) {
      loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const loadUsers = async () => {
    const accessToken = props.auth.accessToken;

    try {
      const url = `${baseUrl}api/Users/All`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        setLoading(false);
        return;
      }
      const data = await response.json();
      setUsers(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUserChange = (event: any, value: any) => {
    if (!value) return;
    const userLookup = value;
    const userId = userLookup.userId;
    const url = `/admin/user/${userId}`;
    navigate(url);
  };

  const handleRowClick = (rowData: any) => {
    const userId = rowData.userId;
    const url = `/admin/user/${userId}`;
    navigate(url);
  }

  const defaultSorted = [{ id: "lastNameFirst", desc: false }];

  const options = [...users] as AdminUserModel[];
  options.sort((a: AdminUserModel, b: AdminUserModel) => {
    var aName = a.lastNameFirst.toLowerCase();
    var bName = b.lastNameFirst.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  });

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5">Admin Users</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is the list of all of the current users:
          <Button
            size="medium"
            className="addUserBtn"
            sx={{ float: "right" }}
            variant="contained"
            component={RouterLink}
            to="/admin/staff"
            startIcon={<PersonIcon />}
          >
            Add User
          </Button>
        </Typography>
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <InputLabel className="label">Name:</InputLabel>
        <Autocomplete
          id="admin-staff-autocomplete"
          options={options}
          getOptionLabel={(option: AdminUserModel) => option.lastNameFirst}         
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              className="group-textfield"             
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid>
        <AdminTable
          {...{
            data: users,
            columns,
            showFilter: true,
            showPagination: true,
            defaultSorted,
            handleRowClick: handleRowClick,
            rowClickable: true,
          }}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null)(AdminUsers);
