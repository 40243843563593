import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const closeEconomicCrmClientType = "CLOSE_ECONOMIC_CRM_CLIENT";
const closeEconomicCrmClientReviewYearType = "CLOSE_ECONOMIC_CRM_CLIENT_REVIEW_YEAR";
const editEconomicCrmClientType = "EDIT_ECONOMIC_CRM_CLIENT";
const requestEconomicCrmClientsType = "REQUEST_ECONOMIC_CRM_CLIENTS";
const receiveEconomicCrmClientsType = "RECEIVE_ECONOMIC_CRM_CLIENTS";
const receiveEconomicCrmClientsErrorType = "RECEIVE_ECONOMIC_CRM_CLIENTS_ERROR";
const requestPostEconomicCrmClientType = "REQUEST_POST_ECONOMIC_CRM_CLIENT";
const receivePostEconomicCrmClientType = "RECEIVE_POST_ECONOMIC_CRM_CLIENT";
const receivePostEconomicCrmClientErrorType = "RECEIVE_POST_ECONOMIC_CRM_CLIENT_ERROR";
const requestPutEconomicCrmClientType = "REQUEST_PUT_ECONOMIC_CRM_CLIENT";
const receivePutEconomicCrmClientType = "RECEIVE_PUT_ECONOMIC_CRM_CLIENT";
const receivePutEconomicCrmClientErrorType = "RECEIVE_PUT_ECONOMIC_CRM_CLIENT_ERROR";
const requestDeleteEconomicCrmClientType = "REQUEST_DELETE_ECONOMIC_CRM_CLIENT";
const receiveDeleteEconomicCrmClientType = "RECEIVE_DELETE_ECONOMIC_CRM_CLIENT";
const receiveDeleteEconomicCrmClientErrorType = "RECEIVE_DELETE_ECONOMIC_CRM_CLIENT_ERROR";
const showNewEconomicCrmClientType = "SHOW_NEW_ECONOMIC_CRM_CLIENT";
const showNewEconomicCrmClientReviewYearType = "SHOW_NEW_ECONOMIC_CRM_CLIENT_REVIEW_YEAR";
const requestPostEconomicCrmClientReviewYearType = "REQUEST_POST_ECONOMIC_CRM_CLIENT_REVIEW_YEAR";
const receivePostEconomicCrmClientReviewYearType = "RECEIVE_POST_ECONOMIC_CRM_CLIENT_REVIEW_YEAR";
const receivePostEconomicCrmClientErrorReviewYearType = "RECEIVE_POST_ECONOMIC_CRM_CLIENT_ERROR_REVIEW_YEAR";
const editEconomicCrmClientReviewYearType = "EDIT_ECONOMIC_CRM_CLIENT_REVIEW_YEAR";

const initialState = {
	editEconomicCrmClientVisible: false,
	editEconomicCrmClientReviewYearVisible: false,
	isDeleting: false,
	isLoadedUserId: null,
	isLoading: false,
	isPosting: false,
	isPutting: false,
	newEconomicCrmClientVisible: false,
	newEconomicCrmClientReviewYearVisible: false,
	editingEconomicCrmClientId: null,
	clients: []
};

export const actionCreators = {
	closeEconomicCrmClient: () => ({ type: closeEconomicCrmClientType }),
	closeEconomicCrmClientReviewYear: () => ({ type: closeEconomicCrmClientReviewYearType }),
	editEconomicCrmClient: (data: any) => ({ type: editEconomicCrmClientType, economicCrmClientId: data.economicCrmClientId }),
	editEconomicCrmClientReviewYear: (data: any) => ({ type: editEconomicCrmClientReviewYearType, economicCrmClientId: data.economicCrmClientId }),
	showNewEconomicCrmClient: (visible = true) => ({ type: showNewEconomicCrmClientType, visible: visible }),
	showNewEconomicCrmClientReviewYear: (visible = true) => ({ type: showNewEconomicCrmClientReviewYearType, visible: visible }),
	loadEconomicCrmClients: () => async (dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		if (state.economicCrmClients.isLoadedUserId === userId) {
			return;
		}
		if (state.economicCrmClients.isLoading) {
			return;
		}

		dispatch({ type: requestEconomicCrmClientsType });
		const url = `${baseUrl}api/EconomicCrmClients/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveEconomicCrmClientsErrorType, response: response });
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveEconomicCrmClientsType, data: responseData, userId: userId });
		} catch (exception) {
			dispatch({ type: receiveEconomicCrmClientsErrorType, exception: exception });
		}
	},
	addEconomicCrmClient: (data: any, isReviewYear: boolean) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicCrmClients.isPosting) {
			return;
		}

		const { userId } = state.auth;
		const data3 = { ...data, userId: userId };
		if (isReviewYear) {
			dispatch({ type: requestPostEconomicCrmClientReviewYearType, data: data });
		} else {
			dispatch({ type: requestPostEconomicCrmClientType, data: data });
		}
		
		const url = `${baseUrl}api/EconomicCrmClients`;
		try {
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data3),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				if (isReviewYear) {
					dispatch({ type: receivePostEconomicCrmClientErrorReviewYearType, response: response });
				} else {
					dispatch({ type: receivePostEconomicCrmClientErrorType, response: response });
				}
				dispatch(errorActionCreators.addError("An error occurred while adding an economic CRM client.", null, response));
				return;
			}
			const responseData = await response.json();
			if (isReviewYear) {
				dispatch({ type: receivePostEconomicCrmClientReviewYearType, data: responseData });
			} else {
				dispatch({ type: receivePostEconomicCrmClientType, data: responseData });
			}
			
		} catch (exception) {
			if (isReviewYear) {
				dispatch({ type: receivePostEconomicCrmClientErrorReviewYearType, exception: exception });
			} else {
				dispatch({ type: receivePostEconomicCrmClientErrorType, exception: exception });
			}
			dispatch(errorActionCreators.addError("An error occurred while adding an economic CRM client.", exception, null));
		}
	},
	removeEconomicCrmClient: (data: any) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicCrmClients.isDeleting) {
			return;
		}

		dispatch({ type: requestDeleteEconomicCrmClientType, data: data });
		const url = `${baseUrl}api/EconomicCrmClients/${data}`;
		try {
			const response = await fetch(url,
				{
					method: "DELETE",
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receiveDeleteEconomicCrmClientErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while removing an economic CRM client.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveDeleteEconomicCrmClientType, data: responseData });
		} catch (exception) {
			dispatch({ type: receiveDeleteEconomicCrmClientErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while removing an economic CRM client.", exception, null));
		}
	},
	updateEconomicCrmClient: (data: any) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicCrmClients.isPutting) {			
			return;
		}

		dispatch({ type: requestPutEconomicCrmClientType, data: data });
		const url = `${baseUrl}api/EconomicCrmClients/${data.economicCrmClientId}`;
		try {
			const response = await fetch(url,
				{
					method: "PUT",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receivePutEconomicCrmClientErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while updating an economic CRM client.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receivePutEconomicCrmClientType, data: responseData });
		} catch (exception) {
			dispatch({ type: receivePutEconomicCrmClientErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while updating an economic CRM client.", exception, null));
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === closeEconomicCrmClientType) {
		return {
			...state,
			editEconomicCrmClientVisible: false,
			newEconomicCrmClientVisible: false
		};
	}

	if (action.type === closeEconomicCrmClientReviewYearType) {
		return {
			...state,
			editEconomicCrmClientReviewYearVisible: false,
			newEconomicCrmClientReviewYearVisible: false
		};
	}

	if (action.type === showNewEconomicCrmClientType) {
		return {
			...state,
			newEconomicCrmClientVisible: action.visible
		};
	}

	if (action.type === showNewEconomicCrmClientReviewYearType) {
		return {
			...state,
			newEconomicCrmClientReviewYearVisible: action.visible
		};
	}

	if (action.type === requestPostEconomicCrmClientType) {
		return {
			...state,
			isPosting: true,
			newEconomicCrmClientVisible: false
		};
	}

	if (action.type === requestPostEconomicCrmClientReviewYearType) {
		return {
			...state,
			isPosting: true,
			newEconomicCrmClientReviewYearVisible: false
		};
	}

	if (action.type === receivePostEconomicCrmClientType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicCrmClientReviewYearType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicCrmClientErrorType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === receivePostEconomicCrmClientErrorReviewYearType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === requestPutEconomicCrmClientType) {
		return {
			...state,
			isPutting: true,
			editEconomicCrmClientVisible: false
		};
	}

	if (action.type === receivePutEconomicCrmClientType) {		
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicCrmClientId === action.data.economicCrmClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicCrmClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestDeleteEconomicCrmClientType) {
		return {
			...state,
			isDeleting: true
		};
	}

	if (action.type === receiveDeleteEconomicCrmClientType) {		
		return {
			...state,
			isDeleting: false,
			clients: state.clients.filter((client: any) => client.economicCrmClientId !== action.data.economicCrmClientId)
		};
	}

	if (action.type === receiveDeleteEconomicCrmClientErrorType) {
		return {
			...state,
			isDeleting: false
		};
	}

	if (action.type === requestPutEconomicCrmClientType) {
		return {
			...state,
			isPutting: true
		};
	}

	if (action.type === receivePutEconomicCrmClientType) {		
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicCrmClientId === action.data.economicCrmClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicCrmClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === editEconomicCrmClientType) {		
		return {
			...state,
			editEconomicCrmClientVisible: true,
			editingEconomicCrmClientId: action.economicCrmClientId
		};
	}

	if (action.type === editEconomicCrmClientReviewYearType) {		
		return {
			...state,
			editEconomicCrmClientReviewYearVisible: true,
			editingEconomicCrmClientId: action.economicCrmClientId
		};
	}

	if (action.type === requestEconomicCrmClientsType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveEconomicCrmClientsType) {		
		return {
			...state,
			isLoadedUserId: action.userId,
			isLoading: false,
			clients: action.data
		};
	}

	if (action.type === receiveEconomicCrmClientsErrorType) {		
		return {
			...state,
			isLoadedUserId: null,
			isLoading: false,
			clients: []
		};
	}

	return state;
};