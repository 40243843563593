import { Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import "./input.scss";

interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  icon?: string;
  endIcon?: string;
  valid?: boolean | null;
  placeholder?: string;
  type?: string;
  value?: any;
  label?: string;
  name?: string;
  className?: string;
  isRequired?: boolean;
  hasChips?: boolean;
  chipValues?: string[];
}

const Input: React.FunctionComponent<InputProps> = ({
  disabled,
  icon,
  endIcon,
  valid,
  value,
  placeholder,
  type,
  onChange,
  label,
  name,
  className,
  isRequired,
  hasChips,
  chipValues
}): React.ReactElement => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    setDisplayText(value);
  }, [value]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setDisplayText(text);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`input ${className}`}>
       <div style={{ display: "flex"}}>
        <div style={{ flexGrow: 1 }}>
          <h6 className={`custom-label ${valid ? "isValid" : ""}`} style={{marginTop: hasChips ? "5px": ""}}>
            {label}
            {isRequired && <span className="text-danger">&nbsp;&nbsp;*</span>}
          </h6>
        </div>
        {
          hasChips && chipValues && chipValues.length > 0 &&
          <div style={{marginBottom: "5px"}}>
            <Stack direction="row" spacing={1}>
              {chipValues.map((value, index) => (
                 <Chip label={value} size="small" key={index} sx={{background:"#808080", color:"#fff", fontSize:"11px", fontWeight:600}}/>
              ))}
            </Stack>
          </div>
        }
       </div>
      
      <div className="input-parent">
        {icon ? (
          <span className="material-icons start-icon">{icon}</span>
        ) : null}
        <input
          disabled={disabled}
          placeholder={placeholder}
          value={displayText}
          type={type}
          name={name}
          onChange={handleTextChange}
          className={`${
            valid === null || valid === undefined
              ? "inputStyle"
              : valid === true
              ? "isValid"
              : "isInValid"
          }`}
        />
        {endIcon ? <span className="end-icon"> {endIcon} </span> : null}
      </div>
    </div>
  );
};

export default Input;