import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/reducers/Reports";
import { 
 Avatar
,Box
,Card
,CardContent
,CardHeader
,CircularProgress, Grid, IconButton
,Stack
,Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Submit from "../common/Submit";
import { useState } from "react";
import { actionCreators as homeActionCreators} from "../../store/reducers/Home";

const CurrentPCR: React.FunctionComponent = (props: any): JSX.Element => {

  const [open, setOpen] = useState(false);
 
  const printCommentsReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(false, "PcrCommentsReport");
  };

  const printPlanningReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(false, "PcrPlanningReport");
  };
  
  const handleSubmitOk = () => {
    setOpen(false);
    props.homeActions.updateSubmitDateTime();
    props.homeActions.addSubmitLogItem();
  };

  return (
    <div>
      <Card>
        <CardHeader
            title={
              <Typography className="title">
                Current Partner Contribution Report
              </Typography>
            }
            className="card-header" />
        <CardContent className="card-content">
          <Grid container>
            <Grid item sx={{ p: 2 }} xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant="body1" component="p" className="fs-14 text-center">
                      Get Started
                    </Typography>
                    <Stack  direction="row-reverse" justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ my: 2, justifyContent: "center" }}>
                      <IconButton>
                        <Avatar alt="Start here"
                                component={RouterLink}
                                to="/teamwork"
                                src="/assets/img/start-here.png"
                                sx={{ width: 70, height: 70 }}
                                variant="square"/>
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Typography variant="body1" component="p" className="fs-14 text-center">
                      Ready to submit?
                    </Typography>
                    <Stack spacing={2} direction="row" sx={{ my: 2, justifyContent: "center" }}>
                      <IconButton onClick={() => setOpen(true)} >
                        <Avatar alt="Submit" component={RouterLink} to="#"
                                src="/assets/img/submitbutton.png"
                                sx={{ width: 70, height: 70 }} variant="square"/>
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ p: 2 }} xs={6}>
              <Box>
                <Typography variant="body1" component="p" className="fs-14 text-center">
                  Select report to print
                </Typography>
                <Stack spacing={1} direction="row" sx={{ justifyContent: "center" }}>
                  <IconButton onClick={printPlanningReport}>
                    <Avatar alt="PCR 2024"
                            src="/assets/img/2024pcr.png"
                            sx={{ width: 110, height: 110 }}
                            variant="square"/>
                  </IconButton>
                  <IconButton onClick={printCommentsReport}>
                    <Avatar alt="PCR 2023"
                            src="/assets/img/2023pcr.png"
                            sx={{ width: 110, height: 110 }}
                            variant="square" />
                  </IconButton>
                  {props.isLoading ? (
                    <IconButton>
                      {" "}
                      <CircularProgress color="success" />{" "}
                    </IconButton>
                  ) : null}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Submit open={open} onClose={() => setOpen(false)} onOk={handleSubmitOk} />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    report: state.report,
    isLoading: state.report.isLoading,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    actionCreators: bindActionCreators(actionCreators, dispatch),
    homeActions: bindActionCreators(homeActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPCR);
