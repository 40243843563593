import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Avatar, IconButton, Menu, MenuItem, StepButton, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, styled } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import FlagIcon from '@mui/icons-material/Flag';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import RateReviewIcon from '@mui/icons-material/RateReview';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { KeyboardArrowDown } from '@mui/icons-material';


interface ProgressBarProps {
  sbuLeaderCommentsEnabled?: boolean,
  partnerCommentsVisible?: boolean,
}

const steps = ['Home', 'Teamwork', 'Economic', 'Diversity', 'Qualitative Goals'];
const pages = ['','teamwork', 'economic', 'diversity', 'goals']

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(0,0,233) 0%,rgb(0,0,233) 50%,rgb(0,0,233) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    border: 0,
    backgroundColor: '#155724',
    borderRadius: 1,
    marginTop:'5px'
  },
}));
                
const ColorlibStepIconRoot = styled('div')<{
ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: 'white',
  zIndex: 1,
  color: '#155724',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  borderColor: '#155724',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {color : 'blue'}), 
  }));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1:<HomeIcon />,
    2:<GroupIcon />,
    3:<AttachMoneyOutlinedIcon />,
    4:<img src="/Diversity-rounded.png"  width="25" height="25" alt="Diversity" />,
    5:<FlagIcon />, 
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function ColorlibYearEndStepIcon(props: StepIconProps) {
  const { active, completed, className } = props; 
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {<LightbulbIcon />}
    </ColorlibStepIconRoot>
  );
}

function ColorlibSBULeaderStepIcon(props: StepIconProps) {
  const { active, completed, className } = props; 
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {<RateReviewIcon />}
    </ColorlibStepIconRoot>
  );
}

function ColorlibSummaryStepIcon(props: StepIconProps) {
  const { active, completed, className } = props; 
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {<img src="/summary-icon.png"  width="25" height="25" alt="summary" />}
    </ColorlibStepIconRoot>
  );
}

const ProgressBar: React.FunctionComponent <ProgressBarProps> = ({
  sbuLeaderCommentsEnabled  
}): JSX.Element => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return( 
    <Stepper activeStep={activeStep} alternativeLabel nonLinear connector={<ColorlibConnector />}>
      {steps.map((label, index) => (
        <Step key={label}>          
          <StepButton color="inherit" className='step-link' onClick={handleStep(index)} component={RouterLink} to={`/${pages[index]}`}>
            <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ marginTop: 0}}>{label}</StepLabel> 
          </StepButton>
        </Step>
      ))}
      <Step key="Year Ed Review">          
        <StepButton color="inherit" className='step-link' onClick={handleStep(5)} component={RouterLink} to={`/yearEndReview`}>
          <StepLabel StepIconComponent={ColorlibYearEndStepIcon} sx={{ marginTop: 0}}>Year End Review</StepLabel> 
        </StepButton>
      </Step>    
      {
        sbuLeaderCommentsEnabled &&
        <Step key="SBU Leader">          
          <StepButton color="inherit" className='step-link' onClick={handleStep(6)} component={RouterLink} to={`/sbuLeader`}>
            <StepLabel StepIconComponent={ColorlibSBULeaderStepIcon} sx={{ marginTop: 0}}>SBU Leader</StepLabel> 
          </StepButton>
        </Step>
      }
      <Step key="Summary">          
        <StepButton color="inherit" className='step-link' onClick={handleStep(7)} component={RouterLink} to={`/summary`}>
          <StepLabel StepIconComponent={ColorlibSummaryStepIcon} sx={{ marginTop: 0}}>Summary</StepLabel> 
        </StepButton>
      </Step>
      <Step key="Help">       
        <div onClick={handleClick}>
          <IconButton size="small"
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}>
            <Avatar sx={{ width: 27, height: 27 }}>
              <ColorlibStepIconRoot ownerState={{ completed: undefined, active: undefined }} >
                {<QuestionMarkOutlinedIcon/>}
              </ColorlibStepIconRoot>
            </Avatar>
          </IconButton>
          <div style={{ display: "flex", cursor:"pointer", justifyContent: "center", paddingTop: "7px" }}>
            <div style={{fontFamily: "Roboto,Helvetica,Arial", fontWeight: 400, fontSize: "0.875rem", lineHeight:1.43}}>Help</div>
            <div><KeyboardArrowDown /></div>
          </div>
        </div>
        <Menu anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}>
          <MenuItem>
           <a href="Performance Metrics Definitions for PCR 120721.pdf" target="_blank">Performance Metrics Definitions for PCR</a> 
          </MenuItem>
        </Menu>
      </Step>
    </Stepper>
  )
}

export default ProgressBar;