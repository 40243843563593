import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, FormControl, Button, Typography } from "@mui/material";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicScClient } from "../../models/IEconomicScClient";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import ScClientCard from "./ScClientCard";
import EconomicScClientAddEdit from "./EconomicScClientAddEdit";
import { actionCreators as economicScActionCreators } from "../../store/reducers/EconomicScClients";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import EconomicHistory from "./EconomicHistory";
import { canEditSelector, reviewYearSelector } from "../../store/reducers/Settings";


interface EconomicScClientProps extends PropsFromRedux {
  year?:number;
  disabled?: boolean;
  canShowClientsSection: boolean;
  economic: IEconomic;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
  isReviewYear: boolean;
}

const EconomicScClient: React.FunctionComponent<EconomicScClientProps> = (props): JSX.Element => {

  useEffect(() => {    
    if(props.auth.accessToken){
      props.economicScActions.loadEconomicScClients();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);  


  const handleOnChange = (event:  any) => {
    if (props.disabled) return;

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const clientsForYear =  props.economicScClients.clients && props.economicScClients.clients
                          .filter((client : IEconomicScClient) => client.year === props.year)
                          .map((client: IEconomicScClient) =>
                              <ScClientCard key={client.economicScClientId.toString()} 
                                            client={client} 
                                            disabled={props.disabled}
                                            edit={props.isReviewYear ? props.economicScActions.editEconomicScClientReviewYear : props.economicScActions.editEconomicScClient } 
                                            remove={props.economicScActions.removeEconomicScClient} 
                                             />);
  const showScClients = clientsForYear && clientsForYear.length > 0;
  
  let economicScClient = undefined;
  if (props.economicScClients.editEconomicScClientVisible) {
    economicScClient = props.economicScClients.clients.find((client: IEconomicScClient) => client.economicScClientId === props.economicScClients.editingEconomicScClientId);
  }

let economicScClientReviewYear = undefined;
  if (props.economicScClients.editEconomicScClientReviewYearVisible) {
    economicScClientReviewYear = props.economicScClients.clients.find((client: IEconomicScClient) => client.economicScClientId === props.economicScClients.editingEconomicScClientId);
  }

  return (
    <Grid container>
       <EconomicHistory label="Revenue Dollars Collected:"
                        section="sc"
                        isRevenue
                        economicHistories={props.economicHistories} />
      <FormControl fullWidth>
        <Input  placeholder="Revenue" 
                value={Format.formatCurrencyInput(props.economic.scRevenue)}
                icon="attach_money"
                fullWidth
                disabled={props.disabled}
                type="number"                    
                name="scRevenue"
                onChange={handleOnChange}
                  valid={Utils.getValidationStateRevenue(props.economic.scRevenue)} />
      </FormControl>
      {
        (showScClients || props.canShowClientsSection) && props.isReviewYear
        &&
        <React.Fragment>
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography variant="h6" className="fs-14" sx={{ fontWeight: "700" }} >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Add one or more Clients.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {
              !props.disabled
              ? <Grid item xs={12} sx={{ p: 0.5 }}>
                  <Button color="primary" 
                          size="small" 
                          variant="contained"
                          className="addClientButton"
                          onClick={() => props.economicScActions.showNewEconomicScClientReviewYear()}>Add SC Client</Button>
                </Grid>
              : null
            }
            {props.economicScClients.newEconomicScClientReviewYearVisible && props.isReviewYear &&
              <EconomicScClientAddEdit  accessToken={props.auth.accessToken}
                                        isNew
                                        year={props.year}
                                        reviewYear={props.reviewYear}
                                        save={props.economicScActions.addEconomicScClient}
										title="Add SC Client"
                                        canEdit={!props.disabled}
                                        close={() => props.economicScActions.closeEconomicScClientReviewYear()}
                                        isReviewYear={props.isReviewYear}/>
            }
            {props.economicScClients.editEconomicScClientReviewYearVisible && props.isReviewYear &&
              <EconomicScClientAddEdit  accessToken={props.auth.accessToken}
                                        year={props.year}
                                        reviewYear={props.reviewYear}
                                        client={economicScClientReviewYear}
                                        save={props.economicScActions.updateEconomicScClient}
                                        title="Edit SC Client"
                                        close={() => props.economicScActions.closeEconomicScClientReviewYear()}
                                        canEdit={!props.disabled}
                                        isReviewYear={props.isReviewYear}/>
            }
          </Grid>
        </React.Fragment>
      }
      {
        (showScClients || props.canShowClientsSection) && !props.isReviewYear
        &&
        <React.Fragment>
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography variant="h6" className="fs-14" sx={{ fontWeight: "700" }} >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Add one or more Clients.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {
              !props.disabled
              ? <Grid item xs={12} sx={{ p: 0.5 }}>
                  <Button color="primary" 
                          size="small" 
                          variant="contained"
                          className="addClientButton"
                          onClick={() => props.economicScActions.showNewEconomicScClient()}>Add SC Client</Button>
                </Grid>
              : null
            }
            {props.economicScClients.newEconomicScClientVisible &&
              <EconomicScClientAddEdit  accessToken={props.auth.accessToken}
                                        isNew
                                        year={props.year}
                                        reviewYear={props.reviewYear}
                                        save={props.economicScActions.addEconomicScClient}
																	      title="Add SC Client"
                                        canEdit={!props.disabled}
                                        close={() => props.economicScActions.closeEconomicScClient()}
                                        isReviewYear={props.isReviewYear}/>
            }
            {props.economicScClients.editEconomicScClientVisible &&
              <EconomicScClientAddEdit  accessToken={props.auth.accessToken}
                                        year={props.year}
                                        reviewYear={props.reviewYear}
                                        client={economicScClient}
                                        save={props.economicScActions.updateEconomicScClient}
                                        title="Edit SC Client"
                                        close={() => props.economicScActions.closeEconomicScClient()}
                                        canEdit={!props.disabled}
                                        isReviewYear={props.isReviewYear}/>
            }
          </Grid>
        </React.Fragment>
      }      
    </Grid>
  )
}

const mapStateToProps = (state: any) => {
	return {
    auth: state.auth,
    economicScClients: state.economicScClients,
    canEdit: canEditSelector(state),
    reviewYear: reviewYearSelector(state),
	};
};
const mapDispatchToProps = (dispatch: any) => {
	return {    
    economicScActions: bindActionCreators(economicScActionCreators, dispatch),
	};
};

const connector = connect(mapStateToProps,mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(EconomicScClient)