const settingsUtil = {
    getCanEditProxy: function (settings: any, isProxy: boolean) {
      
      const canEditProxySetting = settings.find((s: { name: string }) => s.name === "canEditProxy");

      if (canEditProxySetting !== undefined && canEditProxySetting !== null)
        return canEditProxySetting.value === "true";
    }
};


export default settingsUtil;