import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  PaginationState,
} from "@tanstack/react-table";
import Filter from "./Filter/Filter";

import "../admin.scss";
import { useState, useEffect } from "react";
import Pagination from "./Pagination/Pagination";
import React from "react";

export default function AdminTable({
  data,
  columns,
  showFilter,
  showPagination,
  defaultSorted,
  handleRowClick,
  handleRowSelection,
  canSelectRow = false,
  rowClickable,
}: {
  data: any;
  columns: ColumnDef<any>[];
  showFilter: Boolean;
  showPagination: Boolean;
  defaultSorted?: any;
  handleRowClick?: any;
  handleRowSelection?: any;
  canSelectRow?: Boolean;
  rowClickable?: Boolean;
}) {
  const defaultPagination = {
    pageIndex: 0,
    pageSize: 20,
  };
  const [pagination, setPagination] =
    useState<PaginationState>(defaultPagination);
  const [sorting, setSorting] = useState<SortingState>(defaultSorted ?? []);
  const [rowSelection, setRowSelection] = useState({});

  const onRowSelectionChange = (rowselectionData: any) => {
    setRowSelection(rowselectionData);
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      rowSelection,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    if (canSelectRow) {
      handleRowSelection(table.getSelectedRowModel().flatRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const renderTableHeader = (headerGroup: any) => {
    return headerGroup.headers.map((header: any) => {
      return (
        <React.Fragment key={header.id}>
          <th
            colSpan={header.colSpan}
            className={`${header.column.getIsSorted()}`}
            style={{
              maxWidth: header.getSize(),
              whiteSpace: "initial",
            }}
          >
            {header.isPlaceholder ? null : (
              <div
                {...{
                  className: header.column.getCanSort()
                    ? `cursor-pointer select-none`
                    : ``,
                  onClick: header.column.getToggleSortingHandler(),
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </div>
            )}
          </th>
        </React.Fragment>
      );
    });
  }

  const renderTableFilter = (headerGroup: any) => {
    return headerGroup.headers.map((header: any) => {
      return (
        <React.Fragment key={header.id}>
          <th colSpan={header.colSpan} className="filters">
            {header.column.getCanFilter() ? (
              <div>
                <Filter column={header.column} table={table} />
              </div>
            ) : null}
          </th>
        </React.Fragment>
      );
    });
  };

  const renderTableBody = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <tr
          key={row.id}
          onClick={() => (rowClickable ? handleRowClick(row.original) : null)}
          style={rowClickable ? { cursor: "pointer" } : {}}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                style={{
                  maxWidth: cell.column.getSize(),
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="p-1 admin-table">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <React.Fragment key={headerGroup.id}>
              <tr>{renderTableHeader(headerGroup)}</tr>
              {showFilter && <tr>{renderTableFilter(headerGroup)}</tr>}
            </React.Fragment>
          ))}
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </table>
      <div className="h-2" />
      {showPagination && <Pagination table={table} />}
    </div>
  );
}
