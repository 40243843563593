import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';

interface ProgressBarProps {
  open: boolean;
  onClose(): void;
  onOk(): void;
  title: string;
}

// eslint-disable-next-line no-empty-pattern
const Confirmation: React.FunctionComponent<ProgressBarProps> = ({
  open,
  onClose,
  onOk,
  title
}): JSX.Element => {

  return (
    <Dialog open={open}
            onClose={onClose}>

    <DialogTitle id="alert-dialog-title">
      <IconButton> 
        <DangerousIcon  color="error" 
                        sx={{ fontSize: "40px", marginRight:"10px" }}/> Are you sure?
      </IconButton> 
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
       {title}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" size="medium" onClick={onClose}>Cancel</Button>
      <Button variant="contained" size="medium" onClick={onOk} autoFocus color="error">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
  );
};

export default Confirmation;
