const initialState = {
  account: null,
  error: null,
  idToken: null,
  accessToken: null,
  isAuthenticated: false,
  id: null,
  userPrincipalName: null,
  givenName: null,
  surname: null,
  jobTitle: null,
  mobilePhone: null,
  preferredLanguage: null,
  firstLogin: true, 
  user:null,
  isLoading: true,
  userId: null,
  currentUserId: null,
  proxyUsers:[],
  roles:[]
};

export const updateAccount = (payload: any) => ({
  type: "UPDATE_ACCOUNT",
  payload,
});

export const updateError = (payload: any) => ({
  type: "UPDATE_ERROR",
  payload,
});

export const updateProfile = (payload: any) => ({
  type: "UPDATE_PROFILE",
  payload,
});

export const updateIdentity = (payload: any) => ({
  type: "UPDATE_IDENTITY",
  payload,
});

export const updateProxyUsers = (payload: any) => ({
  type: "UPDATE_PROXY_USERS",
  payload,
});

export const updateUserRoles = (payload: any) => ({
  type: "UPDATE_USER_ROLES",
  payload,
});


export const updateToken = (payload: any) => ({
  type: "UPDATE_TOKEN",
  payload,
});

export const updateLoadingStatus = (payload: boolean) => ({
  type: "UPDATE_LOADER",
  payload,
});

export const updateUserId = (payload: any) => ({
  type: "UPDATE_USER_ID",
  payload,
});

export const updateCurrentUserId = (payload: any) => ({
  type: "UPDATE_CURRENT_USER_ID",
  payload,
});


export const reducer = (state = initialState, action: any) => {  
  switch (action.type) {
    case "UPDATE_ACCOUNT":      
      return Object.assign({}, state, {
        account: action.payload,
        idToken: action.payload.idTokenClaims,
        isAuthenticated: true
      });

    case "UPDATE_ERROR":
      return Object.assign({}, state, {
        error: action.payload,
        isAuthenticated: false
      });

    case "UPDATE_TOKEN":
      return Object.assign({}, state, {
        accessToken: action.payload
    });

    case "UPDATE_PROFILE":
      return Object.assign({}, state, {
        id: action.payload.id,
        userPrincipalName: action.payload.userPrincipalName,
        givenName: action.payload.givenName,
        surname: action.payload.surname,
        jobTitle: action.payload.jobTitle,
        mobilePhone: action.payload.mobilePhone,
        preferredLanguage: action.payload.preferredLanguage,
        user:action.payload.user,
        isExceptionUser: action.payload.isExceptionUser,
        firstLogin: false,
    });

    case "UPDATE_IDENTITY":
      return Object.assign({}, state, {
        user:action.payload.user,
    });

    case "UPDATE_PROXY_USERS":
      return Object.assign({}, state, {
        proxyUsers:action.payload.proxyUsers,
    });

    case "UPDATE_USER_ROLES":
      return Object.assign({}, state, {
        roles:action.payload.roles,
    });

    case "UPDATE_LOADER":
      return Object.assign({}, state, {
        isLoading:action.payload
    });

    case "UPDATE_USER_ID":
      return Object.assign({}, state, {
        userId:action.payload
    });

    case "UPDATE_CURRENT_USER_ID":
      return Object.assign({}, state, {
        currentUserId:action.payload
    });

    default:
      return state;
  }
}