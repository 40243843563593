import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { goalAreas as areas } from "../../utils/goal-areas";
import utils from "../../utils/Utils";
import Confirmation from "../common/Confirmation";
import TextArea from "../common/textarea/TextArea";

const goalNewState = {
  DEFAULT: "DEFAULT",
  AREA: "AREA",
  CATEGORY: "CATEGORY",
  DETAILS: "DETAILS",
};

interface GoalProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  canEdit: Boolean;
  deleteGoal: any;
  goal: any;
  updateGoal: any;
  partnerCommentsEnabled?: Boolean;
  partnerCommentsVisible: Boolean;
  sbuLeaderCommentsEnabled?: Boolean;
}

const Goal: React.FunctionComponent<GoalProps> = ({
  canEdit,
  deleteGoal,
  goal,
  updateGoal,
}): React.ReactElement => {
  const [newState, setNewState] = useState(goalNewState.DEFAULT);
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
;
  const handleDelete = () => {
    if (!canEdit) return;
    setShowConfirmation(true);
  };

	const handleDeleteOk = () => {
		setShowConfirmation(false);
		deleteGoal(goal.goalId);
	}

  const handleEdit = () => {
    setNewState(goalNewState.DETAILS);
    setText(goal.text);
    setDescription(goal.description);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleTextChange = (event: any) => {
    setText(event.target.value);
  };

  const handleCancel = () => {
    setNewState(goalNewState.DEFAULT);
    setText("");
    setDescription("");
  };

  const handleSave = () => {
		const newGoal = { ...goal, text, description };
		updateGoal(newGoal);
		setNewState(goalNewState.DEFAULT);
    setText("");
    setDescription("");
  };

  const renderDetails = () => {
    const selectedArea = areas.find((a) => a.name === goal.area) as any;
    const selectedCategory = selectedArea.categories.find(
      (c: any) => c.name === goal.category
    ) as any;
    const textLabel = selectedCategory.textLabel;
    const descriptionLabel = selectedCategory.descriptionLabel;

    const saveEnabled = utils.hasText(text) && utils.hasText(description);
    const disabled = !canEdit;

    const confirmationText = "Do you really want to delete this goal?  This process cannot be undone.";

    return (
      <>
        <Paper
          elevation={3}
          square
          sx={{ p: 2 }}
          className="details"
          style={{ width: "400px", height: "auto" }}
        >
          <Typography variant="h5" sx={{ color: "#0039a6" }}>
            {goal.area} - {goal.category}
          </Typography>
          <Grid item>
            <TextArea
              label={textLabel}
              value={text}
              disabled={disabled}
              name="text"
              placeholder="Goal"
              onChange={handleTextChange}
              limit={1100}
            />
          </Grid>
          <Grid item>
            <TextArea
              label={descriptionLabel}
              value={description}
              disabled={disabled}
              name="description"
              placeholder="Plan"
              onChange={handleDescriptionChange}
              limit={1100}
            />
          </Grid>
          {canEdit ? (
            <Grid
              item
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <Grid>
                <Button
                  variant="outlined"
                  color="error"
                  size="medium"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ ml: 1 }}
                  size="medium"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Grid>
              <Button
                variant="contained"
                size="medium"
                disabled={!saveEnabled}
                onClick={handleSave}
              >
                Submit
              </Button>
            </Grid>
          ) : (
            <Grid item sx={{ justifyContent: "end", display: "flex" }}>
              <Button
                variant="outlined"
                color="error"
                size="medium"
                onClick={handleCancel}
              >
                Close
              </Button>
            </Grid>
          )}
        </Paper>
        <Confirmation
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onOk={handleDeleteOk}
          title={confirmationText}
        />
      </>
    );
  };

  return (
    <>
      {newState === goalNewState.DEFAULT ? (
        <Paper
          elevation={3}
          square
          className="notes"
          onClick={handleEdit}
          style={{ width: "150px", height: "150px" }}
        >
          <Typography variant="subtitle1">{goal.area}</Typography>
          <Typography variant="subtitle1">{goal.category}</Typography>
          <Typography variant="body1">{goal.text}</Typography>
        </Paper>
      ) : (
        renderDetails()
      )}
    </>
  );
};

export default Goal;
