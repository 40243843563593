import {
  Autocomplete,
  Container,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../admin.scss";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import AdminTable from "../AdminTable/AdminTable";
import { baseUrl } from "../../../utils/authConfig";
import { connect } from "react-redux";
import ConfirmationAdd from "../../common/ConfirmationAdd";
import { useNavigate } from "react-router-dom";

type AdminStaffModel = {
  lastNameFirst: string;
  title: string;
  sbu: string;
  psc: string;
  office: string;
};

const AdminStaff: React.FunctionComponent = (props: any): JSX.Element => {
  const columns = useMemo<ColumnDef<AdminStaffModel>[]>(
    () => [
      {
        accessorKey: "lastNameFirst",
        header: () => "Name",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "title",
        header: () => "Friendly Title",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "sbu",
        header: "SBU",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "psc",
        header: "PSC",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "office",
        header: "Office",
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showConfirmationDialog, setConfirmationDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null) as any;
  const navigate = useNavigate();

  useEffect(() => {
    if (props.auth.accessToken) {
      loadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const loadUsers = async () => {
    const accessToken = props.auth.accessToken;

    try {
      const url = `${baseUrl}api/Users/ActiveFirmStaffList`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        setLoading(false);
        return;
      }
      const data = await response.json();
      setUsers(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUserChange = (_event: any, value: any) => {
    if (!value) return;
    setSelectedUser(value);
    setConfirmationDialogOpen(true);
  };

  const addCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const addOk = async () => {
    setConfirmationDialogOpen(false);
    await addUser(selectedUser.ntId);
  };

  const addUser = async (ntId: string) => {
    const accessToken = props.auth.accessToken;

    try {
      const url = `${baseUrl}api/Users/InsertStaff/${ntId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        setSelectedUser(null);
        setConfirmationDialogOpen(false);
        return;
      }
      const data = await response.json();
      navigate(`/admin/user/${data.userId}`);
    } catch (e) {
      setSelectedUser(null);
      setConfirmationDialogOpen(false);
    }
  };

  const handleRowClick = (rowData: any) => {
    if (!rowData) return;
    setSelectedUser(rowData);
    setConfirmationDialogOpen(true);
  };

  const defaultSorted = [{ id: "lastNameFirst", desc: false }];

  const options = [...users] as AdminStaffModel[];

  options.sort((a: AdminStaffModel, b: AdminStaffModel) => {
    var aName = a.lastNameFirst.toLowerCase();
    var bName = b.lastNameFirst.toLowerCase();
    if (aName < bName) return -1;
    if (aName > bName) return 1;
    return 0;
  });

  const name = selectedUser ? selectedUser.lastNameFirst : "";
  const confirmationText = `Are you sure you want to add ${name} as a user?`;

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5">Admin Staff</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is a list of all of the current staff. Select one to add them as
          a user.
        </Typography>
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <InputLabel className="label">Name:</InputLabel>
        <Autocomplete
          id="admin-staff-autocomplete"
          options={options}
          getOptionLabel={(option: AdminStaffModel) => option.lastNameFirst}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              className="group-textfield"
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid>
        <AdminTable
          {...{
            data: users,
            columns,
            showFilter: false,
            showPagination: true,
            defaultSorted,
            handleRowClick: handleRowClick,
            rowClickable: true,
          }}
        />
      </Grid>
      <ConfirmationAdd
        open={showConfirmationDialog}
        text={confirmationText}
        onClose={addCancel}
        onOk={addOk}
      />
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null)(AdminStaff);
