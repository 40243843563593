import { actionCreators as errorActionCreators } from "./Error";
import FetchQueue from "../../utils/FetchQueue";
import { baseUrl } from "../../utils/authConfig";
import { IPartnerComment } from "../../models/IPartnerComments";

const requestPartnerCommentsType = "REQUEST_PARTNER_COMMENTS";
const receivePartnerCommentsType = "RECEIVE_PARTNER_COMMENTS";
const receivePartnerCommentsErrorType = "RECEIVE_PARTNER_COMMENTS_ERROR";
const requestPostPartnerCommentsType = "REQUEST_POST_PARTNER_COMMENTS";
const receivePostPartnerCommentsType = "RECEIVE_POST_PARTNER_COMMENTS";
const receivePostPartnerCommentsErrorType = "RECEIVE_POST_PARTNER_COMMENTS_ERROR";

const initialState = {
	isLoaded: false,
	isLoading: false,
	isSaving: false,
	data: {}	
};

const saveQueue = new FetchQueue(`${baseUrl}api/PartnerComments/`,
	"PUT",
	null,
	function(dispatch: any, response: any) {
		dispatch({ type: receivePostPartnerCommentsErrorType, response: response });
		dispatch(errorActionCreators.addError("An error occurred while updating partner comments.", null, response));},
	function(dispatch: any, exception: any) {
		dispatch({ type: receivePostPartnerCommentsErrorType, exception: exception });
		dispatch(errorActionCreators.addError("An exception occurred while updating partner comments.", exception, null));});

export const actionCreators = {
	loadPartnerComments: () => async (dispatch: any, getState: any) => {		
		var state = getState();
		const { userId } = state.auth;
		
    if(!userId){
      return;
    }
		if (state.partnerComments.data.userId === userId) {
			return;
		}
		if (state.partnerComments.isLoading) {			
			return;
		}

		dispatch({ type: requestPartnerCommentsType });
		const url = `${baseUrl}api/PartnerComments/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receivePartnerCommentsErrorType, response: response });
				return;
			}
			const data = await response.json();
			dispatch({ type: receivePartnerCommentsType, data: data });      
		} catch (exception) {
			dispatch({ type: receivePartnerCommentsErrorType, exception: exception });
		}
	},
	savePartnerComments: (data: IPartnerComment) => async (dispatch: any, getState: any) => {
		if (!data.partnerCommentsId) {
			return;
		}

		dispatch({ type: requestPostPartnerCommentsType, data: data });		
		dispatch({ type: receivePartnerCommentsType, data: data });
		const url = `${baseUrl}api/PartnerComments/${data.partnerCommentsId}`;
		saveQueue.queue(data.partnerCommentsId, data, getState().auth.accessToken, dispatch, url);
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestPartnerCommentsType) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receivePartnerCommentsType) {
		return {
			...state,
			isLoading: false,
			data: action.data
		};
	}

	if (action.type === receivePartnerCommentsErrorType) {
		return {
			...state,
			isLoading: false
		};
	}

	if (action.type === requestPostPartnerCommentsType) {
		const newState = {
			...state,
			isSaving: true,
			data: action.data
		};
		return newState;
	}

	if (action.type === receivePostPartnerCommentsType) {
		return {
			...state,
			isSaving: false
		};
	}

	if (action.type === receivePostPartnerCommentsErrorType) {
		return {
			...state,
			isSaving: false
		};
	}

	return state;
};