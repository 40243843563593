/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputLabel, Stack, Chip, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import Format from "../../utils/Format";

interface EconomicHistoryProps {
  section: string;
  label: string;
  isRevenue?: boolean;
  isDm?: boolean;
  isBillableHours?: boolean;
  isinvestmentHours?:boolean;
  economicHistories?: Array<IEconomicHistory>;
}

const EconomicHistory: React.FunctionComponent<EconomicHistoryProps> = ({
  section,
  label,
  isRevenue,
  isDm,
  isBillableHours,
  isinvestmentHours,
  economicHistories
}): JSX.Element => {

  const [currentYearText, setcurrentYearText] = useState("");
  const [previousYearText, setPreviousYearText] = useState("");
  const [prePreviousYearText, setPrePreviousYearText] = useState("");

  var currentYear = new Date().getFullYear();
  const currentYearHistory = economicHistories && economicHistories.find(e => e.year === currentYear);
  const previousYearHistory = economicHistories && economicHistories.find(e => e.year === currentYear-1);
  const prePreviousYearHistory = economicHistories && economicHistories.find(e => e.year === currentYear-2);

  const tooltipCurrentYear = currentYearHistory ? "Actual as of " + Format.formatDate(currentYearHistory.asOfDate) : "";
  const tooltipPreviousYear = previousYearHistory ? "Actual as of " + Format.formatDate(previousYearHistory.asOfDate) : "";
  const tooltipPrePreviousYear = prePreviousYearHistory ? "Actual as of " + Format.formatDate(prePreviousYearHistory.asOfDate) : "";


  useEffect(() => {
    if(section === "crm"){
      if(isRevenue){
        setcurrentYearText(Format.formatCurrency(currentYearHistory?.crmRevenue));
        setPreviousYearText(Format.formatCurrency(previousYearHistory?.crmRevenue));
        setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.crmRevenue))
      }
      else if(isDm){
        setcurrentYearText(Format.formatPercentage(currentYearHistory?.crmDm));
        setPreviousYearText(Format.formatPercentage(previousYearHistory?.crmDm));
        setPrePreviousYearText(Format.formatPercentage(prePreviousYearHistory?.crmDm))
      }
    }
    if(section === "sc"){      
      setcurrentYearText(Format.formatCurrency(currentYearHistory?.scRevenue));
      setPreviousYearText(Format.formatCurrency(previousYearHistory?.scRevenue));
      setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.scRevenue));
    }
    if(section === "ma"){
      if(isRevenue){
        setcurrentYearText(Format.formatCurrency(currentYearHistory?.maRevenue));
        setPreviousYearText(Format.formatCurrency(previousYearHistory?.maRevenue));
        setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.maRevenue))
      }
      else if(isDm){
        setcurrentYearText(Format.formatPercentage(currentYearHistory?.maDm));
        setPreviousYearText(Format.formatPercentage(previousYearHistory?.maDm));
        setPrePreviousYearText(Format.formatPercentage(prePreviousYearHistory?.maDm))
      }
    }
    if(section === "ncc"){
      setcurrentYearText(Format.formatCurrency(currentYearHistory?.nccRevenue));
      setPreviousYearText(Format.formatCurrency(previousYearHistory?.nccRevenue));
      setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.nccRevenue));
    }
    if(section === "pc"){
      setcurrentYearText(Format.formatCurrency(currentYearHistory?.pcRevenue));
      setPreviousYearText(Format.formatCurrency(previousYearHistory?.pcRevenue));
      setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.pcRevenue));
    }
    if(section === "wa"){
      if(isRevenue){
        setcurrentYearText(Format.formatCurrency(currentYearHistory?.waCollections));
        setPreviousYearText(Format.formatCurrency(previousYearHistory?.waCollections));
        setPrePreviousYearText(Format.formatCurrency(prePreviousYearHistory?.waCollections))
      }
      else if(isBillableHours){
        setcurrentYearText(Format.formatHours(currentYearHistory?.waBillableHours));
        setPreviousYearText(Format.formatHours(previousYearHistory?.waBillableHours));
        setPrePreviousYearText(Format.formatHours(prePreviousYearHistory?.waBillableHours))
      }
      else if(isinvestmentHours){
        setcurrentYearText(Format.formatHours(currentYearHistory?.waInvestmentHours));
        setPreviousYearText(Format.formatHours(previousYearHistory?.waInvestmentHours));
        setPrePreviousYearText(Format.formatHours(prePreviousYearHistory?.waInvestmentHours))
      }

    }
  }, [economicHistories]);

  
  return (    
    <Grid container>
      <InputLabel className="label green-label">
        {label}
      </InputLabel>
      <Stack className="parent-chip" direction="row" spacing={0.5} sx={{ my: 1, ml: 0.5 }} >
        <Tooltip title={tooltipCurrentYear} placement="bottom">
         <Chip label={currentYear + ": " +  currentYearText} size="small" className="label-chip" />
        </Tooltip>
        <Tooltip title={tooltipPreviousYear} placement="bottom">
          <Chip label={(currentYear-1) + ": " +  previousYearText} size="small" className="label-chip" />
        </Tooltip>
       <Tooltip title={tooltipPrePreviousYear} placement="bottom">
          <Chip label={(currentYear-2) + ": " +  prePreviousYearText} size="small" className="label-chip" />
        </Tooltip>
      </Stack>
    </Grid>
  )
}

export default EconomicHistory;