import { Grid, FormControl } from "@mui/material";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import EconomicHistory from "./EconomicHistory";

interface EconomicMAProps {
  disabled?: boolean;
  economic: IEconomic;
  canEdit?: boolean;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
}

const EconomicMA: React.FunctionComponent<EconomicMAProps> = ({
  disabled,
  onChange,
  economic,
  economicHistories
}): JSX.Element => {

  const handleOnChange = (event:  any) => {
    if (disabled) return;

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div>
      <Grid container>
        <EconomicHistory  label="Revenue Dollars Collected:"
                          section="ma"
                          isRevenue
                          economicHistories={economicHistories} />
        <FormControl fullWidth>
          <Input  placeholder="Revenue" 
                  value={Format.formatCurrencyInput(economic.maRevenue)}
                  icon="attach_money"
                  fullWidth
                  disabled={disabled}
                  type="number"                    
                  name="maRevenue"
                  onChange={handleOnChange}
                  valid={Utils.getValidationStateRevenue(economic.maRevenue)} /> 
        </FormControl>
      </Grid>
      <Grid container>
        <EconomicHistory  label="Direct Margin:"
                          section="ma"
                          isDm
                          economicHistories={economicHistories} />
        <FormControl fullWidth>
          <Input  placeholder="Margin" 
                  value={Format.formatPercentageInput(economic.maDm)}
                  endIcon="%"
                  fullWidth
                  disabled={disabled}
                  type="number"
                  name="maDm"
                  onChange={handleOnChange}
                  valid={Utils.getValidationStateDm(economic.maDm)} />
        </FormControl>       
      </Grid>
    </div>
  )
}

export default EconomicMA;