import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import { Link as RouterLink, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./adminUser.scss";
import { useEffect, useState } from "react";
import FetchQueue from "../../../utils/FetchQueue";
import { actionCreators as proxyActionCreators } from "../../../store/reducers/ProxyUser";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { baseUrl } from "../../../utils/authConfig";
import Format from "../../../utils/Format";

const AdminUser: React.FunctionComponent = (props: any): JSX.Element => {
  const params = useParams();
  const userId = params.userId;
  const userRolesFetchQueue = new FetchQueue(
    `${baseUrl}api/Users/Roles/${userId}`,
    "PUT"
  );
  const isAttorneyFetchQueue = new FetchQueue(
    `${baseUrl}api/Users/IsAttorney/${userId}`,
    "PUT"
  );

  const [, setLoading] = useState(false);
  const [user, setUser] = useState({}) as any;
 const [userRoleValues, setUserRoleValues] = useState([]) as any;
const [userRoleOptions] = useState([
    { value: "admin", label: "admin" },
  ]) as any;

  useEffect(() => {
    if (props.auth.accessToken) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const loadUser = async () => {
    const accessToken = props.auth.accessToken;

    try {
      const url = `${baseUrl}api/Users/UserWithRolesAndProxies/${userId}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        setLoading(false);
        return;
      }
      const data = await response.json();
      data.userRoles.sort((a: any, b: any) => {
        var aName = a.role.toLowerCase();
        var bName = b.role.toLowerCase();
        return aName.localeCompare(bName);
      });
      const userRoleValues = data.userRoles.map((ur: any) => ({
        value: ur.role,
        label: ur.role,
      }));

      data.proxyUsers = data.proxyUsers.filter(
        (a: any) => a.proxyUserUser != null
      ).sort((a: any, b: any) => {
        var aName = a.proxyUserUser.name.toLowerCase();
        var bName = b.proxyUserUser.name.toLowerCase();
        return aName.localeCompare(bName);
      });

      setUserRoleValues(userRoleValues);
      setUser(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };


  const handleIsAttorney = (event: any) => {
    const accessToken = props.auth.accessToken;
    const isAttorney = event.target.checked;
    var userData = {
      ...user,
      isAttorney: isAttorney,
    } as any;
    setUser(userData);
    isAttorneyFetchQueue.queue(user.userId, isAttorney, accessToken);
    props.proxyActions.updateProxyUser(user.userId, isAttorney);
  };

  const handleUserRoles = (selectedOptions: any) => {
    const accessToken = props.auth.accessToken;
    const userId = user.userId;
    const userRoles = selectedOptions.map((o: any) => o.value);
    setUserRoleValues(selectedOptions);
    userRolesFetchQueue.queue(userId, userRoles, accessToken);
  };


  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin-user container">
      <Grid item className="header">
        <Typography variant="h5">Admin User - {user && user.lastNameFirst}</Typography>
      </Grid>
      <Grid item className="header">
        <RouterLink to="/admin/users/">
          <ArrowBackIosIcon sx={{ fontSize: "0.7rem" }} />
          Back to Users
        </RouterLink>
      </Grid>
      <Grid item className="p-1">
        <Card>
          <CardHeader
            title={<Typography className="title">Details</Typography>}
            className="card-header"
          />
          <CardContent className="card-content">
            <Grid item sx={{ px: 2, pt: 2, pb: 0.5 }}>
              <InputLabel className="label green-label">Name</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="group-textfield"
                fullWidth
                size="small"
                disabled
                value={Format.formatString(user.lastNameFirst)}
              />
            </Grid>
            <Grid item sx={{ px: 2, py: 0.5 }}>
              <InputLabel className="label green-label">Office</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="group-textfield"
                fullWidth
                size="small"
                disabled
                value={Format.formatString(user.office)}
              />
            </Grid>
            <Grid item sx={{ px: 2, py: 0.5 }}>
              <InputLabel className="label green-label">SBU</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="group-textfield"
                fullWidth
                size="small"
                disabled
                value={Format.formatString(user.sbu)}
              />
            </Grid>
            <Grid item sx={{ px: 2, py: 0.5 }}>
              <InputLabel className="label green-label">PSC</InputLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                className="group-textfield"
                fullWidth
                size="small"
                disabled
                value={Format.formatString(user.psc)}
              />
            </Grid>
            <Grid item sx={{ px: 2, py: 0.5 }}>
              <InputLabel className="label">Is Attorney</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ py: 0.5 }}
                    checked={Format.formatCheckboxInput(user.isAttorney)}
                    onChange={handleIsAttorney}
                  />
                }
                label="Is Attorney?"
              />
            </Grid>
            <Grid item sx={{ px: 2, pt: 0.5, pb: 2 }}>
              <div style={{ width: "100%", display: "flex" }}>
                <InputLabel className="label">Roles:</InputLabel>
              </div>
              <Select
                id="userroles"
                options={userRoleOptions}
                isClearable={false}
                isMulti
                value={userRoleValues}
                onChange={handleUserRoles}
                closeMenuOnSelect
                className="user-react-select"
                menuPortalTarget={document.body}
                styles={{menuPortal: base => ({...base, zIndex: 9999})}}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>     
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    identity: state.proxyUser,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    proxyActions: bindActionCreators(proxyActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);
