import { apiConfig } from "../../utils/authConfig";
import {
  updateProfile,
  updateIdentity,
  updateLoadingStatus,
  updateUserId,
  updateProxyUsers,
  updateCurrentUserId,
  updateUserRoles,
} from "../reducers/Auth";

export const getProfile = () => (dispatch: any, getState: any) => {
  dispatch(updateLoadingStatus(true));
  return fetch(apiConfig.resourceUri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getState().auth.accessToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response && response.status !== 404) {
        return response.json();
      }
    })
    .then((response) => {
      if (response) {
        dispatch(updateUserId(response.user.userId));
        dispatch(updateCurrentUserId(response.user.userId));
        dispatch(updateProfile(response));
        dispatch(updateIdentity(response));
        dispatch(updateProxyUsers(response));
        dispatch(updateUserRoles(response));
      }
    })
    .finally(() => {
      dispatch(updateLoadingStatus(false));
    });
};
