import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { scroller } from 'react-scroll';
import { goalAreas as areas } from "../../../utils/goal-areas";
import utils from "../../../utils/Utils";
import TextArea from "../../common/textarea/TextArea";

const goalNewState = {
  DEFAULT: "DEFAULT",
  AREA: "AREA",
  CATEGORY: "CATEGORY",
  DETAILS: "DETAILS",
};

interface AddNewGoalProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  addGoal: any;
  year: any;
}

const AddNewGoal: React.FunctionComponent<AddNewGoalProps> = ({
  addGoal,
  year,
}): React.ReactElement => {
  const [, setNewState] = useState(goalNewState.DEFAULT);
  const [enableArea, setEnableArea] = useState(false);
  const [enableCategory, setEnableCategory] = useState(false);
  const [enableDetails, setEnableDetails] = useState(false);
  const [area, setArea] = useState(null);
  const [category, setCategory] = useState(null);
  const [text, setText] = useState("");
  const [description, setDescription] = useState("");

  
  const handleAddNewGoal = (event: any) => {
    setEnableArea(true);
    scrollTo(event, 'addNewGoalView', -200);
  };

  const scrollTo = (event: any, target: any, offset: any) => {
    event.preventDefault();
    scroller.scrollTo(target, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset,
    });
    return false;
  };

  const handleSelectArea = (area: any) => {
    setNewState(goalNewState.CATEGORY);
    setArea(area);
    setEnableCategory(area !== "Innovation");
    setEnableDetails(area === "Innovation");
    if (area === "Innovation") {
      handleSelectCategory("Development & Marketing / Sales");
    }
  };

  const handleSelectCategory = (category: any) => {
    setNewState(goalNewState.DETAILS);
    setCategory(category);
    setEnableDetails(true);
  };

  const renderArea = () => {
    return (
      <Paper
        elevation={3}
        square
        sx={{ p: 2 }}
        className="add-notes"
        style={{ height: "auto", width: "auto" }}
      >
        <Typography variant="h5">
          Please select one of the following areas to enter a NEW goal:
        </Typography>
        <List className="area-list" dense={true}>
          {areas.map((area) => (
            <ListItem key={area.name}>
              <ListItemButton
                className="button"
                data-area={area.name}
                onClick={() => handleSelectArea(area.name)}
              >
                <ListItemText
                  primary={`${area.longName}: ${area.description}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {enableArea && !enableCategory && !enableDetails && (
          <Grid item sx={{ justifyContent: "left", display: "flex", mt: 1 }}>
            <Button
              variant="contained"
              color="error"
              size="medium"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Paper>
    );
  };

  const renderCategory = () => {
    const selectedArea = areas.find((a) => a.name === area) as any;
    const categories = selectedArea.categories;

    return (
      <Paper
        elevation={3}
        square
        sx={{ p: 2 }}
        className="add-notes"
        style={{ height: "auto", width: "auto" }}
      >
        <Typography variant="h5">
          Please select one of the following areas to enter a NEW goal:
        </Typography>
        <List className="area-list" dense={true}>
          {categories &&
            categories.map((category: any) => (
              <ListItem key={category.name}>
                <ListItemButton
                  className="button"
                  data-area={category.name}
                  onClick={() => handleSelectCategory(category.name)}
                >
                  <ListItemText primary={`${category.name}`} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        {enableArea && enableCategory && !enableDetails && (
          <Grid item sx={{ justifyContent: "left", display: "flex", mt: 1 }}>
            <Button
              variant="contained"
              color="error"
              size="medium"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Paper>
    );
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleTextChange = (event: any) => {
    setText(event.target.value);
  };

  const handleCancel = () => {
    setNewState(goalNewState.DEFAULT);
    setArea(null);
    setCategory(null);
    setText("");
    setDescription("");
    setEnableArea(false);
    setEnableCategory(false);
    setEnableDetails(false);
  };

  const handleSave = () => {
    const goal = { area, category, text, description, year };
    addGoal(goal);

    setNewState(goalNewState.DEFAULT);
    setArea(null);
    setCategory(null);
    setText("");
    setDescription("");
    setEnableArea(false);
    setEnableCategory(false);
    setEnableDetails(false);
  };

  const renderDetails = () => {
    const selectedArea = areas.find((a) => a.name === area) as any;
    const selectedCategory = selectedArea.categories.find(
      (c: any) => c.name === category
    ) as any;
    const textLabel = selectedCategory.textLabel;
    const descriptionLabel = selectedCategory.descriptionLabel;

    const saveEnabled =
      area && category && utils.hasText(text) && utils.hasText(description);
    return (
      <>
        <Paper
          elevation={3}
          square
          sx={{ p: 2 }}
          className="add-notes"
          style={{ height: "auto", width: "auto" }}
        >
          <Typography variant="h6" sx={{ color: "#0039a6" }}>
            {area} - {category}
          </Typography>
          <Grid item>
            <TextArea
              label={textLabel}
              value={text}
              name="text"
              placeholder="Goal"
              onChange={handleTextChange}
              limit={1100}
            />
          </Grid>
          <Grid item>
            <TextArea
              label={descriptionLabel}
              value={description}
              name="description"
              placeholder="Plan"
              onChange={handleDescriptionChange}
              limit={1100}
            />
          </Grid>
          <Grid item sx={{ justifyContent: "space-between", display: "flex" }}>
            <Button
              variant="contained"
              color="error"
              size="medium"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              disabled={!saveEnabled}
              onClick={handleSave}
            >
              Submit
            </Button>
          </Grid>
        </Paper>
      </>
    );
  };

  return (
    <>
      {!enableArea && (
        <Paper
          elevation={3}
          square
          sx={{ p: 2 }}
          className="add-notes add-new-goal"
          onClick={handleAddNewGoal}
          style={{ height: "150px", width: "150px" }}
        >
          <Typography variant="body1">Add new goal</Typography>
        </Paper>
      )}
      <Grid container id="addNewGoalView">
        <Grid item xs={6}>{enableArea && renderArea()}</Grid>
        <Grid item xs={6}>
          {enableCategory && renderCategory()}
          {enableDetails && renderDetails()}
        </Grid>
      </Grid>
    </>
  );
};

export default AddNewGoal;
