import { useState } from "react";
import { Typography, Card, IconButton, CardActionArea, CardContent, Stack } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import "./economic.scss";
import Confirmation from "../common/Confirmation";
import { IEconomicCrmClient } from "../../models/IEconomicCrmClient";

interface CrmClientProps {
  client: IEconomicCrmClient;
  disabled?: boolean;
  edit(arg0: IEconomicCrmClient): void;
  remove(id: number): void;
}

const CrmClientCard: React.FunctionComponent<CrmClientProps> = ({
  client,
  disabled,
  edit,
  remove
 }): JSX.Element => {
  
  const [open, setOpen] = useState(false);
  const confirmationText = "Are you sure you want to delete this client?  This process cannot be undone.";

  function onConfirmationOk() {
    setOpen(false);
		if (disabled) return;
		remove(client.economicCrmClientId);
	}

  const onEditClient = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
    edit(client);
	}

  return (
    <div>
      <Card className="card">
        <CardContent sx={{padding: "5px !important"}}>
          <Stack direction="row">
            <CardActionArea  onClick={onEditClient}>
              <Typography className="fs-12">
                {client.clientNumber} - {client.clientName}
              </Typography>
            </CardActionArea>
            {
              !disabled && 
              <IconButton onClick={() => setOpen(true)} sx={{padding:"3px !important"}}>
                <HighlightOffIcon color="error" sx={{ fontSize: "18px"}}/>
              </IconButton>
            }
          </Stack>
        </CardContent>
      </Card>
      <Confirmation open={open}
                    onClose={() => setOpen(false)}
                    onOk={onConfirmationOk}
                    title={confirmationText} />
    </div>
  )
}

export default CrmClientCard;