import { useState } from "react";
import { Typography, Card, CardHeader, CardContent, IconButton, CardActionArea } from "@mui/material";
import { IEconomicPcClient } from "../../models/IEconomicPcClient";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Confirmation from "../common/Confirmation";

interface CrmClientProps {
  client: IEconomicPcClient;
  disabled?: boolean;
  edit(arg0: IEconomicPcClient): void;
  remove(id: number): void;
}

const PcClientCard: React.FunctionComponent<CrmClientProps> = ({
  client,
  disabled,
  edit,
  remove
 }): JSX.Element => {
  
  const [open, setOpen] = useState(false);
  const confirmationText = "Are you sure you want to delete this client?  This process cannot be undone.";

  function onConfirmationOk() {
    setOpen(false);
		if (disabled) return;
		remove(client.economicPcClientId);
	}

  const onEditClient = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
    edit(client);
	}

  return (
    <div>
      <Card className="card">
        <CardHeader
          title={
            <Typography className="fs-14">
              {client.clientNumber} - {client.clientName}
            </Typography>
          }
          action={
                  !disabled
                  ? <IconButton onClick={() => setOpen(true)}>
                      <HighlightOffIcon color="error" sx={{ fontSize: "18px"}}/>
                    </IconButton>
                  : null
                }
          className="card-header"/>
        <CardActionArea onClick={onEditClient}>
          <CardContent className="card-body" >
            <Typography className="fs-14">
              Proliferation Area:<b>{" "}{client.proliferationArea}</b>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Confirmation  open={open}
                      onClose={() => setOpen(false)}
                      onOk={onConfirmationOk}
                      title={confirmationText} />
    </div>
  )
}

export default PcClientCard;