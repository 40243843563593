import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { ArrowDropDown } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { adminMenu } from "../utils/admin-menu";
import MenuLink from "./common/menu-link/menu-link";

interface AppMenuProps {
  sbuLeaderCommentsEnabled?: boolean;
  isAdmin?: boolean;
}

const pages = ["teamwork", "economic", "diversity", "goals"];
const steps = ["Teamwork", "Economic", "Diversity", "Qualitative Goals"];

const AppMenu: React.FunctionComponent<AppMenuProps> = ({
  sbuLeaderCommentsEnabled,
  isAdmin,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AppBar position="static" color="default">
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ minHeight: "40px !important" }}>
          <Button
            component={RouterLink}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              color: "#3c3c3c",
              textDecoration: "none",
              textTransform: "none",
              fontSize: 18,
            }}
          >
            Home
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => (
              <MenuLink key={page} link={`/${page}`} label={steps[index]} />
            ))}
            <MenuLink link="/yearEndReview" label="Year End Review" />
            {sbuLeaderCommentsEnabled && (
              <MenuLink link="/sbuLeader" label="SBU Leader" />
            )}
            <MenuLink link="/summary" label="Summary" />
            {isAdmin && (
              <MenuLink link="/admin/statusReport" label="Status Report" />
            )}
          </Box>
          {isAdmin && (
            <Box sx={{ flexGrow: 0 }}>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  color: "#838383",
                  textTransform: "none",
                  display: "block",
                }}
              >
                {" "}
                Admin
                <ArrowDropDown fontSize="inherit" />
              </Button>
              <Menu
                id="basic-menu"
                className="admin-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
              >
                {adminMenu.map((menu) => (
                  <MenuItem
                    key={menu.name}
                    sx={{ color: "#000", textAlign: "left" }}
                    onClick={() => setAnchorEl(null)}
                  >
                    <Button component={RouterLink} to={menu.url}>
                      {menu.name}
                    </Button>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppMenu;
