import { actionCreators as errorActionCreators } from "./Error";
import FetchQueue from "../../utils/FetchQueue";
import { ISbuLeaderComment } from "../../models/ISbuLeaderComment";
import { baseUrl } from "../../utils/authConfig";

const requestSbuLeaderCommentsType = "REQUEST_SBU_LEADER_COMMENTS";
const receiveSbuLeaderCommentsType = "RECEIVE_SBU_LEADER_COMMENTS";
const receiveSbuLeaderCommentsErrorType = "RECEIVE_SBU_LEADER_COMMENTS_ERROR";
const requestPostSbuLeaderCommentsType = "REQUEST_POST_SBU_LEADER_COMMENTS";
const receivePostSbuLeaderCommentsType = "RECEIVE_POST_SBU_LEADER_COMMENTS";
const receivePostSbuLeaderCommentsErrorType =
  "RECEIVE_POST_SBU_LEADER_COMMENTS_ERROR";

const initialState = {
  isLoaded: false,
  isLoading: false,
  isSaving: false,
  data: {},
};

const saveQueue = new FetchQueue(
  `${baseUrl}api/SbuLeaderComments/`,
  "PUT",
  null,
  function (dispatch: any, response: any) {
    dispatch({
      type: receivePostSbuLeaderCommentsErrorType,
      response: response,
    });
    dispatch(
      errorActionCreators.addError(
        "An error occurred while updating sbuLeader comments.",
        null,
        response
      )
    );
  },
  function (dispatch: any, exception: any) {
    dispatch({
      type: receivePostSbuLeaderCommentsErrorType,
      exception: exception,
    });
    dispatch(
      errorActionCreators.addError(
        "An exception occurred while updating sbuLeader comments.",
        exception,
        null
      )
    );
  }
);

export const actionCreators = {
  loadSbuLeaderComments: () => async (dispatch: any, getState: any) => {
    var state = getState();
    const { userId, currentUserId } = state.auth;

    if (
      !userId ||
      userId === currentUserId ||
      state.sbuLeaderComments.data.userId === userId ||
      state.sbuLeaderComments.isLoading
    ) {
      return;
    }

    dispatch({ type: requestSbuLeaderCommentsType });
    const url = `${baseUrl}api/SbuLeaderComments/${userId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({
          type: receiveSbuLeaderCommentsErrorType,
          response: response,
        });
        return;
      }
      const data = await response.json();
      dispatch({ type: receiveSbuLeaderCommentsType, data: data });
    } catch (exception) {
      dispatch({
        type: receiveSbuLeaderCommentsErrorType,
        exception: exception,
      });
    }
  },
  saveSbuLeaderComments:
    (data: ISbuLeaderComment) => async (dispatch: any, getState: any) => {
      if (!data.sbuLeaderCommentsId) {
        return;
      }
      dispatch({ type: requestPostSbuLeaderCommentsType, data: data });
      dispatch({ type: receiveSbuLeaderCommentsType, data: data });
      const url = `${baseUrl}api/SbuLeaderComments/${data.sbuLeaderCommentsId}`;
      saveQueue.queue(
        data.sbuLeaderCommentsId,
        data,
        getState().auth.accessToken,
        dispatch,
        url
      );
    },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  if (action.type === requestSbuLeaderCommentsType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveSbuLeaderCommentsType) {
    return {
      ...state,
      isLoading: false,
      data: action.data,
    };
  }

  if (action.type === receiveSbuLeaderCommentsErrorType) {
    return {
      ...state,
      isLoading: false,
    };
  }

  if (action.type === requestPostSbuLeaderCommentsType) {
    const newState = {
      ...state,
      isSaving: true,
      data: action.data,
    };
    return newState;
  }

  if (action.type === receivePostSbuLeaderCommentsType) {
    return {
      ...state,
      isSaving: false,
    };
  }

  if (action.type === receivePostSbuLeaderCommentsErrorType) {
    return {
      ...state,
      isSaving: false,
    };
  }

  return state;
};
