import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import "./teamWork.scss";
import { ITeam } from "../../models/ITeam";
import Format from "../../utils/Format";
import React, { useEffect } from "react";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import TextArea from "../common/textarea/TextArea";
import ClientAutoComplete from "../common/client-autocomplete/client-autocomplete";
import { IClientLookup } from "../../models/IClientLookup";
import { connect, ConnectedProps } from "react-redux";
import { baseUrl } from "../../utils/authConfig";

interface TeamEditAddProps extends PropsFromRedux {
  team?: any;
  closeTeam(): void;
  saveTeam(team: ITeam): void;
  title: string;
  accessToken: string;
  canEdit?: boolean;
}

const improvingOptions = [
  "LPM",
  "Strategic Account",
  "Client Service Team",
  "Other",
];

const TeamEditAdd: React.FunctionComponent<TeamEditAddProps> = (
  props
): JSX.Element => {
  const [localTeam, setTeam] = React.useState(props.team || ({} as ITeam));
  const [groupName, setGroupName] = React.useState<string | undefined | null>(
    null
  );
  const [groupNumber, setGroupNumber] = React.useState<
    string | undefined | null
  >(null);
  const [revenues, setRevenues] = React.useState<string[]>([]);

  useEffect(() => {
    if (props.team) {
      lookupClientInfo(props.team.clientNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lookupClientInfo = async (clientNumber: string | undefined | null) => {
    const query = clientNumber;
    if (!query) return;
    const url = `${baseUrl}api/ClientLookups/${query}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${props.auth.accessToken}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      return;
    }
    const data = await response.json();
    if (data.length === 1) {
      handleClientChange(data[0]);
    }
  };

  const handleChange = (event: any) => {
    if (!props.canEdit) return;

    const { name, value } = event.target;
    setTeam({
      ...localTeam,
      [name]: value,
    });
  };

  const handleNumberFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!props.canEdit) return;
    let value = event.target.value;
    if (!Utils.isNumeric(value)) value = value.replace(/[^\d.]/g, "");
    setTeam({
      ...localTeam,
      [event.target.name]: value,
    });
  };

  const handleImprovingStrategies = (event: any) => {
    const {
      target: { value },
    } = event;
    setTeam({
      ...localTeam,
      improvingStrategies: value.toString(),
    });
  };

  const handleClientChange = (value: IClientLookup) => {
    if (!props.canEdit) return;

    setTeam({
      ...localTeam,
      clientNumber: value.client,
      teamName: value.clientName,
    });

    setGroupName(null);
    setGroupNumber(null);
    if (value && value.relatedNum !== value.client) {
      setGroupName(value.relatedName);
      setGroupNumber(value.relatedNum);
    }

    setRevenues([]);
    const currentYear = new Date().getFullYear();
    setRevenues((revenues) => [
      ...revenues,
      currentYear.toString() +
        ": " +
        Format.formatCurrency(value.currentRevenue),
    ]);
    setRevenues((revenues) => [
      ...revenues,
      (currentYear - 1).toString() +
        ": " +
        Format.formatCurrency(value.lastYearRevenue),
    ]);
    setRevenues((revenues) => [
      ...revenues,
      (currentYear - 2).toString() +
        ": " +
        Format.formatCurrency(value.lastLastYearRevenue),
    ]);
  };

  const useGroup = () => {
    if (!props.canEdit) return;
    setTeam({
      ...localTeam,
      clientNumber: groupNumber,
      teamName: groupNumber,
    });

    lookupClientInfo(groupNumber);
  };

  const save = () => {
    if (!props.canEdit) {
      props.closeTeam();
      return;
    }
    
    if (localTeam.year === undefined) {
        localTeam.year = props.teamwork.requestedYear;
    }

    props.saveTeam(localTeam);
  };

  const getValidationState = () => {
    const validationState = {
      clientSearch: Utils.getValidationStateText(localTeam.clientNumber),
      clientNumber: Utils.getValidationStateText(localTeam.clientNumber),
      teamName: Utils.getValidationStateText(localTeam.teamName),
      yourRole: Utils.getValidationStateText(localTeam.yourRole),
      nonBillableHours: Utils.getValidationStateHours(
        localTeam.nonBillableHours
      ),
      revenueTarget: Utils.getValidationStateRevenue(localTeam.revenueTarget),
      proliferating: Utils.getValidationStateSelect(localTeam.proliferating),
      proliferatingCredit: Utils.getValidationStateRevenue(
        localTeam.proliferatingCredit
      ),
      proliferatingPlan: Utils.getValidationStateComments(
        localTeam.proliferatingPlan
      ),
      improving: Utils.getValidationStateSelect(localTeam.improving),
      improvingStrategies: Utils.getValidationStateSelect(
        localTeam.improvingStrategies
      ),
      improvingRevenue: Utils.getValidationStateComments(
        localTeam.improvingRevenue
      ),
      improvingPlan: Utils.getValidationStateComments(localTeam.improvingPlan),
    };
    return validationState;
  };

  const validationState = getValidationState();

  const showGroup = groupNumber !== undefined && groupNumber !== null;
  const showTargets = localTeam && localTeam.yourRole === "CRM";
  const isProliferating =
    localTeam &&
    (localTeam.proliferating === "true" || localTeam.proliferating === true);
  const isImproving =
    localTeam &&
    (localTeam.improving === "true" || localTeam.improving === true);

  return (
    <div className="team-work">
      <Dialog
        open={true}
        onClose={props.closeTeam}
        scroll="paper"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ textAlign: "center", pb: 0 }}
        >
          <Typography sx={{ textAlign: "center", fontSize: 22, pb: 2 }}>
            {props.title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Stack spacing={2}>
            <Card>
              <CardContent className="card-content">
                <ClientAutoComplete
                  accessToken={props.accessToken}
                  fullWidth
                  label="Client Name/Number:"
                  onChange={handleClientChange}
                  disabled={!props.canEdit}
                />
                <Input
                  placeholder="Client Number"
                  value={Format.formatString(localTeam.clientNumber)}
                  disabled
                  fullWidth
                  name="clientNumber"
                  label="Client Number:"
                />
                <Input
                  placeholder="Client Name"
                  value={Format.formatString(localTeam.teamName)}
                  disabled
                  fullWidth
                  name="teamName"
                  label="Client Name:"
                />
                {showGroup && (
                  <FormControl style={{ marginBottom: "0.5rem" }}>
                    <Typography>
                      The client is part of group {groupNumber} - {groupName}.
                      Would you like to use the group instead of the client?
                    </Typography>
                    <Button
                      variant="contained"
                      color="warning"
                      disabled={!props.canEdit}
                      onClick={useGroup}
                      sx={{ width: "120px" }}
                    >
                      Use Group
                    </Button>
                  </FormControl>
                )}
                <FormControl fullWidth sx={{ marginTop: "15px" }} size="small">
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                    variant="subtitle2"
                  >
                    Your Role:
                  </Typography>
                  <Select
                    labelId="role-select-label"
                    id="role-select"
                    value={localTeam.yourRole || ""}
                    onChange={handleChange}
                    name="yourRole"
                    disabled={!props.canEdit}
                  >
                    <MenuItem value="">{""}</MenuItem>
                    <MenuItem value="CRM">CRM</MenuItem>
                    <MenuItem value="Managing Attorney">
                      Managing Attorney
                    </MenuItem>
                    <MenuItem value="Client Team Member">
                      Client Team Member
                    </MenuItem>
                  </Select>
                </FormControl>
                {showTargets && (
                  <FormControl fullWidth sx={{ marginTop: "15px" }}>
                    <Input
                      placeholder="Revenue Target"
                      value={Format.formatCurrencyInput(
                        localTeam.revenueTarget
                      )}
                      icon="attach_money"
                      endIcon=".00"
                      fullWidth
                      type="number"
                      label="Revenue Target for Team:"
                      name="revenueTarget"
                      hasChips
                      chipValues={revenues}
                      onChange={handleNumberFieldChange}
                      valid={validationState.revenueTarget}
                      disabled={!props.canEdit}
                    />
                  </FormControl>
                )}
                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                  <Input
                    placeholder="Non-billable Hours"
                    value={Format.formatWholeNumber(localTeam.nonBillableHours)}
                    endIcon="hours"
                    fullWidth
                    type="number"
                    name="nonBillableHours"
                    onChange={handleNumberFieldChange}
                    label="Number of non-billable hours you will devote to team:"
                    valid={validationState.nonBillableHours}
                    disabled={!props.canEdit}
                  />
                </FormControl>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="card-content">
                <FormControl fullWidth size="small">
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                    variant="subtitle2"
                  >
                    Proliferating work into new practice areas for existing
                    client?
                  </Typography>
                  <Select
                    labelId="proliferating-select-label"
                    id="proliferating-select"
                    value={Format.formatString(localTeam.proliferating)}
                    name="proliferating"
                    onChange={handleChange}
                    disabled={!props.canEdit}
                  >
                    <MenuItem value="">Yes/No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                {isProliferating ? (
                  <React.Fragment>
                    <FormControl fullWidth sx={{ marginTop: "15px" }}>
                      <Input
                        placeholder="Revenue Target"
                        value={Format.formatCurrencyInput(
                          localTeam.proliferatingCredit
                        )}
                        icon="attach_money"
                        endIcon=".00"
                        fullWidth
                        type="number"
                        name="proliferatingCredit"
                        onChange={handleNumberFieldChange}
                        label="If yes, then how much proliferation credit will be generated?"
                        valid={validationState.proliferatingCredit}
                        disabled={!props.canEdit}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: "15px" }}>
                      <TextArea
                        label="Your plan for proliferating work."
                        value={Format.formatString(localTeam.proliferatingPlan)}
                        name="proliferatingPlan"
                        onChange={handleChange}
                        limit={1100}
                        disabled={!props.canEdit}
                      />
                    </FormControl>
                  </React.Fragment>
                ) : null}
              </CardContent>
            </Card>
            <Card>
              <CardContent className="card-content">
                <FormControl fullWidth size="small">
                  <Typography
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                    variant="subtitle2"
                  >
                    Improving the service level for the client through
                    implementation of LPM, CST, or other client service
                    strategy?
                  </Typography>
                  <Select
                    labelId="improving-select-label"
                    id="improving-select"
                    name="improving"
                    value={Format.formatString(localTeam.improving)}
                    onChange={handleChange}
                    disabled={!props.canEdit}
                  >
                    <MenuItem value="">Yes/No</MenuItem>
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </FormControl>
                {isImproving && (
                  <React.Fragment>
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "15px" }}
                      size="small"
                    >
                      <Typography
                        gutterBottom
                        sx={{ fontWeight: 600 }}
                        variant="subtitle2"
                      >
                        Strategies (Select All that apply)?
                      </Typography>
                      <Select
                        labelId="improving-multiple-chip-label"
                        id="improving-multiple-chip"
                        multiple
                        disabled={!props.canEdit}
                        value={Format.formatMultiselectInput(
                          localTeam.improvingStrategies
                        )}
                        name="improvingStrategies"
                        onChange={handleImprovingStrategies}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value: any) => (
                              <Chip key={value} label={value} size="small" />
                            ))}
                          </Box>
                        )}
                      >
                        {improvingOptions.map((name) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: "15px" }}>
                      <TextArea
                        label="If yes, please describe your goals, including an increase in revenue when applicable,
                                      goals should be measurable (e.g. XX number of face-to-face meetings, ECO meeting,
                                      establishing a portal, etc.)"
                        value={Format.formatString(localTeam.improvingRevenue)}
                        name="improvingRevenue"
                        onChange={handleChange}
                        limit={550}
                        disabled={!props.canEdit}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: "15px" }}>
                      <TextArea
                        label="Your plan for improving the service level for client:"
                        value={Format.formatString(localTeam.improvingPlan)}
                        name="improvingPlan"
                        onChange={handleChange}
                        limit={1100}
                        disabled={!props.canEdit}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
              </CardContent>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className="theme-button"
            onClick={props.closeTeam}
          >
            Cancel
          </Button>
          {props.canEdit && (
            <Button
              variant="contained"
              size="medium"
              className="theme-button"
              onClick={save}
            >
              Save and Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    teamwork: state.teamwork,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TeamEditAdd);
