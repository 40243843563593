import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import Format from "../../../utils/Format";
import React from "react";
import Input from "../../common/input/Input";
import TextArea from "../../common/textarea/TextArea";
import { IClientProject } from "../../../models/IClientProject";
import utils from "../../../utils/Utils";
import Search from "./Search/Search";
import { actionCreators as clientProjectsActionCreators } from "../../../store/reducers/ClientProjects";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import status from "../../../utils/Status";
import { reviewYearSelector } from "../../../store/reducers/Settings";

const AddNewProject: React.FunctionComponent<any> = (
  props: any
): JSX.Element => {
  const [data, setData] = React.useState(
    props.clientProject || ({} as IClientProject)
  );
  const [_defaultValidationState, setValidationState] = React.useState<
    boolean | null
  >(null);

  const handleSave = () => {
    if (!props.canEdit) {
      props.close();
      return;
    }
    if (!status.isClientProjectComplete(data)) {
      setValidationState(false);
      return;
    }
    data.year = props.reviewYear;
    props.save(data);
  };

  const handleChange = (event: any) => {
    if (!props.canEdit) return;
    const { name, value } = event.target;
    setData({
        ...data,
        [name]: value,
    });
  };

    const onClose = () => {
       props.clientSearch.options = [];
       props.matterSearch.options = [];
    }

  const handleClientSearch = (query: string) => {
    if (!props.canEdit) return;
    const { matterNumber } = data;
    var search = query.trim();
    props.clientProjectsActions.searchClient(search, matterNumber);
  };

  const handleClientChange = (_event: any, value: any) => {
    if (!props.canEdit) return;
    if (!value) return;
    const clientMatter = value;
    var selectedClientProject = {
      ...data,
      clientNumber: clientMatter.client,
      clientName: clientMatter.clientName,
    };
    setData(selectedClientProject);
  };

  const handleMatterSearch = (query: string) => {
    if (!props.canEdit) return;
    const { clientNumber } = data;
    var search = query.trim();
    props.clientProjectsActions.searchMatter(search, clientNumber);
  };

  const handleMatterChange = (_event: any, value: any) => {
    if (!props.canEdit) return;
    if (!value) return;
    const clientMatter = value;
    var selectedClientProject = {
      ...data,
      matterNumber: clientMatter.matter,
      matterName: clientMatter.matterName,
    };
    setData(selectedClientProject);
  };

  const validationState = {
    isClientSearchValid: utils.getValidationStateText(
      data.clientNumber,
      _defaultValidationState
    ),
    isClientNumberValid: utils.getValidationStateText(
      data.clientNumber,
      _defaultValidationState
    ),
    isClientNameValid: utils.getValidationStateText(
      data.clientName,
      _defaultValidationState
    ),
    isMatterSearchValid: utils.getValidationStateText(
      data.matterNumber,
      _defaultValidationState
    ),
    isMatterNumberValid: utils.getValidationStateText(
      data.matterNumber,
      _defaultValidationState
    ),
    isMatterNameValid: utils.getValidationStateText(
      data.matterName,
      _defaultValidationState
    ),
    isDescriptionValid: utils.getValidationStateText(
      data.description,
      _defaultValidationState
    ),
  };

  const disabled = !props.canEdit;

  const isRequiredValid = () => {
    var isRequiredResult = true;
    if (!utils.hasText(data.description) && _defaultValidationState !== null)
      return _defaultValidationState;

    if (data.description === null || data.description === undefined)
      isRequiredResult = true;
    else if (data.description === "") isRequiredResult = false;
    else isRequiredResult = true;

    return isRequiredResult;
  };

  return (
    <div className="project">
      <Dialog
        open={true}
        onClose={props.close}
        scroll="paper"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" sx={{ pb: 0 }}>
          <Typography
            sx={{ textAlign: "left", fontSize: 22, pb: 2, color: "#0033a0" }}
          >
            {props.title}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Stack spacing={2}>
            <Card>
              <CardContent className="card-content">
                {props.isNew && (
                  <Grid item sx={{ my: 1.5 }}>
                    <InputLabel
                      className="label"
                      sx={{ fontWeight: 700, color: "#000" }}
                    >
                      Client Search:
                    </InputLabel>
                    <Search
                                          id="client-auto-complete"
                                          disabled={disabled}
                                          searchData={props.clientSearch}
                                          handleChange={handleClientChange}
                                          handleSearch={handleClientSearch}
                                          noOptionsText="No clients found."
                                          onClose={onClose}
                    />
                  </Grid>
                )}
                <Grid item sx={{ my: 1.5 }}>
                  <Input
                    placeholder="Client Number"
                    value={Format.formatString(data.clientNumber)}
                    disabled
                    fullWidth
                    name="clientNumber"
                    label="Client Number:"
                    onChange={handleChange}
                    valid={
                      validationState.isClientNumberValid === null
                        ? null
                        : validationState.isClientNumberValid
                    }
                  />
                </Grid>
                <Grid item sx={{ my: 1.5 }}>
                  <Input
                    placeholder="Client Name"
                    value={Format.formatString(data.clientName)}
                    disabled
                    fullWidth
                    name="clientName"
                    label="Client Name:"
                    onChange={handleChange}
                    valid={
                      validationState.isClientNameValid === null
                        ? null
                        : validationState.isClientNameValid
                    }
                  />
                </Grid>

                {props.isNew && (
                  <Grid item sx={{ my: 1.5 }}>
                    <InputLabel
                      className="label"
                      sx={{ fontWeight: 700, color: "#000" }}
                    >
                      Matter Search:
                    </InputLabel>
                    <Search
                      id="matter-auto-complete"
                      disabled={disabled}
                      searchData={props.matterSearch}
                      handleChange={handleMatterChange}
                      handleSearch={handleMatterSearch}
                      noOptionsText="No matters found."
                      onClose={onClose}
                    />
                  </Grid>
                )}
                <Grid item sx={{ my: 1.5 }}>
                  <Input
                    placeholder="Matter Number"
                    value={Format.formatString(data.matterNumber)}
                    disabled
                    fullWidth
                    name="matterNumber"
                    label="Matter Number:"
                    onChange={handleChange}
                    valid={
                      validationState.isMatterNumberValid === null
                        ? null
                        : validationState.isMatterNumberValid
                    }
                  />
                </Grid>
                <Grid item sx={{ my: 1.5 }}>
                  <Input
                    placeholder="Matter Name"
                    value={Format.formatString(data.matterName)}
                    disabled
                    fullWidth
                    name="matterName"
                    label="Matter Name:"
                    onChange={handleChange}
                    valid={
                      validationState.isMatterNameValid === null
                        ? null
                        : validationState.isMatterNameValid
                    }
                  />
                </Grid>
                <FormControl fullWidth>
                  <TextArea
                    label="Project Description:"
                    value={Format.formatString(data.description)}
                    placeholder="Description"
                    name="description"
                    onChange={handleChange}
                    limit={1100}
                    disabled={disabled}
                    isForceInValid={!isRequiredValid()}
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            className="theme-button"
            onClick={props.close}
          >
            Close
          </Button>
          {props.canEdit && (
            <Button
              variant="contained"
              size="medium"
              className="theme-button"
              onClick={handleSave}
            >
              Save and Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    matterSearch: state.clientProjects.matterSearch,
    clientSearch: state.clientProjects.clientSearch,
    reviewYear: reviewYearSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    clientProjectsActions: bindActionCreators(
      clientProjectsActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProject);
