import Utils from "../utils/Utils";

function hasValue(value) {
  return value !== undefined && value !== null && value !== "" ? 1 : 0;
}

function stringHasValue(value) {
  return value !== undefined && value !== null && value.trim().length > 0
    ? 1
    : 0;
}

function isNumeric(value) {
  return !isNaN(value - parseFloat(value));
}

function isTextComplete(value, minLength = 1) {
  return (
    value !== undefined &&
    value !== null &&
    value.toString().length >= minLength
  );
}

function areTargetsComplete(team) {
  return (team.yourRole !== "CRM" || isNumeric(team.revenueTarget));
}

function isTeamComplete(team) {
  if (team === undefined || team === null) return false;
  const result =
    isTextComplete(team.teamName) &&
    isTextComplete(team.yourRole) &&
    areTargetsComplete(team);
  return result;
}

function isClientProjectComplete(clientProject) {
  if (clientProject === undefined || clientProject === null) return false;
  const result =
    Utils.isTextComplete(clientProject.clientNumber) &&
    Utils.isTextComplete(clientProject.clientName) &&
    Utils.isTextComplete(clientProject.matterNumber) &&
    Utils.isTextComplete(clientProject.matterName) &&
    Utils.isTextComplete(clientProject.description);
  return result;
}

const status = {
  isTeamComplete: isTeamComplete,
  getEconomicStatus: function (economic, crm, pc, sc) {
    if (economic === undefined || economic === null) return "Not Started";
    let i = 0;
    let complete = 3;
    i += hasValue(economic.waBillableHours);
    i += hasValue(economic.waInvestmentHours);
    i += hasValue(economic.waCollections);

    if (i === 0) return "Not Started";
    if (i < complete) return "In Progress";
    return "Completed";
  },

  getTeamworkStatus: function (teams) {
    const length = teams.length;
    if (length === 0) return "Not Started";
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      if (!isTeamComplete(team)) return "In Progress";
    }
    return "Completed";
  },

  getGoalsStatus: function (goals) {
    const length = goals.length;
    if (length === 0) return "Not Started";
    if (length < 3) return "In Progress";
    return "Completed";
  },

  getDiversityStatus: function (diversity) {
    const diversityHasValue =
      diversity && stringHasValue(diversity.diversitySummaryComments);
    if (!diversityHasValue) return "Not Started";
    return "Completed";
  },

  getExpectationsStatus: function (expectations) {
    if (hasValue(expectations.signature)) return "Completed";
    return "Not Started";
  },

  getPartnerCommentsTeamworkStatus: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.teamworkComments)) count++;
    }

    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getPartnerCommentsEconomicStatus: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.economicComments)) count++;
    }
    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getPartnerCommentsDiversityStatus: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.diversityComments)) count++;
    }
    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getPartnerYearEndStatus: function (summaryComments) {
    let count = 0;
    let completed = 1;
    if (summaryComments) {
      if (stringHasValue(summaryComments.yearEndSummaryComments)) count++;
    }
    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getPartnerCommentsStatus: function (partnerComments) {
    let count = 0;
    let completed = 4;
    if (partnerComments) {
      if (stringHasValue(partnerComments.teamworkComments)) count++;
      if (stringHasValue(partnerComments.economicComments)) count++;
      if (stringHasValue(partnerComments.diversityComments)) count++;
      if (stringHasValue(partnerComments.goalComments)) count++;
    }

    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getSbuLeaderCommentsStatus: function (sbuLeaderComments, goals) {
    if (!sbuLeaderComments) return "Not Started";
    let count = 0;
    let required = 1;
    if (stringHasValue(sbuLeaderComments.baseCompRecommendations)) count++;
    if (stringHasValue(sbuLeaderComments.bonusRecommendations)) count++;
    if (stringHasValue(sbuLeaderComments.partnerBaseComments)) count++;
    if (stringHasValue(sbuLeaderComments.partnerBonusComments)) count++;
    if (stringHasValue(sbuLeaderComments.equityStatusComments)) count++;

    if (count === 0) return "Not Started";
    if (count < required) return "In Progress";
    return "Completed";
  },

  getPartnerCommentsGoalsStatus: function (
    partnerCommentsGoals,
    partnerComments,
    clientProjects
  ) {
    const minClientProjects = 1;
    let count = 0;
    let completed = 2;
    if (clientProjects)
      count += Math.min(minClientProjects, clientProjects.length);
    if (partnerComments) {
      completed += 1;
      if (stringHasValue(partnerComments.goalComments)) count++;
    }

    if (count === 0) return "Not Started";
    if (count < completed) return "In Progress";
    return "Completed";
  },

  getOverallStatus: function (
    teamworkStatus,
    economicStatus,
    goalsStatus,
    diversityStatus
  ) {
    if (
      teamworkStatus === "Not Started" &&
      economicStatus === "Not Started" &&
      goalsStatus === "Not Started" &&
      diversityStatus === "Not Started"
    )
      return "Not Started";
    if (
      teamworkStatus === "Completed" &&
      economicStatus === "Completed" &&
      goalsStatus === "Completed" &&
      diversityStatus === "Completed"
    )
      return "Completed";
    return "In Progress";
  },

  isClientProjectComplete: isClientProjectComplete,
};

export const todos = {
  getEconomicTodos: function (economic, crm, pc, sc) {
    if (economic === undefined || economic === null) return ["Not Started"];
    let todos = [];
    let i = 0;

    i += hasValue(economic.waBillableHours);
    if (!hasValue(economic.waBillableHours))
      todos.push("WA Billable Hours is required.");
    i += hasValue(economic.waInvestmentHours);
    if (!hasValue(economic.waInvestmentHours))
      todos.push("WA Investment Hours is required.");
    i += hasValue(economic.waCollections);
    if (!hasValue(economic.waCollections))
      todos.push("WA Collections is required.");

    if (i === 0) return ["Not Started"];
    return todos;
  },
  getTeamworkTodos: function (teams) {
    const length = teams.length;
    if (length === 0) return ["Not Started"];
    let todos = [];
    for (let i = 0; i < teams.length; i++) {
      const team = teams[i];
      if (!isTeamComplete(team)) todos.push(`${team.teamName} is not complete`);
    }
    return todos;
  },
  getGoalsTodos: function (goals, diversity) {
    const length = goals.length;
    if (length === 0) return ["Not Started"];
    if (length < 3) {
      let todos = [];
      if (length < 3) todos.push("You must add at least 2-3 goals.");
      return todos;
    }
    return [];
  },
  getDiversityTodos: function (summaryComments) {
    let count = 0;
    let completed = 1;
    let todos = [];
    if (summaryComments) {
      if (!stringHasValue(summaryComments.diversityComments)) count++;
      else todos.push("Diversity Comments are required.");
    }
    if (count < completed) return todos;
    return [];
  },
  getPartnerCommentsTeamworkTodos: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.teamworkComments)) count++;
    }
    if (count < completed) return ["Teamwork comments are required."];
    return [];
  },
  getPartnerCommentsEconomicTodos: function (partnerComments, economic) {
    let count = 0;
    let completed = 1;
    let todos = [];
    if (partnerComments) {
      if (stringHasValue(partnerComments.economicComments)) count++;
      else todos.push("Economic Comments are required.");
    }
    if (count < completed) return todos;
    return [];
  },
  getPartnerCommentsGoalsStatus: function (
    partnerCommentsGoals,
    partnerComments,
    clientProjects
  ) {
    const minClientProjects = 1;
    let count = 0;
    let completed = 2;
    let todos = [];
    if (clientProjects) {
      count += Math.min(minClientProjects, clientProjects.length);
      if (clientProjects.length < minClientProjects)
        todos.push("Client projects are required.");
    }
    if (partnerComments) {
      completed += 1;
      if (stringHasValue(partnerComments.goalComments)) count++;
      else todos.push("Goal Comments are required.");
    }

    if (count === 0) return ["Not Started"];
    if (count < completed) return todos;
    return [];
  },
  getPartnerCommentsDiversityTodos: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.diversityComments)) count++;
    }

    if (count < completed) return ["Diversity comments are required."];
    return [];
  },
  getPartnerCommentsYearEndTodos: function (partnerComments) {
    let count = 0;
    let completed = 1;
    if (partnerComments) {
      if (stringHasValue(partnerComments.diversityComments)) count++;
    }

    if (count < completed)
      return ["Year End Contribution Highlights comments are required."];
    return [];
  },
};

export default status;
