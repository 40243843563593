import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import SendIcon from "@mui/icons-material/Send";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { actionCreators as adminEmailActionCreators } from "../../../store/reducers/AdminEmail";
import Format from "../../../utils/Format";
import TextArea from "../../common/textarea/TextArea";
import { ColumnDef } from "@tanstack/react-table";
import AdminTable from "../AdminTable/AdminTable";
import { bindActionCreators } from "redux";
import { currentPartnerOptionSelector } from "../../../store/reducers/AdminEmail";

type AdminEmailModel = {
  lastNameFirst: string;
  email: string;
  success: boolean;
  message: string;
};

const AdminEmail: React.FunctionComponent = (props: any): JSX.Element => {
  const columns = useMemo<ColumnDef<AdminEmailModel>[]>(
    () => [
      {
        accessorKey: "lastNameFirst",
        header: () => "Name",
        footer: (props: any) => props.column.id,
      },
      {
        accessorKey: "email",
        header: () => "Email",
        footer: (props: any) => props.column.id,
      },
      {
        accessorKey: "success",
        header: "Success",
        footer: (props: any) => props.column.id,
        type: "list",
        cell: (data: any) => (
          <span>{data.getValue() ? "Succeeded" : "Failed"}</span>
        ),
        options: [
          { label: "Failed", value: false },
          { label: "Succeeded", value: true },
        ],
      },
      {
        accessorKey: "message",
        header: "Message",
        footer: (props: any) => props.column.id,
      },
    ],
    []
  );

  const defaultSorted = [
    { id: "success", desc: false },
    { id: "lastNameFirst", desc: false },
  ];

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [partnerValues, setPartnerValues] = useState([]);
  const [testMyEmail, setTestMyEmail] = useState(true);
  const [results, setResults] = useState([]);
  const [subject, setSubject] = useState("2020 Quarter Review");

  useEffect(() => {
    if (props.auth.accessToken) {
      loadPartners();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  useEffect(() => {
    setPartnerOptions(props.currentPartnerOptions);
    setPartnerValues(props.currentPartnerOptions);
  }, [props.currentPartnerOptions]);

  useEffect(() => {
    setResults(props.resultData);
  }, [props.resultData]);

  const fullHeightStyle = {
    height: "100%",
    background: "#ccc",
    color: "black",
    border: "#ccc",
  };
  const fullWidthStyle = {
    width: "100%",
  };
  const borderStyle = {
    border: "solid 1px #ccc",
    borderRadius: "4px",
    width: "fit-content",
  };
  const emailStyle = {
    backgroundColor: "#eee",
  };
  const paddingStyle = {
    padding: 4,
  };
  const fullWidthPaddingStyle = { ...fullWidthStyle, ...paddingStyle };

  const _html =
    "<!DOCTYPE html>\r\n<html lang='en'>\r\n<head>\r\n<title>Q2 PIA</title>\r\n</head>\r\n<body>\r\n<div style='text-align:center'>\r\n<a href='http://www.huschblackwell.com'>\r\n<img src='http://start.huschblackwell.com/styles/images/logo.gif' style='border:0' alt=\"Husch Blackwell\" />\r\n</a>\r\n</div>\r\n<hr />\r\n<p>At the beginning of the year, all Partners completed a Partner Impact Assessment (PIA) to establish goals/targets for 2020.\r\nIn an effort to assist you in monitoring your goals/targets, we are providing a Q2 update in the attached PDF of your PIA.\r\nYou will find Actual numbers included that reflect your personal financial data.\r\nReviewing your goals - and the progress you are making to reach them - is an opportunity to adjust or refocus your efforts, to course-correct before the end of the year.</p>\r\n<p>Please note, the Attorney Dashboard tracks your financial metrics and progress towards your financial goals/targets.\r\nWe encourage you to use the dashboard to obtain up-to-date information.</p>\r\n<p>If you have any questions about your goals listed in the attached report, please contact your Managing Director.</p>\r\n<p>Thank you!</p>\r\n</body>\r\n</html>";

  const loadPartners = async () => {
    props.adminEmailActions.loadPartners();
  };

  const handleCheckbox = (event: any) => {
    setTestMyEmail(event.target.checked);
  };

  const handlePartners = (selectedOptions: any) => {
    setPartnerValues(selectedOptions);
  };

  const handleInput = (event: any) => {
    setSubject(event.target.value);
  };

  const send = async () => {
    const userIds = partnerValues.map((u: any) => u.userId);

    const data = {
      userIds: userIds,
      From: "Karen.Huck@huschblackwell.com",
      to: "Priyanka.Sharma@huschblackwell.com",
      cc: "Priyanka.Sharma@huschblackwell.com",
      subject: subject,
      body: _html,
      test: testMyEmail,
    };

    props.adminEmailActions.sendEmail(data);
  };

  const to = testMyEmail ? props.auth.user.name + ";" :  "Selected Partners;";

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5" sx={{ mb: 2 }}>
          Admin Email
        </Typography>
        <Typography variant="h5">Bulk email reports to partners</Typography>
      </Grid>
      <Grid item sx={{ pl: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                sx={{ py: 0.5 }}
                id="testMyEmail"
                name="testMyEmail"
                checked={Format.formatCheckboxInput(testMyEmail)}
                onChange={handleCheckbox}
              />
            }
            label="Send test emails to yourself"
          />
        </FormGroup>
      </Grid>
      <Grid item sx={{ p: 1 }} xs={12}>
        <div style={{ width: "100%", display: "flex" }}>
          <InputLabel className="label">Partners:</InputLabel>
        </div>
        <Select
          options={partnerOptions}
          onChange={handlePartners}
          isClearable
          isMulti
          value={partnerValues}
          closeMenuOnSelect={false}
          className="user-react-select"
        />
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Card>
          <CardHeader
            title={
              <Typography
                className="title"
                sx={{ fontSize: 16, fontWeight: 700 }}
              >
                {subject} - Message (HTML)
              </Typography>
            }
            className="card-header"
          />
          <CardContent className="card-content" style={emailStyle}>
            <FormGroup>
              <table>
                <tbody>
                  <tr>
                    <td style={paddingStyle} rowSpan={3}>
                      <button style={fullHeightStyle} onClick={send}>
                        <br />
                        <SendIcon />
                        <Typography>Send</Typography>
                      </button>
                    </td>
                    <td style={paddingStyle}>
                      <Button
                        style={{
                          background: "#ccc",
                          color: "black",
                          border: "#ccc",
                        }}
                      >
                        To...
                      </Button>
                    </td>
                    <td style={fullWidthPaddingStyle}>
                      <input
                        readOnly
                        style={{ width: "98%", padding: "8px" }}
                        value={to}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={paddingStyle}>
                      <Button
                        style={{
                          background: "#ccc",
                          color: "black",
                          border: "#ccc",
                        }}
                      >
                        Cc...
                      </Button>
                    </td>
                    <td style={fullWidthPaddingStyle}>
                      <input
                        readOnly
                        style={{ width: "98%", padding: "8px" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={paddingStyle}>
                      <div>Subject</div>
                    </td>
                    <td style={fullWidthPaddingStyle}>
                      <input
                        id="subject"
                        value={subject}
                        style={{ width: "98%", padding: "8px" }}
                        onChange={handleInput}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={paddingStyle}></td>
                    <td style={paddingStyle}>
                      <div>Attached</div>
                    </td>
                    <td style={paddingStyle}>
                      <div style={borderStyle}>
                        <FilePresentIcon />
                        <Typography sx={{ float: "right" }}>
                          2020 PIA - Name.pdf
                        </Typography>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={paddingStyle} colSpan={3}>
                      <TextArea
                        disabled
                        rows={12}
                        value={_html}
                        name="message"
                        limit={10000}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </FormGroup>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        {props.isLoading && (
          <div>
            <h4>Loading...</h4>
            <p>
              This may take several minutes to complete. You can remain on this
              page to see the results, or continue elsewhere.
            </p>
          </div>
        )}
        {results && results.length > 0 && (
          <div>
            <h4>Results:</h4>
            <AdminTable
              {...{
                data: results,
                columns,
                showFilter: true,
                showPagination: true,
                defaultSorted,
                rowClickable: true,
              }}
            />
          </div>
        )}
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    currentPartnerOptions: currentPartnerOptionSelector(state),
    resultData: state.adminEmail.resultData,
    isLoading: state.adminEmail.isLoading,    
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    adminEmailActions: bindActionCreators(
      adminEmailActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmail);
