import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { IClientLookup } from "../../models/IClientLookup";
import { IEconomicCrmClient } from "../../models/IEconomicCrmClient";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import ClientAutoComplete from "../common/client-autocomplete/client-autocomplete";
import Input from "../common/input/Input";

interface EconomicCrmClientAddEditProps {
  accessToken: string;
  isNew?: boolean;
  title: string;
  year?: number;
  reviewYear:number;
  canEdit: boolean;  
  client?: IEconomicCrmClient;
  close(): void;
  save(arg0: IEconomicCrmClient, isReviewYear: boolean): void;
  isReviewYear: boolean;
}

const EconomicCrmClientAddEdit: React.FunctionComponent<EconomicCrmClientAddEditProps> = ({
  accessToken,  
  isNew,
  title,
  year,
  canEdit,  
  reviewYear,
  client,
  save,
  close,
  isReviewYear
}): JSX.Element => {

  const [tempCrmClient, setCrmClient] = useState(client || {year: year} as IEconomicCrmClient);
  const[defaultValidationState, setDefaultValdationState] = useState<boolean|null|undefined>(null);

  const disabled = client && client.year === reviewYear; 
  
  const isClientComplete = (client: IEconomicCrmClient) => {		
		if (client === undefined || client === null) return false;
		const result = Utils.isTextComplete(client.clientNumber) && Utils.isTextComplete(client.clientName)
		return result;
	}

  const onSave = () => {    
		if (!canEdit) {
			close();
			return;
		}    
		const isCrmClientComplete = isClientComplete(tempCrmClient);
		if (!isCrmClientComplete) {
			setDefaultValdationState(false);
			return;
		}
		save(tempCrmClient, isReviewYear);		
  }

  const handleClientChange = (value : IClientLookup) => {
		if (!canEdit) return;		

    setCrmClient({
      ...tempCrmClient,
      clientNumber: value.client,
      clientName:value.clientName
    });		
	}

  return (
    <div>
      <Dialog open={true}                   
              scroll="paper"
              fullWidth={true}
              maxWidth="sm">
        <DialogTitle id="scroll-dialog-title" sx={{ textAlign: "center", pb: 0 }}>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Typography sx={{ textAlign: "left", fontSize: 22, pb: 2 }}>
                {title}
              </Typography>
            </div>
            <div>
              <IconButton onClick={close} sx={{ textAlign: "right", fontSize: 22 }}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {
            isNew &&
            <ClientAutoComplete accessToken={accessToken} 
                                fullWidth 
                                label="Client Name/Number:" 
                                onChange={handleClientChange} 
                                valid={Utils.getValidationStateText(tempCrmClient.clientNumber, defaultValidationState)}/>
          }
         <div style={{marginTop: '15px'}}>
            <Input  placeholder="Client Number"
                    value={Format.formatString(tempCrmClient.clientNumber)}
                    disabled
                    fullWidth
                    name="clientNumber"
                    label="Client Number:"
                    valid={Utils.getValidationStateText(tempCrmClient.clientNumber, defaultValidationState)} />
          </div>
          <div style={{marginTop: '15px'}}>
            <Input  placeholder="Client Name"
                    value={Format.formatString(tempCrmClient.clientName)}
                    disabled
                    fullWidth
                    name="clientName"
                    label="Client Name:"
                    valid={Utils.getValidationStateText(tempCrmClient.clientNumber, defaultValidationState)} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="medium" className="theme-button" onClick={close}>
            Cancel
          </Button>
          {
            canEdit && !disabled &&
            <Button variant="contained" size="medium" className="theme-button" onClick={onSave}>
              Save and Close
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EconomicCrmClientAddEdit;