export const goalAreas = [
  {
    name: "Industry Focus",
    longName: "Industry First Focus",
    description:
      "This section is intended to capture " +
      "your plans and goals to advance the Firm's Industry First Focus, that aligns " +
      "with the Firm's strategic plan.",
    categories: [
      {
        name: "Industry First / SBU Support",
        textLabel:
          "Your goal for ensuring the proper expertise is deployed on client projects",
        descriptionLabel:
          "How will you achieve your goal related to ensuring the proper expertise is deployed on clients projects",
      },
      {
        name: "Industry Expertise",
        textLabel: "How will you demonstrate industry expertise",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to demonstrate industry expertise (note: this is " +
          "specific to industry and NOT practice area; opportunities to explain practice area goals are included in the " +
          "Ownership & Commitment behavior.)",
      },
    ],
  },
  {
    name: "Ownership",
    longName: "Ownership & Commitment",
    description:
      "This section is intended to capture your plans and goals related " +
      "to Firm leadership, Firm citizenship and personal growth",
    categories: [
      {
        name: "Leadership",
        textLabel:
          "What Firm leadership positions will you hold (note: SBU leadership is covered in behavior II. above)",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to assume the leadership positions",
      },
      {
        name: "Citizenship",
        textLabel:
          "Identify contributions you will make to Firm initiatives, committees, people development, and diversity",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to make the citizenship contributions",
      },
      {
        name: "Personal Growth",
        textLabel: "Your goal or goals related to growing your legal expertise",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to meet your personal growth goal or goals",
      },
    ],
  },
  {
    name: "Innovation",
    longName: "Innovation",
    description:
      "This section is intended to capture your goals and plans related to innovation - either related " +
      "to development of innovative products and services and/or marketing and sales innovative products and " +
      "services.",
    categories: [
      {
        name: "Development & Marketing / Sales",
        textLabel:
          "Your goal or goals related to developing an innovative product or service",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to meet your product / service development goal or goals",
      },
      {
        name: "Marketing / Sales",
        textLabel:
          "Your goal or goals related to marketing and/or selling an innovative product or service",
        descriptionLabel:
          "Describe the specific actions you will take to enable you to meet your product / service marketing and/or selling goals",
      },
    ],
  },
];
