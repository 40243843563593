import { useEffect, useRef, HTMLProps } from "react";

export default function CheckboxSelection({
  indeterminate,
  className = "",
  ...props
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !props.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <>
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...props}
      />
      {props.label && <label> {props.label}</label>}
    </>
  );
}
