import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";
import "./navigation-btn.scss";

interface NavigationBtnProps {
  prevLink: string;
  nextLink: string;
  prevBtnTxt?: string;
  nextBtnTxt?: string;
}

const NavigationBtn: React.FunctionComponent<NavigationBtnProps> = ({
  prevLink,
  nextLink,
  prevBtnTxt,
  nextBtnTxt,
}): React.ReactElement => {
  return (
    <ButtonGroup
      variant="contained"
      size="medium"
      className="btnGroup">
      <Button component={RouterLink} to={`${prevLink}`}>
        {prevBtnTxt}
      </Button>
      <Button component={RouterLink} to={`${nextLink}`}>
        {nextBtnTxt}
      </Button>
    </ButtonGroup>
  );
};

export default NavigationBtn;
