import * as React from "react";
import { Typography } from "@mui/material";

interface UserInfoProps {
  data: string;
}

const UserInfo: React.FunctionComponent<UserInfoProps> = ({
  data,
}): React.ReactElement => {
  return (
    <Typography
      variant="caption"
      sx={{
        background: "grey",
        color: "white",
        padding: "4px",
        fontWeight: 600,
        margin: "2px",
      }}
    >
      {data}
    </Typography>
  );
};

export default UserInfo;
