import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "PcrDevClientIdtoUpdate",
    authority: "PcrDevAuthoritytoUpdate",
    redirectUri: "http://localhost:3002",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {	
    loggerOptions: {	
      loggerCallback: (level: any, message: any, containsPii: any) => {	
        if (containsPii) {		
          return;		
        }		
        switch (level) {		
          case LogLevel.Error:		
            console.error(message);		
            return;		
          case LogLevel.Info:		
            console.info(message);		
            return;		
          case LogLevel.Verbose:		
            console.debug(message);		
            return;		
          case LogLevel.Warning:		
            console.warn(message);		
            return;		
        }	
      }	
    }	
  }
};

export const apiConfig = {
  resourceUri: "https://localhost:7238/api/Graph",
  resourceScopes: ["PcrDevScopestoUpdate"]
}

export const loginRequest = {
  scopes: [...apiConfig.resourceScopes]
}

export const tokenRequest = {
  scopes: [...apiConfig.resourceScopes]
}

export const silentRequest = {
  scopes: ["openid", "profile",...apiConfig.resourceScopes]
}

export const baseUrl = "https://localhost:7238/"