import { Avatar, Card
  , CardContent
  , CardHeader
  , IconButton
  , Stack
  , Typography
  , CircularProgress} from "@mui/material";
import { actionCreators } from "../../store/reducers/Reports";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const PreviousPCR: React.FunctionComponent = (props: any): JSX.Element => {

  const printCommentsReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(true, "PcrCommentsReport");
  };

  const printPlanningReport = async (e: any) => {
    e.preventDefault();
    props.actionCreators.requestPartnerPcrReport(true, "PcrPlanningReport");
  };
 
  return (
    <Card>
      <CardHeader
        title={
          <Typography className="title">
            Previous Partner Contribution Reports
          </Typography>
        }
        className="card-header" />
      <CardContent className="card-content">
        <Stack spacing={1} direction="row" sx={{ my: 1 }}>
          <IconButton onClick={printPlanningReport}>
            <Avatar alt="2023 PCR"
                    src="/assets/img/2023pcr.png"
                    sx={{ width: 110, height: 110 }}
                    variant="square"/>
          </IconButton>
          <IconButton onClick={printCommentsReport}>
            <Avatar alt="2022 PCR"
                    src="/assets/img/2022pcr.png"
                    sx={{ width: 110, height: 110 }}
                    variant="square" />
          </IconButton>
        {props.isLoadingPrevious ? (
        <IconButton>
            {" "}
            <CircularProgress color="success" />{" "}
        </IconButton>
        ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    report: state.report,
    isLoadingPrevious: state.report.isLoadingPrevious,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    actionCreators: bindActionCreators(actionCreators, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousPCR);