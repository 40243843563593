import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import utils from "../../../../utils/Utils";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";

interface SearchProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  id: string;
  searchData: any;
  handleChange: any;
  handleSearch: any;
  disabled: boolean;
  noOptionsText: string;
  onClose: any;
}

const Search: React.FunctionComponent<SearchProps> = ({
  id,
  searchData,
  handleChange,
  handleSearch,
  disabled,
  noOptionsText,
  onClose
}): React.ReactElement => {
  return (
    <Autocomplete
      id={id}
      options={searchData.options}
      disabled={disabled}
      fullWidth
      filterOptions={(x) => x}
      autoHighlight
      noOptionsText={noOptionsText}
      size="small"
      loading={searchData.isLoading}
      onClose={onClose}
      getOptionLabel={(option: any) => option.label}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option.label}
        </Box>
      )}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          onChange={(ev) => {
            if (utils.hasText(ev.target.value)) {
              handleSearch(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchData.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default Search;
