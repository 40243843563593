import { Column, Table as ReactTable } from "@tanstack/react-table";
import utils from "../../../../utils/Utils";

export default function Filter({
  column,
  table,
}: {
  column: Column<any, any>;
  table: ReactTable<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  function ConvertFilterValueToColumnType(filterValue: any) {
    if (!utils.hasText(filterValue)) return filterValue;

    const type = typeof firstValue;
    switch (type) {
      case "number":
        return Number(filterValue);
      case "boolean":
        return JSON.parse(filterValue);
      default:
        return filterValue;
    }
  }

  const { type, options } = column.columnDef as any;

  const columnFilterValue = column.getFilterValue();

  if (type === "list") {
    return (
      <select
        onChange={(event) => {
          const selectedValue = ConvertFilterValueToColumnType(
            event.target.value
          );
          column.setFilterValue(selectedValue);
        }}
        style={{ width: "95%" }}
        value={(columnFilterValue ?? "") as string}
      >
        <option value="">Show All</option>
        {options.map((option: any, i: number) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        className="border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        className="border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      className="border shadow rounded"
    ></input>
  );
}
