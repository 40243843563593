import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "./diversity.scss";
import DiversityDashboard from "./DiversityDashboard";
import TextArea from "../common/textarea/TextArea";
import { actionCreators as summaryCommentsActionCreators } from "../../store/reducers/SummaryComments";
import { actionCreators as partnerCommentsActionCreators } from "../../store/reducers/PartnerComments";
import Format from "../../utils/Format";
import NavigationBtn from "../common/navigation-btn/navigation-btn";
import {
  canEditSelector,
  partnerCommentsEnabledSelector,
  planYearSelector,
  reviewYearSelector,
  planYearTextSelector,
  reviewYearTextSelector,
  canEditProxyUserSelector,
  isExceptionUserSelector,
} from "../../store/reducers/Settings";
import {actionCreators as diversityActionCreators } from "../../store/reducers/Diversity";

// eslint-disable-next-line no-empty-pattern
const TabDiversity: React.FunctionComponent = (props: any): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.summaryCommentsActions.loadSummaryComments();
      props.partnerCommentsActions.loadPartnerComments();
      props.diversityActions.requestDiversityAttyDash();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const summaryCommentForCurrentYear =
    props.summaryComments.summaryCommentCurrentYear;
  const summaryCommentForPlanYear =
    props.summaryComments.summaryCommentPlanYear;
  const partnerCommentsReviewYear = props.partnerComments.data;

  const canEditPlanSummaryComments = props.canEdit || props.canEditProxyUser;

  const handleSummaryCommentsPlan = (event: any, isCurrentYear: boolean) => {
    if (!canEditPlanSummaryComments) return;

      if (!isCurrentYear) {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentPlanYear,
                  diversitySummaryComments: event.target.value,
              },
              isCurrentYear
          );
      } else {
          props.summaryCommentsActions.saveSummaryComments(
              {
                  ...props.summaryComments.summaryCommentCurrentYear,
                  diversitySummaryComments: event.target.value,
              },
              isCurrentYear
          );
      }
  };

  const handlePartnerComments = (event: any) => {
    if (!props.partnerCommentsEnabled) return;

    const { name } = event.target;
    props.partnerCommentsActions.savePartnerComments({
      ...props.partnerComments.data,
      [name]: event.target.value,
    });
  };

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="diversity container">
      <Grid item className="header">
        <Typography variant="h5">Diversity, Equity & Inclusion</Typography>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Card>
          <CardHeader
            title={<Typography className="title">DE&I</Typography>}
            className="card-header"
          />
          <CardContent className="card-content">
            <Grid container>
              <Grid item xs={6}>
                <DiversityDashboard label={props.reviewYearText} showDashboard={true} diversityData={props.diversity.data} />
              </Grid>
              <Grid item sx={{ borderLeft: "2px solid #0033a0" }} xs={6}>
                <DiversityDashboard label={props.planYearText} showDashboard={false} diversityData={props.diversity.data} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sx={{ px: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Typography className="title">
                    Diversity, Equity & Inclusion Summary
                  </Typography>
                }
                className="card-header"
              />
              <CardContent className="card-content">
                <Grid container>
                  <Grid item className="p-1" xs={6}>
                    <TextArea
                      label=" Describe what specific actions you intend to take to advance the Firm's Diversity, Equity and Inclusion goals. (Max 500 characters)"
                      value={Format.formatString(
                        summaryCommentForCurrentYear &&
                          summaryCommentForCurrentYear.diversitySummaryComments
                      )}
                      name="diversitySummaryCommentsCurrentYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, true)}
                      limit={550}
                      disabled={!props.isExceptionUser}
                    />
                    <TextArea
                      label="Partner Comments: (Max 1100 characters)"
                      value={Format.formatString(
                        partnerCommentsReviewYear &&
                          partnerCommentsReviewYear.diversityComments
                      )}
                      name="diversityComments"
                      onChange={handlePartnerComments}
                      limit={1100}
                      disabled={!props.partnerCommentsEnabled}
                    />
                  </Grid>
                  <Grid
                    item
                    className="p-1"
                    sx={{ borderLeft: "2px solid #0033a0" }}
                    xs={6}
                  >
                    <TextArea
                      label=" Describe what specific actions you intend to take to advance the Firm's Diversity, Equity and Inclusion goals. (Max 500 characters)"
                      value={Format.formatString(
                        summaryCommentForPlanYear &&
                          summaryCommentForPlanYear.diversitySummaryComments
                      )}
                      name="diversitySummaryCommentsPlanningYear"
                      onChange={(e) => handleSummaryCommentsPlan(e, false)}
                      limit={550}
                      disabled={!canEditPlanSummaryComments}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="p-1"
        sx={{ justifyContent: "left", display: "flex" }}
      >
        <NavigationBtn
          nextLink="/goals"
          prevLink="/economic"
          prevBtnTxt={`< Prev`}
          nextBtnTxt={`Next >`}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    summaryComments: state.summaryComments,
    partnerComments: state.partnerComments,
    canEdit: canEditSelector(state),
    partnerCommentsEnabled: partnerCommentsEnabledSelector(state),
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
    reviewYearText: reviewYearTextSelector(state),
    planYearText: planYearTextSelector(state),
    canEditProxyUser: canEditProxyUserSelector(state),
    diversity: state.diversity,
    isExceptionUser: isExceptionUserSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    summaryCommentsActions: bindActionCreators(
      summaryCommentsActionCreators,
      dispatch
    ),
    partnerCommentsActions: bindActionCreators(
      partnerCommentsActionCreators,
      dispatch
    ),
    diversityActions: bindActionCreators(
      diversityActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabDiversity);
