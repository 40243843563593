import { baseUrl } from "../../utils/authConfig";

const requestProliferationAreasType = "REQUEST_PROLIFERATION_AREAS";
const receiveProliferationAreasType = "RECEIVE_PROLIFERATION_AREAS";
const receiveProliferationAreasErrorType = "RECEIVE_PROLIFERATION_AREAS_ERROR";

const initialState = {
	isLoaded: false,
	isLoading: false,
	proliferationAreas: []
};

export const actionCreators = {
	loadProliferationAreas: () => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.proliferationAreas.isLoaded) {
			return;
		}
		if (state.proliferationAreas.isLoading) {
			return;
		}
		
		dispatch({ type: requestProliferationAreasType });
		const url = `${baseUrl}api/ProliferationAreas`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveProliferationAreasErrorType, response: response });
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveProliferationAreasType, data: responseData});
		} catch (exception) {
			dispatch({ type: receiveProliferationAreasErrorType, exception: exception });
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestProliferationAreasType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveProliferationAreasType) {
		return {
			...state,
			isLoaded: true,
			isLoading: false,
			proliferationAreas: action.data
		};
	}

	if (action.type === receiveProliferationAreasErrorType) {
		return {
			...state,
			isLoaded: false,
			isLoading: false,
			proliferationAreas: []
		};
	}
	return state;
};