import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import Timetable from "./Timetable";
import "./home.scss";
import Contacts from "./Contacts";
import Documentation from "./Documentation";
import PreviousPCR from "./PreviousPCR";
import CurrentPCR from "./CurrentPCR";
import {
  planYearSelector,
  reviewYearSelector,
} from "../../store/reducers/Settings";
import { actionCreators } from "../../store/reducers/Timetable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


const TabHome: React.FunctionComponent = (props: any): JSX.Element => {
  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="home container">
      <Grid item className="header">
        <Typography variant="h5">
          HB Partner Compensation Review System
        </Typography>
      </Grid>
      <Grid item className="p-1">
        <Card>
          <CardHeader title={<Typography className="title">Welcome</Typography>}
                      className="card-header" />
          <CardContent className="card-content">
            <Typography variant="body1" component="p" className="fs-14">
              The HB Partner Compensation Review System for {props.reviewYear}/{props.planYear} is aligned
              with metrics and behaviors that will drive successful growth -
              collaboration, team success, accountability, client relationships,
              diversity, equity & inclusion, innovation and firm citizenship.
              Outlined below is a summary of the process.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid container>
        <Grid item className="p-1" xs={5}>
          <CurrentPCR />
        </Grid>
        <Grid item className="p-1" xs={7}>
         <Contacts />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className="p-1" xs={5}>
          <PreviousPCR />
          <Documentation />
        </Grid>
        <Grid item sx={{ p: 1 }} xs={7}>
          <Timetable />
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    reviewYear: reviewYearSelector(state),
    planYear: planYearSelector(state),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actionCreators: bindActionCreators(actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabHome);
