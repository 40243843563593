import React from "react"
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";


interface ExportXlsxProps {
    data: any;
    filename: string;
  }
// eslint-disable-next-line no-empty-pattern
const ExportXlsx: React.FunctionComponent<ExportXlsxProps> = ({
    data,
    filename
  }): JSX.Element => {

	const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const exportToXlsx = () => {
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = { Sheets: { 'data': ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const blob = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(blob, filename + fileExtension);
	};

	const disabled = !(data && data.length);

	return (
        <Button
            size="medium"
            className="exportButton"
            sx={{ float: "right" }}
            variant="contained"
            disabled={disabled}
            startIcon={<ExitToAppIcon />}
            onClick={(e) => exportToXlsx()}
          >
            Export
          </Button>
	);
};

export default ExportXlsx;