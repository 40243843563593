import { actionCreators as errorActionCreators } from "./Error";
import FetchQueue from "../../utils/FetchQueue";
import { baseUrl } from "../../utils/authConfig";
import { ISummaryComment } from "../../models/ISummaryComments";
import { reviewYearSelector, planYearSelector } from "./Settings";

const requestSummaryCommentsType = "REQUEST_SUMMARY_COMMENTS";
const receiveSummaryCommentsType = "RECEIVE_SUMMARY_COMMENTS";
const receiveSummaryCommentsCurrentYearType = "RECEIVE_SUMMARY_COMMENTS_CURRENT_YEAR";
const receiveSummaryCommentsPlanYearType = "RECEIVE_SUMMARY_COMMENTS_PLAN_YEAR";
const receiveSummaryCommentsErrorType = "RECEIVE_SUMMARY_COMMENTS_ERROR";
const requestPostSummaryCommentsType = "REQUEST_POST_SUMMARY_COMMENTS";
const receivePostSummaryCommentsType = "RECEIVE_POST_SUMMARY_COMMENTS";
const receivePostSummaryCommentsErrorType = "RECEIVE_POST_SUMMARY_COMMENTS_ERROR";

const initialState = {
	isLoaded: false,
	isLoading: false,
	isSaving: false,
	data: [],
	summaryCommentCurrentYear: {},
	summaryCommentPlanYear: {}
};

const saveQueue = new FetchQueue(`${baseUrl}api/SummaryComments/`,
	"PUT",
	null,
	function(dispatch: any, response: any) {
		dispatch({ type: receivePostSummaryCommentsErrorType, response: response });
		dispatch(errorActionCreators.addError("An error occurred while updating Summary comments.", null, response));},
	function(dispatch: any, exception: any) {
		dispatch({ type: receivePostSummaryCommentsErrorType, exception: exception });
		dispatch(errorActionCreators.addError("An exception occurred while updating Summary comments.", exception, null));});

export const actionCreators = {
	loadSummaryComments: () => async (dispatch:any, getState:any) => {
		var state = getState();
		const { userId } = state.auth;
		const reviewYear = reviewYearSelector(state);
		const planYear = planYearSelector(state);

		if(!userId){
      return;
    }
		
		if (state.summaryComments.data.userId === userId) {			
			return;
		}
		if (state.summaryComments.isLoading) {
			return;
		}

		dispatch({ type: requestSummaryCommentsType });
		const url = `${baseUrl}api/SummaryComments/${userId}`;
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveSummaryCommentsErrorType, response: response });
				return;
			}
			const data = await response.json();
			const currentYearComment = data.find((c:any) => c.year === reviewYear);
			const planYearComment = data.find((c:any) => c.year === planYear);
			dispatch({ type: receiveSummaryCommentsType, data: data });
			dispatch({ type: receiveSummaryCommentsCurrentYearType, data: currentYearComment });
			dispatch({ type: receiveSummaryCommentsPlanYearType, data: planYearComment });
		} catch (exception) {
			dispatch({ type: receiveSummaryCommentsErrorType, exception: exception });
		}
	},
	saveSummaryComments: (data: ISummaryComment, isCurrentYear: boolean) => async (dispatch:any, getState:any) => {	
		if (!data.summaryCommentsId) {
			return;
		}
		dispatch({ type: requestPostSummaryCommentsType, data: data });
		if(isCurrentYear)
			dispatch({ type: receiveSummaryCommentsCurrentYearType, data: data });
		else
			dispatch({ type: receiveSummaryCommentsPlanYearType, data: data });
		const url = `${baseUrl}api/SummaryComments/${data.summaryCommentsId}`;
		saveQueue.queue(data.summaryCommentsId, data, getState().auth.accessToken, dispatch, url);
	}
};

export const reducer = (state:any, action:any) => {
	state = state || initialState;

	if (action.type === requestSummaryCommentsType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveSummaryCommentsType) {
		return {
			...state,
			isLoading: false,
			data: action.data,
		};
	}

	if (action.type === receiveSummaryCommentsCurrentYearType) {
		return {
			...state,
			summaryCommentCurrentYear: action.data,
		};
	}

	if (action.type === receiveSummaryCommentsPlanYearType) {
		return {
			...state,
			summaryCommentPlanYear: action.data,
		};
	}

	if (action.type === receiveSummaryCommentsErrorType) {		
		return {
			...state,
			isLoading: false
		};
	}

	if (action.type === requestPostSummaryCommentsType) {
		const newState = {
			...state,
			isSaving: true,
			data: action.data
		};
		return newState;
	}

	if (action.type === receivePostSummaryCommentsType) {		
		return {
			...state,
			isSaving: false
		};
	}

	if (action.type === receivePostSummaryCommentsErrorType) {
		return {
			...state,
			isSaving: false
		};
	}

	return state;
};