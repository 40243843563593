import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import Format from "../../../utils/Format";
import TextArea from "../../common/textarea/TextArea";

interface GoalSummarySectionProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  goalSummaryCommentsPlanYear: string;
  goalSummaryCommentsCurrentYear: string;
  goalComments: string;
  handlePartnerComments: any;
  handleSummaryCommentsPlan: any;
  canEdit:boolean;
  partnerCommentsEnabled: boolean;
  canEditProxyUser: boolean;
  isExceptionUser: boolean;
}

const GoalSummarySection: React.FunctionComponent<GoalSummarySectionProps> = ({
  goalSummaryCommentsPlanYear,
  goalSummaryCommentsCurrentYear,
  goalComments,
  handlePartnerComments,
  handleSummaryCommentsPlan,
  canEdit,
  partnerCommentsEnabled,
  canEditProxyUser,
  isExceptionUser
}): React.ReactElement => {
  const canEditPlanSummaryComments = canEdit || canEditProxyUser;

  return (
    <>
      <Card>
        <CardHeader
          title={<Typography className="title">Goal Summary</Typography>}
          className="card-header"
        />
        <CardContent className="card-content">
          <Grid container>
            <Grid item className="p-1" xs={6}>
              <TextArea
                label="Provide additional information to support planned goals. (Max 550 characters)"
                value={Format.formatString(goalSummaryCommentsCurrentYear)}
                name="goalSummaryCommentsCurrentYear"
                onChange={(e) => handleSummaryCommentsPlan(e, true)}
                limit={550}
                disabled={!isExceptionUser}
              />
              <TextArea
                label="Partner Comments: (Max 1100 characters)"
                value={Format.formatString(goalComments)}
                name="goalComments"
                onChange={handlePartnerComments}
                limit={1100}
                disabled={!partnerCommentsEnabled}
              />
            </Grid>
            <Grid
              item
              className="p-1"
              sx={{ borderLeft: "2px solid #0033a0" }}
              xs={6}
            >
              <TextArea
                label="Provide additional information to support planned goals. (Max 550 characters)"
                value={Format.formatString(goalSummaryCommentsPlanYear)}
                name="goalSummaryCommentsPlanningYear"
                onChange={(e) => handleSummaryCommentsPlan(e, false)}
                limit={550}
                disabled={!canEditPlanSummaryComments}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default GoalSummarySection;
