import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const closeTeamType = "CLOSE_TEAM";
const editTeamType = "EDIT_TEAM";
const requestTeamsType = "REQUEST_TEAMS";
const receiveTeamsType = "RECEIVE_TEAMS";
const receiveTeamsErrorType = "RECEIVE_TEAMS_ERROR";
const requestPostTeamType = "REQUEST_POST_TEAM";
const receivePostTeamType = "RECEIVE_POST_TEAM";
const receivePostTeamErrorType = "RECEIVE_POST_TEAM_ERROR";
const requestPutTeamType = "REQUEST_PUT_TEAM";
const receivePutTeamType = "RECEIVE_PUT_TEAM";
const receivePutTeamErrorType = "RECEIVE_PUT_TEAM_ERROR";
const requestDeleteTeamType = "REQUEST_DELETE_TEAM";
const receiveDeleteTeamType = "RECEIVE_DELETE_TEAM";
const receiveDeleteTeamErrorType = "RECEIVE_DELETE_TEAM_ERROR";
const showNewTeamType = "SHOW_NEW_TEAM";
const setYearType = "SET_YEAR";

const initialState = {
  editTeamVisible: false,
  isDeleting: false,
  isLoadedUserId: null,
  isLoading: false,
  isPosting: false,
  isPutting: false,
  newTeamVisible: false,
  requestedYear: null,
  editingTeamId: null,
  teams: [],
};

function convertPercentagesFromServer(data: any): any {
  const result = { ...data };
  if (data.directMarginTarget)
    result.directMarginTarget = data.directMarginTarget * 100;
  return result;
}

function convertPercentagesToServer(data: any): any {
  const result = { ...data };
  if (data.directMarginTarget)
    result.directMarginTarget = data.directMarginTarget / 100;
  return result;
}

export const actionCreators = {
  closeTeam: () => ({ type: closeTeamType }),
  editTeam: (data: any) => ({ type: editTeamType, teamId: data.teamId }),
  showNewTeam: (visible = true) => ({
    type: showNewTeamType,
    visible: visible,
  }),
  setYear: (year: number) => ({ type: setYearType, year: year }),

  loadTeams: () => async (dispatch: any, getState: any) => {
    const state = getState();
    const { userId } = state.auth;
    if (state.teamwork.isLoadedUserId === userId) {
      return;
    }

    if (state.teamwork.isLoading) {
      return;
    }

    dispatch({ type: requestTeamsType });
    const url = baseUrl + `api/Teams/${userId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getState().auth.accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        dispatch({ type: receiveTeamsErrorType, response: response });
        return;
      }
      const responseData = await response.json();
      const responseData2 = responseData.map((team: any) =>
        convertPercentagesFromServer(team)
      );
      dispatch({ type: receiveTeamsType, data: responseData2, userId: userId });
    } catch (exception) {
      dispatch({ type: receiveTeamsErrorType, exception: exception });
    }
  },
  addTeam: (data: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.teamwork.isPosting) {
      return;
    }

    const data2 = convertPercentagesToServer(data);
    const { userId } = state.auth;
    const data3 = { ...data2, userId: userId };
    dispatch({ type: requestPostTeamType, data: data });
    const url = baseUrl + `api/Teams`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
        body: JSON.stringify(data3),
      });
      if (!response.ok) {
        dispatch({ type: receivePostTeamErrorType, response: response });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while adding a team.",
            null,
            response
          )
        );
        return;
      }
      const responseData = await response.json();
      const responseData2 = convertPercentagesFromServer(responseData);
      dispatch({ type: receivePostTeamType, data: responseData2 });
    } catch (exception) {
      dispatch({ type: receivePostTeamErrorType, exception: exception });
      dispatch(
        errorActionCreators.addError(
          "An error occurred while adding a team.",
          exception,
          null
        )
      );
    }
  },
  removeTeam: (data: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.teamwork.isDeleting) {
      return;
    }

    dispatch({ type: requestDeleteTeamType, data: data });
    const url = baseUrl + `api/Teams/${data}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({ type: receiveDeleteTeamErrorType, response: response });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while removing a team.",
            null,
            response
          )
        );
        return;
      }
      const responseData = await response.json();
      dispatch({ type: receiveDeleteTeamType, data: responseData });
    } catch (exception) {
      dispatch({ type: receiveDeleteTeamErrorType, exception: exception });
      dispatch(
        errorActionCreators.addError(
          "An error occurred while removing a team.",
          exception,
          null
        )
      );
    }
  },
  updateTeam: (data: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.teamwork.isPutting) {
      return;
    }
    const data2 = convertPercentagesToServer(data);
    dispatch({ type: requestPutTeamType, data: data });
    const url = baseUrl + `api/Teams/${data.teamId}`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(data2),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({ type: receivePutTeamErrorType, response: response });
        dispatch(
          errorActionCreators.addError(
            "An error occurred while updating a team.",
            null,
            response
          )
        );
        return;
      }
      const responseData = await response.json();
      const responseData2 = convertPercentagesFromServer(responseData);
      dispatch({ type: receivePutTeamType, data: responseData2 });
    } catch (exception) {
      dispatch({ type: receivePutTeamErrorType, exception: exception });
      dispatch(
        errorActionCreators.addError(
          "An error occurred while updating a team.",
          exception,
          null
        )
      );
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  if (action.type === closeTeamType) {
    return {
      ...state,
      editTeamVisible: false,
      newTeamVisible: false,
    };
  }

  if (action.type === showNewTeamType) {
    return {
      ...state,
      newTeamVisible: action.visible,
    };
  }

  if (action.type === setYearType) {
    return {
      ...state,
      requestedYear: action.year,
    };
  }

  if (action.type === requestPostTeamType) {
    return {
      ...state,
      isPosting: true,
      newTeamVisible: false,
    };
  }

  if (action.type === receivePostTeamType) {
    return {
      ...state,
      isPosting: false,
      teams: [...state.teams, action.data],
    };
  }

  if (action.type === receivePostTeamErrorType) {
    return {
      ...state,
      isPosting: false,
    };
  }

  if (action.type === requestPutTeamType) {
    return {
      ...state,
      isPutting: true,
      editTeamVisible: false,
    };
  }

  if (action.type === receivePutTeamType) {
    return {
      ...state,
      isPutting: false,
      teams: state.teams.map((team: any) =>
        team.teamId === action.data.teamId ? { ...team, ...action.data } : team
      ),
    };
  }

  if (action.type === receivePutTeamErrorType) {
    return {
      ...state,
      isPutting: false,
    };
  }

  if (action.type === requestDeleteTeamType) {
    return {
      ...state,
      isDeleting: true,
    };
  }

  if (action.type === receiveDeleteTeamType) {
    return {
      ...state,
      isDeleting: false,
      teams: state.teams.filter(
        (team: any) => team.teamId !== action.data.teamId
      ),
    };
  }

  if (action.type === receiveDeleteTeamErrorType) {
    return {
      ...state,
      isDeleting: false,
    };
  }

  if (action.type === requestPutTeamType) {
    return {
      ...state,
      isPutting: true,
    };
  }

  if (action.type === receivePutTeamType) {
    return {
      ...state,
      isPutting: false,
      teams: state.teams.map((team: any) =>
        team.teamId === action.data.teamId ? { ...team, ...action.data } : team
      ),
    };
  }

  if (action.type === receivePutTeamErrorType) {
    return {
      ...state,
      isPutting: false,
    };
  }

  if (action.type === editTeamType) {
    return {
      ...state,
      editTeamVisible: true,
      editingTeamId: action.teamId,
    };
  }

  if (action.type === requestTeamsType) {
    return {
      ...state,
      isLoading: true,
    };
  }

  if (action.type === receiveTeamsType) {
    return {
      ...state,
      isLoadedUserId: action.userId,
      isLoading: false,
      teams: action.data,
    };
  }

  if (action.type === receiveTeamsErrorType) {
    return {
      ...state,
      isLoadedUserId: null,
      isLoading: false,
      teams: [],
    };
  }

  return state;
};
