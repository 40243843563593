import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { routerReducer, routerMiddleware } from "react-router-redux"
import thunk from 'redux-thunk'
import * as Auth from "./reducers/Auth";
import * as Home from "./reducers/Home";
import * as Settings from "./reducers/Settings";
import * as Header from "./reducers/Header";
import * as ProxyUser from "./reducers/ProxyUser";
import * as Timetable from "./reducers/Timetable";
import * as Report from "./reducers/Reports";
import * as Teamwork from "./reducers/Teamwork";
import * as SummaryComments from "./reducers/SummaryComments";
import * as PartnerComments from "./reducers/PartnerComments";
import * as Economic from "./reducers/Economic";
import * as EconomicCrmClients from "./reducers/EconomicCrmClients";
import * as EconomicHistory from "./reducers/EconomicHistory";
import * as EconomicPcClients from "./reducers/EconomicPcClients";
import * as EconomicScClients from "./reducers/EconomicScClients";
import * as Goals from "./reducers/Goals";
import * as SbuLeaderComments from "./reducers/SbuLeaderComments";
import * as ClientProjects from "./reducers/ClientProjects";
import * as ProliferationAreas from "./reducers/ProliferationAreas";
import * as AdminEmail from "./reducers/AdminEmail";
import * as AdminExceptionUser from "./reducers/AdminExceptionUser";
import * as Diversity from "./reducers/Diversity";
import * as AdminProxyUser from "./reducers/AdminProxyUsers";

export default function configureAppStore(history: any) {

  const reducers = {
		 auth: Auth.reducer
		,home: Home.reducer
		,settings: Settings.reducer
		,header: Header.reducer
		,proxyUser: ProxyUser.reducer
		,timetable: Timetable.reducer
		,report: Report.reducer
		,teamwork: Teamwork.reducer
		,summaryComments: SummaryComments.reducer
		,partnerComments: PartnerComments.reducer
		,economic: Economic.reducer
		,economicCrmClients: EconomicCrmClients.reducer
		,economicHistory: EconomicHistory.reducer
		,economicPcClients: EconomicPcClients.reducer
		,economicScClients: EconomicScClients.reducer
		,goals: Goals.reducer
		,sbuLeaderComments: SbuLeaderComments.reducer
		,proliferationAreas: ProliferationAreas.reducer
		,clientProjects: ClientProjects.reducer
		,adminEmail: AdminEmail.reducer
		,adminExceptionUser: AdminExceptionUser.reducer
		,diversity: Diversity.reducer
		,adminProxyUser: AdminProxyUser.reducer
	};

  const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer
	});

	const middleware = [
		thunk,
		routerMiddleware(history)
	];
	
  const store = configureStore({
    reducer: rootReducer,
		middleware: middleware,
    enhancers: [],
  })

  return store
}