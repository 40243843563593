/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";
import { baseUrl } from "../../utils/authConfig";
import { proxyUserId } from "../../utils/storage-key";
import settingsUtil from "../../utils/settingsUtil";

const requestSettingsType = "REQUEST_SETTINGS";
const receiveSettingsType = "RECEIVE_SETTINGS";
const receiveSettingsErrorType = "RECEIVE_SETTINGS_ERROR";
const requestPutSettingType = "REQUEST_PUT_SETTING";
const receivePutSettingType = "RECEIVE_PUT_SETTING";
const receivePutSettingErrorType = "RECEIVE_PUT_SETTING_ERROR";

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: [
    { name: "azureStorageKey", value: "" },
    { name: "reviewYear", value: "" },
    { name: "canEdit", value: "false" },
    { name: "canEditProxy", value: "false" },
    { name: "canEditTeam", value: "false" },
    { name: "expectationsEnabled", value: "false" },
    { name: "partnerCommentsEnabled", value: "false" },
    { name: "sbuLeaderCommentsEnabled", value: "false" },
  ],
};

export const actionCreators = {
  loadSettings: () => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.settings.isLoaded || state.settings.isLoading) {
      return;
    }
    dispatch({ type: requestSettingsType });
    try {
      const url = `${baseUrl}api/Settings`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({ type: receiveSettingsErrorType, response: response });
        return;
      }
      const data = await response.json();
      dispatch({ type: receiveSettingsType, data: data });
    } catch (exception) {
      dispatch({ type: receiveSettingsErrorType, exception: exception });
    }
  },
  updateSetting: (data: any) => async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.settings.isUpdating) {
      return;
    }

    dispatch({ type: requestPutSettingType, data: data });
    const url = `${baseUrl}api/Settings/${data.name}`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({ type: receivePutSettingErrorType, response: response });
        return;
      }
      const responseData = await response.json();
      dispatch({ type: receivePutSettingType, data: responseData });
    } catch (exception) {
      dispatch({ type: receivePutSettingErrorType, exception: exception });
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case requestSettingsType:
      return {
        ...state,
        isLoading: true,
      };

    case receiveSettingsType:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.data,
      };

    case receiveSettingsErrorType:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        data: [],
      };

    case requestPutSettingType:
      return {
        ...state,
        isUpdating: true,
      };

    case receivePutSettingType:
      const data = [
        ...state.data.filter((d: any) => d.name !== action.data.name),
        { name: action.data.name, value: action.data.value },
      ];
      return {
        ...state,
        isUpdating: false,
        data: data,
      };

    case receivePutSettingErrorType:
      return {
        ...state,
        isUpdating: false,
      };

    default:
      return state;
  }
};

const userIdSelector = (state: { auth: { userId: number } }) =>
  state.auth.userId;
const currentUserIdSelector = (state: { auth: { currentUserId: number } }) =>
  state.auth.currentUserId;
const isAttorneySelector = (state: { auth: { user: { isAttorney: any } } }) =>
  state.auth.user.isAttorney;

export const isExceptionUserSelector = (state: {
  auth: { isExceptionUser: any };
}) => state.auth.isExceptionUser;

export const isProxySelector = createSelector(
  userIdSelector,
  currentUserIdSelector,
  (userId, currentUserId) => {
    return userId !== currentUserId;
  }
);

const settingsSelector = (state: { settings: { data: any } }) =>
  state.settings.data;

export const canEditSelector = createSelector(
  settingsSelector,
  isAttorneySelector,
  isExceptionUserSelector,
  isProxySelector,
  (settings, isAttorney, isExceptionUserSelector, isProxy) => {
    if (isExceptionUserSelector) return true;

    if (isProxy) {
      return settingsUtil.getCanEditProxy(settings, isProxy);
    }

    const canEditSetting = settings.find(
      (s: { name: string }) => s.name === "canEdit"
    );

    return (
      (canEditSetting ? canEditSetting.value === "true" : false) && isAttorney
    );
  }
);

export const canEditProxyUserSelector = createSelector(
  isProxySelector,
  settingsSelector,
  isExceptionUserSelector,
  (isProxy, settings, isExceptionUserSelector) => {
    if (isExceptionUserSelector) return true;

    if (!isProxy) return false;

    return settingsUtil.getCanEditProxy(settings, isProxy);
  }
);

export const canEditTeamSelector = createSelector(
  settingsSelector,
  isAttorneySelector,
  isExceptionUserSelector,
  isProxySelector,
  (settings, isAttorney, isExceptionUserSelector, isProxy) => {
    if (isExceptionUserSelector) return true;

    if (isProxy) {
      return settingsUtil.getCanEditProxy(settings, isProxy);
    }

    const canEditTeamSetting = settings.find(
      (s: { name: string }) => s.name === "canEditTeam"
    );

    return (
      (canEditTeamSetting ? canEditTeamSetting.value === "true" : false) &&
      isAttorney
    );
  }
);

export const partnerCommentsEnabledSelector = createSelector(
  settingsSelector,
  isExceptionUserSelector,
  (settings, isExceptionUserSelector) => {
    if (isExceptionUserSelector) return true;

    const partnerCommentsEnabled = settings.find(
      (s: { name: string }) => s.name === "partnerCommentsEnabled"
    );
    return partnerCommentsEnabled && partnerCommentsEnabled.value === "true";
  }
);

export const partnerCommentsVisibleSelector = createSelector(
  settingsSelector,
  isExceptionUserSelector,
  (settings, isExceptionUserSelector) => {
    if (isExceptionUserSelector) return true;
    const partnerCommentsEnabled = settings.find(
      (s: { name: string }) => s.name === "partnerCommentsEnabled"
    );

    const partnerEnabled =
      partnerCommentsEnabled && partnerCommentsEnabled.value === "true";
    const sbuLeaderCommentsEnabled = settings.find(
      (s: { name: string }) => s.name === "sbuLeaderCommentsEnabled"
    );

    const sbuLeaderEnabled =
      sbuLeaderCommentsEnabled && sbuLeaderCommentsEnabled.value === "true";
    return partnerEnabled || sbuLeaderEnabled;
  }
);

export const sbuLeaderCommentsEnabledSelector = createSelector(
  settingsSelector,
  userIdSelector,
  currentUserIdSelector,
  (settings, userId, currentUserId) => {
    const sbuLeaderCommentsEnabled = settings.find(
      (s: { name: string }) => s.name === "sbuLeaderCommentsEnabled"
    );

    const enabled =
      sbuLeaderCommentsEnabled && sbuLeaderCommentsEnabled.value === "true";
    const notSelf = currentUserId !== userId;
    return enabled && notSelf;
  }
);

export const azureStorageSelector = createSelector(
  settingsSelector,
  (settings) => {
    const azureStorageKey = settings.find(
      (s: { name: string }) => s.name === "azureStorageKey"
    );
    return azureStorageKey?.value;
  }
);

export const reviewYearSelector = createSelector(
  settingsSelector,
  (settings) => {
    const reviewYear = settings.find(
      (s: { name: string }) => s.name === "reviewYear"
    ).value;
    return parseInt(reviewYear);
  }
);

export const reviewYearTextSelector = createSelector(
  settingsSelector,
  (settings) => {
    const currentYear = settings.find(
      (s: { name: string }) => s.name === "reviewYear"
    ).value;
    return `${currentYear} In Review`;
  }
);

export const planYearSelector = createSelector(settingsSelector, (settings) => {
  const currentYear = settings.find(
    (s: { name: string }) => s.name === "reviewYear"
  ).value;
  return parseInt(currentYear) + 1;
});

export const planYearTextSelector = createSelector(
  settingsSelector,
  (settings) => {
    const currentYear = settings.find(
      (s: { name: string }) => s.name === "reviewYear"
    ).value;
    return `${parseInt(currentYear) + 1} Planning`;
  }
);

export const proxyUserSelector = () => {
  return sessionStorage.getItem(proxyUserId) ?? "";
};
