import { Grid, FormControl } from "@mui/material";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicHistory } from "../../models/IEconomicHistory";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import EconomicHistory from "./EconomicHistory";

interface EconomicNccClientProps {
  disabled?: boolean;
  economic: IEconomic;
  canEdit?: boolean;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
}

const EconomicNccClient: React.FunctionComponent<EconomicNccClientProps> = ({
  disabled,
  onChange,
  economic,
  economicHistories
}): JSX.Element => {

  const handleOnChange = (event:  any) => {
    if (disabled) return;

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Grid container>
      <EconomicHistory  label="Revenue Dollars Collected:"
                        section="ncc"
                        economicHistories={economicHistories} />
      <FormControl fullWidth>
        <Input  placeholder="Revenue" 
                value={Format.formatCurrencyInput(economic.nccRevenue)}
                icon="attach_money"
                fullWidth
                disabled={disabled}
                type="number"
                name="nccRevenue"
                onChange={handleOnChange}
                valid={Utils.getValidationStateRevenue(economic.nccRevenue)} />
      </FormControl>   
  </Grid>
  )
}

export default EconomicNccClient;