import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import {
  msalConfig,
  loginRequest,
  tokenRequest,
  silentRequest,
} from "./authConfig";
import { getProfile } from "../store/graph/graphServiceActions";
import {
  updateAccount,
  updateToken,
  updateLoadingStatus,
} from "../store/reducers/Auth";
import { tokenTimeout } from "./storage-key";

const msalApp = new PublicClientApplication(msalConfig);

const AuthHOC = (WrappedComponent) =>
  class AuthProvider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        error: null,
        username: null,
        isAuthenticated: false,
        accessToken: null,
      };
    }

    componentDidMount = () => {
      msalApp.addEventCallback((event) => {
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account;
          msalApp.setActiveAccount(account);
        }
      });

        msalApp
            .handleRedirectPromise()
            .then((authResult) => {
                const account = msalApp.getActiveAccount();
                if (account) {
                    this.setState({
                        username: account.username,
                        account: account,
                        isAuthenticated: true,
                    });
                    msalApp.setActiveAccount(account);
                    this.props.updateAccount(account);
                    this.acquireToken().then((response) => {
                        if (response) {
                            this.setState({
                                accessToken: response.accessToken,
                            });
                            this.props.updateToken(response.accessToken);
                            localStorage.setItem(
                                tokenTimeout,
                                JSON.stringify(response.expiresOn)
                            );
                            if (this.state.account) {
                                this.props.getProfile(this.state.account.idTokenClaims.oid);
                            }
                        }
                    });
                } else if (authResult) {
                    this.setState({
                        username: authResult.account.username,
                        account: authResult.account,
                        isAuthenticated: true,
                    });
                    msalApp.setActiveAccount(authResult.account);
                    this.props.updateAccount(authResult.account);
                    if (this.state.account) {
                        this.acquireToken().then((response) => {
                            if (response) {
                                this.setState({
                                    accessToken: response.accessToken,
                                });
                                this.props.updateToken(response.accessToken);
                                localStorage.setItem(
                                    tokenTimeout,
                                    JSON.stringify(response.expiresOn)
                                );
                                if (this.state.account) {
                                    this.props.getProfile(this.state.account.idTokenClaims.oid);
                                }
                            }
                        });
                    }
                    this.props.updateLoadingStatus(false);
                } else {
                    msalApp.loginRedirect();
                }
            });
    };

    getAccounts = () => {
      const currentAccounts = msalApp.getAllAccounts();

      if (currentAccounts === null) {
        return;
      } else if (currentAccounts.length > 1) {
        this.setState({
          username: currentAccounts[0].username,
          account: currentAccounts[0],
          isAuthenticated: true,
        });
        this.props.updateAccount(currentAccounts[0]);
      } else if (currentAccounts.length === 1) {
        this.setState({
          username: currentAccounts[0].username,
          account: currentAccounts[0],
          isAuthenticated: true,
        });
        this.props.updateAccount(currentAccounts[0]);
      }
    };

    handleResponse = (response) => {
      if (response !== null) {
        this.setState({
          account: response.account,
          username: response.account.username,
          isAuthenticated: true,
          accessToken: response.accessToken,
        });
      } else {
        this.getAccounts();
      }
    };

    acquireToken = async () => {
      silentRequest.account = msalApp.getAccountByUsername(this.state.username);
      return msalApp.acquireTokenSilent(silentRequest).catch((error) => {
        if (error) {
          tokenRequest.account = msalApp.getAccountByUsername(
            this.state.username
          );
          return msalApp
            .acquireTokenPopup(tokenRequest)
            .then(this.handleResponse)
            .catch((err) => {
              this.setState({ error: err.errorMessage });
            });
        }
      });
    };

    signIn = async () => {
      return msalApp.loginRedirect(loginRequest);
    };

    signOut = async () => {
      const logoutRequest = {
        account: msalApp.getAccountByUsername(this.state.username),
      };

      return await msalApp.logoutRedirect(logoutRequest);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          account={this.state.account}
          error={this.state.error}
          isAuthenticated={this.state.isAuthenticated}
          accessToken={this.state.accessToken}
          signIn={() => this.signIn()}
          signOut={() => this.signOut()}
          acquireToken={() => this.acquireToken()}
        />
      );
    }
  };

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => {
    dispatch(getProfile());
  },
  updateAccount: (account) => {
    dispatch(updateAccount(account));
  },
  updateToken: (token) => {
    dispatch(updateToken(token));
  },
  updateLoadingStatus: (status) => {
    dispatch(updateLoadingStatus(status));
  },
});

export default compose(connect(mapStateToProps, mapDispatchToProps), AuthHOC);
