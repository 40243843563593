import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

interface ConfirmationAddProps {
  open: boolean;
  onClose(): void;
  onOk(): void;
  text: string;
}

// eslint-disable-next-line no-empty-pattern
const ConfirmationAdd: React.FunctionComponent<ConfirmationAddProps> = ({
  open,
  onClose,
  onOk,
  text,
}): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="confirmation-add"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <HelpIcon
          sx={{ fontSize: "120px", marginRight: "10px" }}
          style={{ color: "#0033a0" }}
        />{" "}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ textAlign: "center", mb: 2, color: "#0033a0" }} variant="h5" >Are you sure?</Typography>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{my: 2}}>
        <Button variant="contained" size="medium" sx={{backgroundColor: "#000"}} className="btn-cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="medium"
          className="btn-add"
          onClick={onOk}
          autoFocus
          color="error"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationAdd;
