import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button } from "@mui/material";

interface MenuLinkProps {
  link: string;
  label: string;
}

const MenuLink: React.FunctionComponent<MenuLinkProps> = ({
  link,
  label,
}): React.ReactElement => {
  return (
    <Button
      component={RouterLink}
      to={link}
      sx={{
        color: "#838383",
        display: "block",
        textTransform: "capitalize"
      }}
    >
      {" "}
      {label}{" "}
    </Button>
  );
};

export default MenuLink;
