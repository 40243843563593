import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { IProgressBarProps } from "../../models/IProgressBarProps";

const SelectProxyUser: React.FunctionComponent<IProgressBarProps> = ({
  open,
  onClose,
  onOk,
  setProxyId,
  users,
  selectedProxyUserId,
  currentUserId,
  setCurrentUser,
}): JSX.Element => {
  const attorneys =
    users &&
    users.filter((user) => user.isAttorney && user.userId !== currentUserId);

  const handleOk = () => {
    onOk();
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="xs">
      <DialogTitle id="scroll-dialog-title" sx={{ textAlign: "center", pb: 0 }}>
        <PersonIcon sx={{ fontSize: 150, color: "#0033a0" }} />
      </DialogTitle>
      <Typography sx={{ textAlign: "center", fontSize: 22, pb: 2 }}>
        Select a User
      </Typography>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          Select a user for whom you want to complete their Partner Contribution
          Report.
        </DialogContentText>
        <Autocomplete
          id="autoCompleteProxyUsers"
          getOptionLabel={(option) => option.lastName}
          value={
            (selectedProxyUserId &&
              attorneys.filter(
                (user) => user.userId === selectedProxyUserId
              )[0]) ||
            null
          }
          options={attorneys}
          sx={{ marginTop: "10px" }}
          onChange={(_event: any, newValue: any) => {
            setProxyId(newValue?.userId);
            setCurrentUser(newValue);
          }}
          renderInput={(params: any) => <TextField {...params} label="Name" />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="theme-button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className="theme-button"
          onClick={() => handleOk()}
          disabled={!selectedProxyUserId}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectProxyUser;
