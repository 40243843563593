import { baseUrl } from "../../utils/authConfig";

const setAdminProxyUserLoading = "SET_ADMIN_PROXY_USER_LOADING";
const setProxyUserListData = "SET_PROXY_USER_LIST_DATA";
const setUsersListData = "SET_USERS_LIST_DATA";
const receiveAdminProxyErrorType = "RECEIVE_ADMIN_PROXY_USER_ERROR";

const initialState = {
  isLoading: false,
  proxyUserListData: [],
  usersListData: [],
  errorResponse: "",
};

export const actionCreators = {
  loadProxyUsers: () => async (dispatch: any, getState: any) => {
    dispatch({
      type: setAdminProxyUserLoading,
      payload: true,
    });

    const url = `${baseUrl}api/proxyUsers/All`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({
          type: receiveAdminProxyErrorType,
          payload: response,
        });
        return;
      }
      const responseData = await response.json();
      dispatch({
        type: setProxyUserListData,
        payload: responseData,
      });
    } catch (error) {
      dispatch({
        type: receiveAdminProxyErrorType,
        payload: error,
      });
    } finally {
      dispatch({
        type: setAdminProxyUserLoading,
        payload: false,
      });
    }
  },
  updateProxyUser:
    (userId: any, dataToSend: any) => async (dispatch: any, getState: any) => {
      const state = getState();
      if (state.adminProxyUser.isLoading) {
        return;
      }
      try {
        dispatch({
          type: setAdminProxyUserLoading,
          payload: true,
        });
        const url = `${baseUrl}api/ProxyUsers/${userId}`;
        const response = await fetch(url, {
          method: "PUT",
          body: JSON.stringify(dataToSend),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: receiveAdminProxyErrorType,
            payload: response,
          });
          return;
        }
        dispatch(actionCreators.loadProxyUsers());
      } catch (error) {
        dispatch({
          type: receiveAdminProxyErrorType,
          payload: error,
        });
      } finally {
        dispatch({
          type: setAdminProxyUserLoading,
          payload: false,
        });
      }
    },
  removeProxyUser:
    (dataToSend: any) => async (dispatch: any, getState: any) => {
      const state = getState();
      if (state.adminProxyUser.isLoading) {
        return;
      }

      try {
        dispatch({
          type: setAdminProxyUserLoading,
          payload: true,
        });
        const url = `${baseUrl}api/proxyUsers`;
        const response = await fetch(url, {
          method: "DELETE",
          body: JSON.stringify(dataToSend),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.accessToken}`,
          },
          credentials: "same-origin",
        });
        if (!response.ok) {
          dispatch({
            type: receiveAdminProxyErrorType,
            payload: response,
          });
          return;
        }
        dispatch(actionCreators.loadProxyUsers());
      } catch (error) {
        dispatch({
          type: receiveAdminProxyErrorType,
          payload: error,
        });
      } finally {
        dispatch({
          type: setAdminProxyUserLoading,
          payload: false,
        });
      }
    },
  loadUsers: () => async (dispatch: any, getState: any) => {
    dispatch({
      type: setAdminProxyUserLoading,
      payload: true,
    });
    try {
      const url = `${baseUrl}api/Users/All`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.accessToken}`,
        },
        credentials: "same-origin",
      });
      if (!response.ok) {
        dispatch({
          type: receiveAdminProxyErrorType,
          payload: response,
        });
        return;
      }
      const data = await response.json();
      dispatch({
        type: setUsersListData,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: receiveAdminProxyErrorType,
        payload: error,
      });
    } finally {
      dispatch({
        type: setAdminProxyUserLoading,
        payload: false,
      });
    }
  },
};

export const reducer = (state: any, action: any) => {
  state = state || initialState;

  switch (action.type) {
    case setAdminProxyUserLoading: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case setProxyUserListData: {
      return {
        ...state,
        proxyUserListData: action.payload,
      };
    }
    case setUsersListData: {
      return {
        ...state,
        usersListData: action.payload,
      };
    }
    case receiveAdminProxyErrorType: {
      return {
        ...state,
        errorResponse: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
