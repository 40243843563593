import { Table as ReactTable } from "@tanstack/react-table";

export default function Pagination({
  table,
}: {
  table: ReactTable<any>;
}) {
  return (
    <div className="pagination">
      <div className="previous">
        <button
          type="button"
          className="btn"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
      </div>
      <div className="center">
        <span className="pageInfo">
          Page{" "}
          <div className="pageJump">
            <input
              type="number"
              value={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = Math.max(Number(e.target.value || 1) -1, 0);
                table.setPageIndex(page);
              }}
            />
          </div>{" "}
          of <span className="totalPages">{table.getPageCount()}</span>
        </span>
        <span className="select-wrap -pageSizeOptions">
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageIndex(0);
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} rows
              </option>
            ))}
          </select>
        </span>
      </div>
      <div className="next">
        <button
          type="button"
          className="btn"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
