import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const closeEconomicPcClientType = "CLOSE_ECONOMIC_PC_CLIENT";
const editEconomicPcClientType = "EDIT_ECONOMIC_PC_CLIENT";
const requestEconomicPcClientsType = "REQUEST_ECONOMIC_PC_CLIENTS";
const receiveEconomicPcClientsType = "RECEIVE_ECONOMIC_PC_CLIENTS";
const receiveEconomicPcClientsErrorType = "RECEIVE_ECONOMIC_PC_CLIENTS_ERROR";
const requestPostEconomicPcClientType = "REQUEST_POST_ECONOMIC_PC_CLIENT";
const receivePostEconomicPcClientType = "RECEIVE_POST_ECONOMIC_PC_CLIENT";
const receivePostEconomicPcClientErrorType = "RECEIVE_POST_ECONOMIC_PC_CLIENT_ERROR";
const requestPutEconomicPcClientType = "REQUEST_PUT_ECONOMIC_PC_CLIENT";
const receivePutEconomicPcClientType = "RECEIVE_PUT_ECONOMIC_PC_CLIENT";
const receivePutEconomicPcClientErrorType = "RECEIVE_PUT_ECONOMIC_PC_CLIENT_ERROR";
const requestDeleteEconomicPcClientType = "REQUEST_DELETE_ECONOMIC_PC_CLIENT";
const receiveDeleteEconomicPcClientType = "RECEIVE_DELETE_ECONOMIC_PC_CLIENT";
const receiveDeleteEconomicPcClientErrorType = "RECEIVE_DELETE_ECONOMIC_PC_CLIENT_ERROR";
const showNewEconomicPcClientType = "SHOW_NEW_ECONOMIC_PC_CLIENT";
const closeEconomicPcClientReviewYearType = "CLOSE_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const editEconomicPcClientReviewYearType = "EDIT_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const showNewEconomicPcClientReviewYearType = "SHOW_NEW_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const requestPostEconomicPcClientReviewYearType = "REQUEST_POST_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const receivePostEconomicPcClientReviewYearType = "RECEIVE_POST_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const receivePostEconomicPcClientErrorReviewYearType = "RECEIVE_POST_ECONOMIC_PC_CLIENT_ERROR_REVIEW_YEAR";
const requestPutEconomicPcClientReviewYearType = "REQUEST_PUT_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const receivePutEconomicPcClientReviewYearType = "RECEIVE_PUT_ECONOMIC_PC_CLIENT_REVIEW_YEAR";
const receivePutEconomicPcClientErrorReviewYearType = "RECEIVE_PUT_ECONOMIC_PC_CLIENT_ERROR_REVIEW_YEAR";

const initialState = {
	editEconomicPcClientVisible: false,
	isDeleting: false,
	isLoadedUserId: null,
	isLoading: false,
	isPosting: false,
	isPutting: false,
	newEconomicPcClientVisible: false,
	editingEconomicPcClientId: null,
	clients: []
};

export const actionCreators = {
	closeEconomicPcClient: () => ({ type: closeEconomicPcClientType }),
	editEconomicPcClient: (data: any) => ({ type: editEconomicPcClientType, economicPcClientId: data.economicPcClientId }),
	showNewEconomicPcClient: (visible = true) => ({ type: showNewEconomicPcClientType, visible: visible }),
	closeEconomicPcClientReviewYear: () => ({ type: closeEconomicPcClientReviewYearType }),
	editEconomicPcClientReviewYear: (data: any) => ({ type: editEconomicPcClientReviewYearType, economicPcClientId: data.economicPcClientId }),
	showNewEconomicPcClientReviewYear: (visible = true) => ({ type: showNewEconomicPcClientReviewYearType, visible: visible }),
	loadEconomicPcClients: () => async (dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		if (state.economicPcClients.isLoadedUserId === userId) {			
			return;
		}
		if (state.economicPcClients.isLoading) {			
			return;
		}

		dispatch({ type: requestEconomicPcClientsType });
		const url = `${baseUrl}api/EconomicPcClients/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",				
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`,
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveEconomicPcClientsErrorType, response: response });
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveEconomicPcClientsType, data: responseData, userId: userId });
		} catch (exception) {
			dispatch({ type: receiveEconomicPcClientsErrorType, exception: exception });
		}
	},
	addEconomicPcClient: (data: any, isReviewYear: boolean) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicPcClients.isPosting) {			
			return;
		}		
		const { userId } = state.auth;
		const data3 = { ...data, userId: userId };

		if (isReviewYear) {
			dispatch({ type: requestPostEconomicPcClientReviewYearType, data: data });
		} else {
			dispatch({ type: requestPostEconomicPcClientType, data: data });
		}
		
		const url = `${baseUrl}api/EconomicPcClients`;
		try {
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data3),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`,
					},
					credentials: "same-origin"
				});
			if (!response.ok) {

				if (isReviewYear) {
					dispatch({ type: receivePostEconomicPcClientErrorReviewYearType, response: response });
				} else {
					dispatch({ type: receivePostEconomicPcClientErrorType, response: response });
				}
				
				dispatch(errorActionCreators.addError("An error occurred while adding an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();

			if (isReviewYear) {
				dispatch({ type: receivePostEconomicPcClientReviewYearType, data: responseData });
			} else {
				dispatch({ type: receivePostEconomicPcClientType, data: responseData });
			}
			
		} catch (exception) {

			if (isReviewYear) {
				dispatch({ type: receivePostEconomicPcClientErrorReviewYearType, exception: exception });
			} else {
				dispatch({ type: receivePostEconomicPcClientErrorType, exception: exception });
			}
			
			dispatch(errorActionCreators.addError("An error occurred while adding an economic PC client.", exception, null));
		}
	},
	removeEconomicPcClient: (data: any) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicPcClients.isDeleting) {			
			return;
		}

		dispatch({ type: requestDeleteEconomicPcClientType, data: data });
		const url = `${baseUrl}api/EconomicPcClients/${data}`;
		try {
			const response = await fetch(url,
				{
					method: "DELETE",
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receiveDeleteEconomicPcClientErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while removing an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveDeleteEconomicPcClientType, data: responseData });
		} catch (exception) {
			dispatch({ type: receiveDeleteEconomicPcClientErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while removing an economic PC client.", exception, null));
		}
	},
	updateEconomicPcClient: (data: any, isReviewYear: boolean) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.economicPcClients.isPutting) {			
			return;
		}

		if (isReviewYear) {
			dispatch({ type: requestPutEconomicPcClientReviewYearType, data: data });
		} else {
			dispatch({ type: requestPutEconomicPcClientType, data: data });
		}
		
		const url = `${baseUrl}api/EconomicPcClients/${data.economicPcClientId}`;
		try {
			const response = await fetch(url,
				{
					method: "PUT",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {

				if (isReviewYear) {
					dispatch({ type: receivePutEconomicPcClientErrorReviewYearType, response: response });
				} else {
					dispatch({ type: receivePutEconomicPcClientErrorType, response: response });
				}
				
				dispatch(errorActionCreators.addError("An error occurred while updating an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();

			if (isReviewYear) {
				dispatch({ type: receivePutEconomicPcClientReviewYearType, data: responseData });
			} else {
				dispatch({ type: receivePutEconomicPcClientType, data: responseData });
			}
			
		} catch (exception) {

			if (isReviewYear) {
				dispatch({ type: receivePutEconomicPcClientErrorReviewYearType, exception: exception });
			} else {
				dispatch({ type: receivePutEconomicPcClientErrorType, exception: exception });
			}
			
			dispatch(errorActionCreators.addError("An error occurred while updating an economic PC client.", exception, null));
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === closeEconomicPcClientType) {
		return {
			...state,
			editEconomicPcClientVisible: false,
			newEconomicPcClientVisible: false
		};
	}

	if (action.type === showNewEconomicPcClientType) {
		return {
			...state,
			newEconomicPcClientVisible: action.visible
		};
	}

	if (action.type === closeEconomicPcClientReviewYearType) {
		return {
			...state,
			editEconomicPcClientReviewYearVisible: false,
			newEconomicPcClientReviewYearVisible: false
		};
	}

	if (action.type === showNewEconomicPcClientReviewYearType) {
		return {
			...state,
			newEconomicPcClientReviewYearVisible: action.visible
		};
	}

	if (action.type === requestPostEconomicPcClientType) {
		return {
			...state,
			isPosting: true,
			newEconomicPcClientVisible: false
		};
	}

	if (action.type === receivePostEconomicPcClientType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicPcClientErrorType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === requestPostEconomicPcClientReviewYearType) {
		return {
			...state,
			isPosting: true,
			newEconomicPcClientReviewYearVisible: false
		};
	}

	if (action.type === receivePostEconomicPcClientReviewYearType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicPcClientErrorReviewYearType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === requestPutEconomicPcClientType) {
		return {
			...state,
			isPutting: true,
			editEconomicPcClientVisible: false
		};
	}

	if (action.type === receivePutEconomicPcClientType) {		
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicPcClientId === action.data.economicPcClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicPcClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestPutEconomicPcClientReviewYearType) {
		return {
			...state,
			isPutting: true,
			editEconomicPcClientReviewYearVisible: false
		};
	}

	if (action.type === receivePutEconomicPcClientReviewYearType) {		
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicPcClientId === action.data.economicPcClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicPcClientErrorReviewYearType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestDeleteEconomicPcClientType) {
		return {
			...state,
			isDeleting: true
		};
	}

	if (action.type === receiveDeleteEconomicPcClientType) {
		return {
			...state,
			isDeleting: false,
			clients: state.clients.filter((client: any) => client.economicPcClientId !== action.data.economicPcClientId)
		};
	}

	if (action.type === receiveDeleteEconomicPcClientErrorType) {
		return {
			...state,
			isDeleting: false
		};
	}

	if (action.type === requestPutEconomicPcClientType) {
		return {
			...state,
			isPutting: true
		};
	}

	if (action.type === receivePutEconomicPcClientType) {		
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicPcClientId === action.data.economicPcClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicPcClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === editEconomicPcClientType) {
		return {
			...state,
			editEconomicPcClientVisible: true,
			editingEconomicPcClientId: action.economicPcClientId
		};
	}

	if (action.type === editEconomicPcClientReviewYearType) {
		return {
			...state,
			editEconomicPcClientReviewYearVisible: true,
			editingEconomicPcClientId: action.economicPcClientId
		};
	}

	if (action.type === requestEconomicPcClientsType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveEconomicPcClientsType) {		
		return {
			...state,
			isLoadedUserId: action.userId,
			isLoading: false,
			clients: action.data
		};
	}

	if (action.type === receiveEconomicPcClientsErrorType) {		
		return {
			...state,
			isLoadedUserId: null,
			isLoading: false,
			clients: []
		};
	}

	return state;
};