import { IEconomicHistory } from "../../models/IEconomicHistory";
import { baseUrl } from "../../utils/authConfig";

const requestEconomicHistoryType = 'REQUEST_ECONOMIC_HISTORY';
const receiveEconomicHistoryType = 'RECEIVE_ECONOMIC_HISTORY';
const receiveEconomicHistoryErrorType = 'RECEIVE_ECONOMIC_HISTORY_ERROR';

const initialState = {
	isLoading: false,
	data: []
};

function convertPercentagesFromServer(data: Array<IEconomicHistory>): any {
  const result: Array<IEconomicHistory> = [];
  for(var i=0; i < data.length; i++){
    var item = data[i];
    if (item.crmDm) item.crmDm = item.crmDm * 100;
	  if (item.maDm) item.maDm = item.maDm * 100;
    result.push(item);
  }	
	return result;
}

export const actionCreators = {
	requestEconomicHistory: () => async (dispatch: any, getState: any) => {
		var state = getState();
		const { userId } = state.auth;
    
		if (state.economicHistory.data.userId === userId) {
			return;
		}
		if (state.economicHistory.isLoading) {			
			return;
		}

		dispatch({ type: requestEconomicHistoryType });
		const url = `${baseUrl}api/EconomicHistories/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});			
			if (!response.ok) {
				dispatch({ type: receiveEconomicHistoryErrorType, response: response });
				return;
			}
			const data = await response.json();
			const data2 = convertPercentagesFromServer(data);
			dispatch({ type: receiveEconomicHistoryType, data: data2 });
		} catch (exception) {
			dispatch({ type: receiveEconomicHistoryErrorType, exception: exception });
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestEconomicHistoryType) {
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveEconomicHistoryType) {
		return {
			...state,
			isLoading: false,
			data: action.data
		};
	}

	if (action.type === receiveEconomicHistoryErrorType) {
		return {
			...state,
			isLoading: false,
			data: []
		};
	}

	return state;
};