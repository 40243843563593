import { IEconomicScClient } from "../../models/IEconomicScClient";
import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const closeEconomicScClientType = "CLOSE_ECONOMIC_SC_CLIENT";
const editEconomicScClientType = "EDIT_ECONOMIC_SC_CLIENT";
const requestEconomicScClientsType = "REQUEST_ECONOMIC_SC_CLIENTS";
const receiveEconomicScClientsType = "RECEIVE_ECONOMIC_SC_CLIENTS";
const receiveEconomicScClientsErrorType = "RECEIVE_ECONOMIC_SC_CLIENTS_ERROR";
const requestPostEconomicScClientType = "REQUEST_POST_ECONOMIC_SC_CLIENT";
const receivePostEconomicScClientType = "RECEIVE_POST_ECONOMIC_SC_CLIENT";
const receivePostEconomicScClientErrorType = "RECEIVE_POST_ECONOMIC_SC_CLIENT_ERROR";
const requestPutEconomicScClientType = "REQUEST_PUT_ECONOMIC_SC_CLIENT";
const receivePutEconomicScClientType = "RECEIVE_PUT_ECONOMIC_SC_CLIENT";
const receivePutEconomicScClientErrorType = "RECEIVE_PUT_ECONOMIC_SC_CLIENT_ERROR";
const requestDeleteEconomicScClientType = "REQUEST_DELETE_ECONOMIC_SC_CLIENT";
const receiveDeleteEconomicScClientType = "RECEIVE_DELETE_ECONOMIC_SC_CLIENT";
const receiveDeleteEconomicScClientErrorType = "RECEIVE_DELETE_ECONOMIC_SC_CLIENT_ERROR";
const showNewEconomicScClientType = "SHOW_NEW_ECONOMIC_SC_CLIENT";
const showNewEconomicScClientReviewYearType = "SHOW_NEW_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const requestPostEconomicScClientReviewYearType = "REQUEST_POST_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const receivePostEconomicScClientReviewYearType = "RECEIVE_POST_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const receivePostEconomicScClientErrorReviewYearType = "RECEIVE_POST_ECONOMIC_SC_CLIENT_ERROR_REVIEW_YEAR";
const closeEconomicScClientReviewYearType = "CLOSE_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const editEconomicScClientReviewYearType = "EDIT_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const requestPutEconomicScClientReviewYearType = "REQUEST_PUT_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const receivePutEconomicScClientReviewYearType = "RECEIVE_PUT_ECONOMIC_SC_CLIENT_REVIEW_YEAR";
const receivePutEconomicScClientErrorReviewYearType = "RECEIVE_PUT_ECONOMIC_SC_CLIENT_ERROR_REVIEW_YEAR";

const initialState = {
	editEconomicScClientVisible: false,
	editEconomicScClientReviewYearVisible: false,
	isDeleting: false,
	isLoadedUserId: null,
	isLoading: false,
	isPosting: false,
	isPutting: false,
	newEconomicScClientVisible: false,
	newEconomicScClientReviewYearVisible: false,
	editingEconomicScClientId: null,
	clients: []
};

export const actionCreators = {
	closeEconomicScClient: () => ({ type: closeEconomicScClientType }),
	closeEconomicScClientReviewYear: () => ({ type: closeEconomicScClientReviewYearType }),
	editEconomicScClient: (data: any) => ({ type: editEconomicScClientType, economicScClientId: data.economicScClientId }),
	editEconomicScClientReviewYear: (data: any) => ({ type: editEconomicScClientReviewYearType, economicScClientId: data.economicScClientId }),
	showNewEconomicScClient: (visible = true) => ({ type: showNewEconomicScClientType, visible: visible }),
	showNewEconomicScClientReviewYear: (visible = true) => ({ type: showNewEconomicScClientReviewYearType, visible: visible }),
	loadEconomicScClients: () => async (dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		if (state.economicScClients.isLoadedUserId === userId) {
			return;
		}
		if (state.economicScClients.isLoading) {
			return;
		}

		dispatch({ type: requestEconomicScClientsType });
		const url = `${baseUrl}api/EconomicScClients/${userId}`;
		try {
			const response = await fetch(url, {
				method: "GET",				
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`,
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveEconomicScClientsErrorType, response: response });
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveEconomicScClientsType, data: responseData, userId: userId });
		} catch (exception) {
			dispatch({ type: receiveEconomicScClientsErrorType, exception: exception });
		}
	},
	addEconomicScClient: (data: any, isReviewYear: boolean) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.economicScClients.isPosting) {			
			return;
		}
		const { userId } = state.auth;
		const data3 = { ...data, userId: userId };
		if (isReviewYear) {
			dispatch({ type: requestPostEconomicScClientReviewYearType, data: data });
		} else {
			dispatch({ type: requestPostEconomicScClientType, data: data });
		}
		
		const url = `${baseUrl}api/EconomicScClients`;
		try {
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data3),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`,
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				if (isReviewYear) {
					dispatch({ type: receivePostEconomicScClientErrorReviewYearType, response: response });
				} else {
					dispatch({ type: receivePostEconomicScClientErrorType, response: response });
				}
				dispatch(errorActionCreators.addError("An error occurred while adding an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();
			if (isReviewYear) {
				dispatch({ type: receivePostEconomicScClientReviewYearType, data: responseData });
			} else {
				dispatch({ type: receivePostEconomicScClientType, data: responseData });
			}
		} catch (exception) {
			if (isReviewYear) {
				dispatch({ type: receivePostEconomicScClientErrorReviewYearType, exception: exception });
			} else {
				dispatch({ type: receivePostEconomicScClientErrorType, exception: exception });
			}
			dispatch(errorActionCreators.addError("An error occurred while adding an economic PC client.", exception, null));
		}
	},
	removeEconomicScClient: (data: any) => async (dispatch: any, getState: any) => {		
		const state = getState();
		if (state.economicScClients.isDeleting) {
			return;
		}

		dispatch({ type: requestDeleteEconomicScClientType, data: data });
		const url = `${baseUrl}api/EconomicScClients/${data}`;
		try {
			const response = await fetch(url,
				{
					method: "DELETE",
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`,
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receiveDeleteEconomicScClientErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while removing an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveDeleteEconomicScClientType, data: responseData });
		} catch (exception) {
			dispatch({ type: receiveDeleteEconomicScClientErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while removing an economic PC client.", exception, null));
		}
	},
	updateEconomicScClient: (data: IEconomicScClient, isReviewYear: boolean) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.economicScClients.isPutting) {			
			return;
		}

		if (isReviewYear) {
			dispatch({ type: requestPutEconomicScClientReviewYearType, data: data });
		} else {
			dispatch({ type: requestPutEconomicScClientType, data: data });
		}
		
		const url = `${baseUrl}api/EconomicScClients/${data.economicScClientId}`;
		try {
			const response = await fetch(url,
				{
					method: "PUT",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`,
					},
					credentials: "same-origin"
				});
			if (!response.ok) {

				if (isReviewYear) {
					dispatch({ type: receivePutEconomicScClientErrorReviewYearType, response: response });
				} else {
					dispatch({ type: receivePutEconomicScClientErrorType, response: response });
				}
				
				dispatch(errorActionCreators.addError("An error occurred while updating an economic PC client.", null, response));
				return;
			}
			const responseData = await response.json();

			if (isReviewYear) {
				dispatch({ type: receivePutEconomicScClientReviewYearType, data: responseData });
			} else {
				dispatch({ type: receivePutEconomicScClientType, data: responseData });
			}
			
		} catch (exception) {

			if (isReviewYear) {
				dispatch({ type: receivePutEconomicScClientErrorReviewYearType, exception: exception });
			} else {
				dispatch({ type: receivePutEconomicScClientErrorType, exception: exception });
			}
			
			dispatch(errorActionCreators.addError("An error occurred while updating an economic PC client.", exception, null));
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === closeEconomicScClientType) {
		return {
			...state,
			editEconomicScClientVisible: false,
			newEconomicScClientVisible: false
		};
	}

	if (action.type === closeEconomicScClientReviewYearType) {
		return {
			...state,
			editEconomicScClientReviewYearVisible: false,
			newEconomicScClientReviewYearVisible: false
		};
	}

	if (action.type === showNewEconomicScClientType) {
		return {
			...state,
			newEconomicScClientVisible: action.visible
		};
	}

	if (action.type === showNewEconomicScClientReviewYearType) {
		return {
			...state,
			newEconomicScClientReviewYearVisible: action.visible
		};
	}

	if (action.type === requestPostEconomicScClientType) {
		return {
			...state,
			isPosting: true,
			newEconomicScClientVisible: false
		};
	}

	if (action.type === requestPostEconomicScClientReviewYearType) {
		return {
			...state,
			isPosting: true,
			newEconomicScClientReviewYearVisible: false
		};
	}

	if (action.type === receivePostEconomicScClientType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicScClientReviewYearType) {
		return {
			...state,
			isPosting: false,
			clients: [
				...state.clients,
				action.data
			]
		};
	}

	if (action.type === receivePostEconomicScClientErrorType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === receivePostEconomicScClientErrorReviewYearType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === requestPutEconomicScClientType) {
		return {
			...state,
			isPutting: true,
			editEconomicScClientVisible: false
		};
	}

	if (action.type === receivePutEconomicScClientType) {
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client : any) =>
				client.economicScClientId === action.data.economicScClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicScClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestPutEconomicScClientReviewYearType) {
		return {
			...state,
			isPutting: true,
			editEconomicScClientReviewYearVisible: false
		};
	}

	if (action.type === receivePutEconomicScClientReviewYearType) {
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client : any) =>
				client.economicScClientId === action.data.economicScClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicScClientErrorReviewYearType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestDeleteEconomicScClientType) {
		return {
			...state,
			isDeleting: true
		};
	}

	if (action.type === receiveDeleteEconomicScClientType) {		
		return {
			...state,
			isDeleting: false,
			clients: state.clients.filter((client: any) => client.economicScClientId !== action.data.economicScClientId)
		};
	}

	if (action.type === receiveDeleteEconomicScClientErrorType) {
		return {
			...state,
			isDeleting: false
		};
	}

	if (action.type === requestPutEconomicScClientType) {
		return {
			...state,
			isPutting: true
		};
	}

	if (action.type === receivePutEconomicScClientType) {
		return {
			...state,
			isPutting: false,
			clients: state.clients.map((client: any) =>
				client.economicScClientId === action.data.economicScClientId
				? { ...client, ...action.data }
				: client)
		};
	}

	if (action.type === receivePutEconomicScClientErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === editEconomicScClientType) {		
		return {
			...state,
			editEconomicScClientVisible: true,
			editingEconomicScClientId: action.economicScClientId
		};
	}

	if (action.type === editEconomicScClientReviewYearType) {		
		return {
			...state,
			editEconomicScClientReviewYearVisible: true,
			editingEconomicScClientId: action.economicScClientId
		};
	}

	if (action.type === requestEconomicScClientsType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveEconomicScClientsType) {		
		return {
			...state,
			isLoadedUserId: action.userId,
			isLoading: false,
			clients: action.data
		};
	}

	if (action.type === receiveEconomicScClientsErrorType) {
		return {
			...state,
			isLoadedUserId: null,
			isLoading: false,
			clients: []
		};
	}

	return state;
};