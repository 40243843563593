import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import Select from "react-select";
import PersonIcon from "@mui/icons-material/Person";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { actionCreators as adminProxyUserActionCreators } from "../../../store/reducers/AdminProxyUsers";
import AdminTable from "../AdminTable/AdminTable";
import "../admin.scss";
import { connect } from "react-redux";
import CheckboxSelection from "../AdminTable/CheckboxSelection/CheckboxSelection";
import { bindActionCreators } from "redux";
import { IProxyUsersModel } from "../../../models/IProxyUsersModel";
import Format from "../../../utils/Format";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProxyUsers: React.FunctionComponent = (props: any): JSX.Element => {
  const headers = useMemo<ColumnDef<IProxyUsersModel>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <div style={{ float: "left" }}>
            <CheckboxSelection
              {...{
                checked: table.getIsAllRowsSelected(),
                label: table.getIsAllRowsSelected()
                  ? "Deselect All"
                  : "Select All",
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <CheckboxSelection
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        maxSize: 35,
      },
      {
        accessorKey: "userLastNameFirst",
        header: () => "Proxy User",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "proxyUserLastNameFirst",
        header: () => "Assigned User",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "enableProxyPlanningGoal",
        header: () => "Allow Proxies to Enter Planning Goal",
        footer: (props) => props.column.id,
        cell: (data: any) => <span>{data.getValue() ? "Yes" : "No"}</span>,
        type: "list",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      {
        accessorKey: "enableTeamWorkReviewAndPlanning",
        header: () => "Enable Teamwork & Collaboration In Review and Planning",
        footer: (props) => props.column.id,
        cell: (data: any) => <span>{data.getValue() ? "Yes" : "No"}</span>,
        type: "list",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      {
        accessorKey: "enableReviewPartnerComments",
        header: () => "Enable In Review Partner Comments",
        footer: (props) => props.column.id,
        cell: (data: any) => <span>{data.getValue() ? "Yes" : "No"}</span>,
        type: "list",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
      {
        accessorKey: "enableSbuLeader",
        header: () => "SBU Leader Recommendations",
        footer: (props) => props.column.id,
        cell: (data: any) => <span>{data.getValue() ? "Yes" : "No"}</span>,
        type: "list",
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
        ],
      },
    ],
    []
  );

  const defaultPhaseSelection = {
    enablePlanningGoal: false,
    enableProxyPlanningGoal: false,
    enableTeamWorkReviewAndPlanning: false,
    enableReviewPartnerComments: false,
    enableSbuLeader: false,
  };

  const [selectedProxyUser, setProxyUser] = useState({}) as any;
  const [selectedAssignedUsers, setAssignedUsers] = useState([]) as any;
  const [phaseSelection, setPhaseSelection] = useState(
    defaultPhaseSelection
  ) as any;
  const [proxyUsersToBeRemoved, setProxyUserToBeRemoved] = useState([]);

  useEffect(() => {
    if (props.auth.accessToken) {
      props.adminProxyUserActions.loadUsers();
      props.adminProxyUserActions.loadProxyUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const handleProxyUsers = (selectedOptions: any) => {
    setProxyUser(selectedOptions);
  };

  const handleAssignedUsers = (selectedOptions: any) => {
    setAssignedUsers(selectedOptions);
  };

  const handleProxyUsersRowSelection = (selectedRows: any) => {
    setProxyUserToBeRemoved(selectedRows);
  };

  const handleAddProxyUser = () => {
    if (
      !(
        phaseSelection.enableProxyPlanningGoal ||
        phaseSelection.enableReviewPartnerComments ||
        phaseSelection.enableSbuLeader ||
        phaseSelection.enableTeamWorkReviewAndPlanning
      )
    ) {
      toast.error("Please select at least one checkbox for access", {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    const dataToSend = {
      ProxyUserNtIds: selectedAssignedUsers.map((item: any) => item.ntId),
      ...phaseSelection,
    };
    props.adminProxyUserActions.updateProxyUser(
      selectedProxyUser.userId,
      dataToSend
    );

    setProxyUser({});
    setAssignedUsers([]);
    setPhaseSelection(defaultPhaseSelection);
  };

  const handleRemoveProxyUser = () => {
    const dataToSend = proxyUsersToBeRemoved.map((item: any) => {
      return {
        userNtId: item.original.userNtId,
        proxyUserNtId: item.original.proxyUserNtId,
      };
    });
    props.adminProxyUserActions.removeProxyUser(dataToSend);
  };

  const defaultSorted = [{ id: "userLastNameFirst", desc: false }];

  const modifyProxyUserSelectOption = () => {
    const options = [...props.usersListData] as any[];
    options.sort((a: any, b: any) => {
      var aName = a.lastNameFirst.toLowerCase();
      var bName = b.lastNameFirst.toLowerCase();
      return aName.localeCompare(bName);
    });

    options.forEach((option: any) => {
      const { lastNameFirst, ntId } = option;
      option.label = lastNameFirst;
      option.value = ntId;
      return option;
    });
    
    return options;
  };

  const handleCheckbox = (event: any) => {
    const { name, checked } = event.target;
    setPhaseSelection({
      ...phaseSelection,
      [name]: checked,
    });
  };

  const enableAddProxyUserButton = selectedProxyUser?.userId != null || selectedAssignedUsers?.length > 0;

  const assignedUserOptions = modifyProxyUserSelectOption().filter(
    (user) => user.isAttorney && user.userId !== (selectedProxyUser && selectedProxyUser.userId)
  );

  const proxyUserOptions = modifyProxyUserSelectOption().filter((el: any) => {
    return el.isAttorney && !selectedAssignedUsers.some((f: any) => {
      return f.userId === el.userId;
    });
  });

  return (
    <Container maxWidth={false} sx={{ my: 2 }} className="admin container">
      <Grid item className="header">
        <Typography variant="h5">Proxy Users</Typography>
        <Typography variant="subtitle1" className="fs-14">
          Here is the list of proxy users :
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Card>
            <CardHeader
              title={
                <Typography
                  className="title"
                  sx={{ fontSize: 16, fontWeight: 700 }}
                >
                  Add Proxy User
                </Typography>
              }
              className="card-header"
            />
            <CardContent className="card-content">
              <Grid container item sx={{ p: 0.5 }} xs={12}>
                <Grid item sx={{ p: 0.5 }} xs={6}>
                  <div
                    className="react-select-container"
                  >
                    <InputLabel className="label">Proxy User:</InputLabel>
                  </div>
                  <Select
                    options={proxyUserOptions}
                    onChange={handleProxyUsers}
                    isClearable
                    closeMenuOnSelect={true}
                    value={
                      selectedProxyUser &&
                      Object.keys(selectedProxyUser).length > 0
                        ? selectedProxyUser
                        : "Select Proxy User"
                    }
                    placeholder="Select Proxy User"
                    className="user-react-select"
                  />
                </Grid>
                <Grid item sx={{ p: 0.5 }} xs={6}>
                  <div
                    className="react-select-container"
                  >
                    <InputLabel className="label">
                      Assigned Partners:
                    </InputLabel>
                  </div>
                  <Select
                    options={assignedUserOptions}
                    onChange={handleAssignedUsers}
                    isClearable
                    isMulti
                    value={selectedAssignedUsers}
                    closeMenuOnSelect={false}
                    placeholder="Select Partners"
                    className="user-react-select"
                  />
                </Grid>
                <Grid item sx={{ p: 0.5 }} xs={6}>
                  <Card>
                    <CardHeader
                      title={
                        <Typography
                          className="title"
                          sx={{ fontSize: 16, fontWeight: 700 }}
                        >
                          Can Edit
                        </Typography>
                      }
                      className="card-header"
                      avatar={
                        <Avatar
                          sx={{
                            backgroundColor: "transparent",
                            margin: "0px !important",
                          }}
                        >
                          <BorderColorIcon
                            sx={{ color: "white", fontSize: "18px" }}
                          />
                        </Avatar>
                      }
                    />
                    <CardContent className="card-content">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ py: 0.5 }}
                              name="enableProxyPlanningGoal"
                              checked={Format.formatCheckboxInput(
                                phaseSelection.enableProxyPlanningGoal
                              )}
                              onChange={handleCheckbox}
                            />
                          }
                          label="Allow Proxies to Enter Planning Goal"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ py: 0.5 }}
                              name="enableTeamWorkReviewAndPlanning"
                              checked={Format.formatCheckboxInput(
                                phaseSelection.enableTeamWorkReviewAndPlanning
                              )}
                              onChange={handleCheckbox}
                            />
                          }
                          label="Enable Teamwork & Collaboration In Review and Planning"
                        />
                      </FormGroup>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sx={{ p: 0.5 }} xs={6}>
                  <Card>
                    <CardHeader
                      title={
                        <Typography
                          className="title"
                          sx={{ fontSize: 16, fontWeight: 700 }}
                        >
                          {" "}
                          Stages{" "}
                        </Typography>
                      }
                      className="card-header"
                      avatar={
                        <Avatar
                          sx={{
                            backgroundColor: "transparent",
                            margin: "0px !important",
                          }}
                        >
                          {" "}
                          <BorderColorIcon
                            sx={{ color: "white", fontSize: "18px" }}
                          />
                        </Avatar>
                      }
                    />
                    <CardContent className="card-content">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ py: 0.5 }}
                              name="enableReviewPartnerComments"
                              checked={Format.formatCheckboxInput(
                                phaseSelection.enableReviewPartnerComments
                              )}
                              onChange={handleCheckbox}
                            />
                          }
                          label="Enable In Review Partner Comments"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ py: 0.5 }}
                              name="enableSbuLeader"
                              checked={Format.formatCheckboxInput(
                                phaseSelection.enableSbuLeader
                              )}
                              onChange={handleCheckbox}
                            />
                          }
                          label="SBU Leader Recommendations"
                        />
                      </FormGroup>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="medium"
                    className="addUserBtn"
                    onClick={handleAddProxyUser}
                    disabled={!enableAddProxyUserButton}
                    sx={{ mx: 1, mt: 1, mb: 0.5, float: "right" }}
                    variant="contained"
                    startIcon={<PersonIcon />}
                  >
                    Add Proxy User
                  </Button>
                  <ToastContainer />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ py: 1 }}>
          <Button
            size="medium"
            className="removeUserBtn"
            onClick={handleRemoveProxyUser}
            disabled={Object.keys(proxyUsersToBeRemoved).length <= 0}
            sx={{ mx: 1, mb: 0.5, float: "left" }}
            variant="contained"
            startIcon={<PersonIcon />}
          >
            Remove
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <AdminTable
              {...{
                data: props.proxyUserListData,
                columns: headers,
                showFilter: false,
                showPagination: true,
                defaultSorted,
                rowClickable: false,
                canSelectRow: true,
                handleRowSelection: handleProxyUsersRowSelection,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    proxyUserListData: state.adminProxyUser.proxyUserListData,
    usersListData: state.adminProxyUser.usersListData,
    isLoading: state.adminProxyUser.isLoading,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    adminProxyUserActions: bindActionCreators(
      adminProxyUserActionCreators,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProxyUsers);
