import { useEffect, useState } from "react";
import "./textarea.scss";

interface TextAreaProps
  extends Omit<React.InputHTMLAttributes<HTMLElement>, "value"> {
  disabled?: boolean;
  placeholder?: string;  
  value?: string;
  label?: string;
  name?: string;
  limit: number;
  rows?: number;
  className?: string;
  isRequired?: boolean;
  isForceInValid?: boolean | null;
}

const TextArea: React.FunctionComponent<TextAreaProps> = ({
  disabled,
  value,
  placeholder,  
  onChange,
  label,
  name,
  limit,
  rows,
  className,
  isRequired,
  isForceInValid,
}): React.ReactElement => {
  const [displayText, setDisplayText] = useState("");
  const [isTextValid, setIsTextValid] = useState<boolean | null>(null);

  useEffect(() => {
    setDisplayText(value || "");
    setIsTextValid(
      !value ? null : value && value.length <= limit ? true : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleTextChange = (event: any) => {
    const text = event.target.value;
    setIsTextValid(
      text.length === 0 ? null : text.length <= limit ? true : false
    );

    if (event.target.value.length > 1100) return;
    setDisplayText(text);
    if (!onChange) return;
    onChange(event);
  };

  return (
    <div className={`text-area ${className}`}>
      <h6
        className={`custom-label ${
          isTextValid === null || isTextValid === undefined
            ? ""
            : isTextValid
            ? "isValid"
            : "isInValid"
        }`}
      >
        {label}
        {isRequired && <span className="text-danger">&nbsp;&nbsp;*</span>}
      </h6>
      <textarea
        disabled={disabled}
        placeholder={placeholder}
        value={displayText}
        name={name}
        rows={rows ? rows : 4}
        onChange={handleTextChange}
        className={`${
          isForceInValid
            ? "isInValid"
            : isTextValid === null || isTextValid === undefined
            ? "inputStyle"
            : isTextValid
            ? "isValid"
            : "isInValid"
        }`}
      />
      {displayText ? (
        <p className="helperText">
          {" "}
          {limit - displayText.length} characters left{" "}
        </p>
      ) : (
        <p className="helperText"> {limit} characters left </p>
      )}
      {!isTextValid && isTextValid !== null ? (
        <p className="helperTextError"> Only {limit} characters are allowed </p>
      ) : null}
    </div>
  );
};

export default TextArea;
