import { Component } from 'react';
import { connect } from 'react-redux';
import AuthProvider from "../utils/authProvider";
import App from '../App';

import {
  updateAccount,
  updateError,
  updateToken,
  updateIdentity
} from '../store/reducers/Auth';

import {
  getProfile
} from '../store/graph/graphServiceActions';


class AppContainer extends Component {
  render() {
    return (
      <App {...this.props} />
    );
  }
}

const mapStateToProps = (state: any) => state;

const mapDispatchToProps = (dispatch : any) => ({
  updateAccount: (account: any) => {    
    dispatch({type: updateAccount(account)})
  },
  updateIdentity: (identity: any) => {    
    dispatch(updateIdentity(identity))
  },
  updateError: (error: any) => {
    dispatch(updateError(error))
  },
  updateToken: (token: any) => {
    dispatch(updateToken(token))
  },
  getProfile: () => {
    dispatch(getProfile());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider(AppContainer));