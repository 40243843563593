import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

interface ProgressBarProps {
  open: boolean;
  onClose(): void;
  onOk(): void;
}

const Submit: React.FunctionComponent<ProgressBarProps> = ({
  open,
  onClose,
  onOk,
}): JSX.Element => {
  const handleOk = () => {
    onOk();
  };

  return (
    <Dialog open={open}
            onClose={onClose}
            scroll="paper"
            maxWidth="xs">
      <DialogTitle id="scroll-dialog-title" sx={{ textAlign: "center", pb: 0 }}>
        <ThumbUpIcon sx={{ fontSize: 150, color: "#0033a0" }} />
      </DialogTitle>
      <Typography sx={{ textAlign: "center", fontSize: 28, color: "#0033a0", pb: 2 }}>
        Success!
      </Typography>
      <DialogContent>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Typography variant="body2" sx={{fontSize: 16, fontWeight: 700, color: '#000', textAlign: 'center'}}>
            Your Partner Contribution Report has been successfully submitted.
          </Typography>
          <Typography variant="body2" sx={{ pt: 2, fontSize: 16, fontWeight: 700, color: '#000', textAlign: 'center' }}>
            You may make additional changes to your report anytime before the
            deadline for submitting reports.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="theme-button" onClick={() => handleOk()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Submit;
