import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Chip,
  Box,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import { IEconomic } from "../../models/IEconomic";
import { IEconomicCrmClient } from "../../models/IEconomicCrmClient";
import Format from "../../utils/Format";
import Utils from "../../utils/Utils";
import Input from "../common/input/Input";
import CrmClientCard from "./CrmClientCard";
import { actionCreators as economicCrmActionCreators } from "../../store/reducers/EconomicCrmClients";
import { useEffect } from "react";
import EconomicCrmClientAddEdit from "./EconomicCrmClientAddEdit";
import {
  canEditSelector,
  reviewYearSelector,
} from "../../store/reducers/Settings";
import EconomicHistory from "./EconomicHistory";
import { IEconomicHistory } from "../../models/IEconomicHistory";

interface EconomicCrmClientProps extends PropsFromRedux {
  yearText: string;
  year?: number;
  disabled?: boolean;
  canShowClientsSection?: boolean;
  economic: IEconomic;
  onChange?: (arg0: any) => void;
  economicHistories?: Array<IEconomicHistory>;
  isReviewYear: boolean;
}

const EconomicCrmClient: React.FunctionComponent<EconomicCrmClientProps> = (
  props
): JSX.Element => {
  useEffect(() => {
    if (props.auth.accessToken) {
      props.economicCrmActions.loadEconomicCrmClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken, props.auth.userId]);

  const handleOnChange = (event: any) => {
    if (props.disabled) return;

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const clientsForYear = props.economicCrmClients.clients
    .filter((client: IEconomicCrmClient) => client.year === props.year)
    .map((client: IEconomicCrmClient) => (
      <CrmClientCard
        key={client.economicCrmClientId.toString()}
        client={client}
        disabled={props.disabled}
        edit={props.economicCrmActions.editEconomicCrmClient}
        remove={props.economicCrmActions.removeEconomicCrmClient}
      />
    ));

  const showCrmClients = clientsForYear && clientsForYear.length > 0;

  let economicCrmClient = undefined;
  if (props.economicCrmClients.editEconomicCrmClientVisible) {
    economicCrmClient = props.economicCrmClients.clients.find(
      (client: IEconomicCrmClient) =>
        client.economicCrmClientId ===
        props.economicCrmClients.editingEconomicCrmClientId
    );
  }

  return (
    <Grid>
      <Grid item>
        <Chip label={props.yearText} className="header-chip" />
      </Grid>
      <Grid container className="p-1">
        <Grid container>
          <EconomicHistory
            label="Revenue Dollars Collected:"
            section="crm"
            isRevenue
            economicHistories={props.economicHistories}
          />
          <FormControl fullWidth sx={{ margin: 0 }}>
            <Input
              placeholder="Revenue Target"
              value={Format.formatCurrencyInput(props.economic.crmRevenue)}
              icon="attach_money"
              fullWidth
              disabled={props.disabled}
              type="number"
              name="crmRevenue"
              onChange={handleOnChange}
              valid={Utils.getValidationStateRevenue(props.economic.crmRevenue)}
            />
          </FormControl>
        </Grid>
        <Grid container>
          <EconomicHistory
            label="Direct Margin:"
            section="crm"
            isDm
            economicHistories={props.economicHistories}
          />
          <FormControl fullWidth>
            <Input
              placeholder="Direct Margin"
              value={Format.formatPercentageInput(props.economic.crmDm)}
              endIcon="%"
              fullWidth
              disabled={props.disabled}
              type="number"
              name="crmDm"
              onChange={handleOnChange}
              valid={Utils.getValidationStateDm(props.economic.crmDm)}
            />
          </FormControl>
        </Grid>
      </Grid>
      {(showCrmClients || props.canShowClientsSection) && props.isReviewYear && (
        <Box className="p-1">
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography
              variant="h6"
              className="fs-14"
              sx={{ fontWeight: "700" }}
            >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Clients with revenue greater than 50k are automatically populated
              below.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {!props.disabled ? (
              <Grid item xs={12} sx={{ p: 0.5 }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className="addClientButton"
                  onClick={() =>
                    props.economicCrmActions.showNewEconomicCrmClientReviewYear()
                  }
                >
                  Add CRM Client
                </Button>
              </Grid>
            ) : null}
            {props.economicCrmClients.newEconomicCrmClientReviewYearVisible && (
              <EconomicCrmClientAddEdit
                              accessToken={props.auth.accessToken}
                              isNew
                              year={props.year}
                              save={props.economicCrmActions.addEconomicCrmClient}
                              title="Add CRM Client"
                              reviewYear={props.reviewYear}
                              canEdit={!props.disabled}
                              close={() => props.economicCrmActions.closeEconomicCrmClientReviewYear()}
                              isReviewYear={props.isReviewYear}
              />
            )}
            {props.economicCrmClients.editEconomicCrmClientReviewYearVisible && (
              <EconomicCrmClientAddEdit
                accessToken={props.auth.accessToken}
                year={props.year}
                client={economicCrmClient}
                save={props.economicCrmActions.updateEconomicCrmClient}
                title="Edit CRM Client"
                reviewYear={props.reviewYear}
                close={() => props.economicCrmActions.closeEconomicCrmClientReviewYear()}
                canEdit={!props.disabled}
                isReviewYear={props.isReviewYear}
              />
            )}
          </Grid>
        </Box>
      )}
          {(showCrmClients || props.canShowClientsSection) && !props.isReviewYear && (
        <Box className="p-1">
          <Grid item sx={{ textAlign: "left", p: 0.5 }}>
            <Typography
              variant="h6"
              className="fs-14"
              sx={{ fontWeight: "700" }}
            >
              Clients
            </Typography>
            <Typography variant="subtitle1" className="fs-14">
              Clients with revenue greater than 50k are automatically populated
              below.
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 0.5 }} className="crm-client">
            {clientsForYear}
            {!props.disabled ? (
              <Grid item xs={12} sx={{ p: 0.5 }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className="addClientButton"
                  onClick={() =>
                    props.economicCrmActions.showNewEconomicCrmClient()
                  }
                >
                  Add CRM Client
                </Button>
              </Grid>
            ) : null}
            {props.economicCrmClients.newEconomicCrmClientVisible && (
              <EconomicCrmClientAddEdit
                accessToken={props.auth.accessToken}
                isNew
                year={props.year}
                save={props.economicCrmActions.addEconomicCrmClient}
                title="Add CRM Client"
                reviewYear={props.reviewYear}
                canEdit={!props.disabled}
                close={() => props.economicCrmActions.closeEconomicCrmClient()}
                isReviewYear={props.isReviewYear}
              />
            )}
            {props.economicCrmClients.editEconomicCrmClientVisible && (
              <EconomicCrmClientAddEdit
                accessToken={props.auth.accessToken}
                year={props.year}
                client={economicCrmClient}
                save={props.economicCrmActions.updateEconomicCrmClient}
                title="Edit CRM Client"
                reviewYear={props.reviewYear}
                close={() => props.economicCrmActions.closeEconomicCrmClient()}
                canEdit={!props.disabled}
                isReviewYear={props.isReviewYear}
              />
            )}
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    economicCrmClients: state.economicCrmClients,
    canEdit: canEditSelector(state),
    reviewYear: reviewYearSelector(state),
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    economicCrmActions: bindActionCreators(economicCrmActionCreators, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EconomicCrmClient);
