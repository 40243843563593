import { Button, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsReducer from "../../store/reducers/Settings";
import { actionCreators as headerActionCreators } from "../../store/reducers/Header";
import { actionCreators as proxyUserActionCreators } from "../../store/reducers/ProxyUser";
import Format from "../../utils/Format";
import ProgressBar from "./ProgressBar";
import SelectProxyUser from "./SelectProxyUser";
import { NavLink } from "react-router-dom";
import UserInfo from "./UserInfo";
import utils from "../../utils/Utils";

const Header: React.FunctionComponent = (props: any): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [proxyUserId, setProxyId] = useState(props.selectedProxyUserId);
  const [currentSelectedUser, setCurrentSelectedUser] = useState({});

  useEffect(() => {
    if (props.auth.accessToken) {
      props.settingsActions.loadSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.accessToken]);

  useEffect(() => {
    if (props.selectedProxyUserId && props.auth.accessToken)
      props.proxyUserActions.setProxyUser(props.selectedProxyUserId);
    else props.headerActions.requestHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.userId, props.selectedProxyUserId, props.auth.accessToken]);

  const selectProxyUserOk = () => {
    setOpen(false);
    props.proxyUserActions.setProxyUser(proxyUserId, currentSelectedUser);
  };

  const header = props && props.header;
  const azureStorageKey = props && props.azureStorageKey;
  const photoName = props && props.header?.photoName;
  const itemImageStyle =
    utils.hasText(azureStorageKey) && utils.hasText(photoName)
      ? {
          backgroundImage: `url(https://unctestpdshareddevstg.file.core.windows.net/employeephotos/${photoName}${azureStorageKey})`,
        }
      : { backgroundImage: "undefined.jpg" };
  const canSelectUser =
    props.auth.proxyUsers && props.auth.proxyUsers.length > 0;
  
  return (
    <Card variant="outlined" sx={{ background: "#c9d5ed", minHeight: 125 }}>
      <CardContent>
        <div className="clearfix">
          <div className="pull-left">
            <NavLink to="/">
              <img
                src="/HB.png"
                width="179"
                height="75"
                alt="H-B"
                className="logo"
              />
            </NavLink>
          </div>
          <div className="pull-left item-image" style={itemImageStyle}></div>
          <div className="pull-left">
            <Typography
              variant="h5"
              style={{ fontWeight: 500, textAlign: "left" }}
            >
              {header?.name}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              style={{ fontSize: 12, fontWeight: 600 }}
            >
              {Format.formatAppendString(header?.sbuLeader, "SBU Leader")}{" "}
              {Format.formatAppendString(header?.sbuMd, "Managing Director")}
            </Typography>
            <Typography
              variant="body2"
              align="left"
              style={{ fontWeight: 600 }}
            >
              Partner Contribution Report
            </Typography>
            <div>
              <UserInfo data={`Plan Year: ${props.reviewYear}/${props.planYear}`} />{" "}
              <UserInfo data={`Office: ${header?.office ?? ""}`} />{" "}
              <UserInfo data={`SBU: ${header?.sbu ?? ""}`} />
              <UserInfo data={`PSC: ${header?.psc ?? ""}`} />
              <UserInfo data={`Hire Date: ${Format.formatDate(header?.hireDate)}`} />
            </div>
          </div>
          {canSelectUser && (
            <div className="pull-right">
              <Button
                variant="contained"
                className="theme-button"
                onClick={() => setOpen(true)}
              >
                Select Partner
              </Button>
              <SelectProxyUser
                open={open}
                users={props && props.proxyUsers}
                currentUserId = {props.auth.userId}
                setProxyId={setProxyId}
                setCurrentUser={setCurrentSelectedUser}
                selectedProxyUserId={proxyUserId}
                onClose={() => setOpen(false)}
                onOk={selectProxyUserOk}
              />
            </div>
          )}
        </div>
      </CardContent>
      <div style={{ clear: "both", textAlign: "center", padding: "10px" }}>
        <ProgressBar
          sbuLeaderCommentsEnabled={props.sbuLeaderCommentsEnabled}
          partnerCommentsVisible={props.partnerCommentsVisible}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    header: state.header,
    proxyUsers: state.auth.proxyUsers,
    azureStorageKey: settingsReducer.azureStorageSelector(state),
    sbuLeaderCommentsEnabled: settingsReducer.sbuLeaderCommentsEnabledSelector(state),
    reviewYear: settingsReducer.reviewYearSelector(state),
    planYear: settingsReducer.planYearSelector(state),
    selectedProxyUserId: settingsReducer.proxyUserSelector(),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    headerActions: bindActionCreators(headerActionCreators, dispatch),
    proxyUserActions: bindActionCreators(proxyUserActionCreators, dispatch),
    settingsActions: bindActionCreators(settingsReducer.actionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
