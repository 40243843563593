import { baseUrl } from "../../utils/authConfig";
import { actionCreators as errorActionCreators } from "./Error";

const requestGoalsType = "REQUEST_GOALS";
const receiveGoalsType = "RECEIVE_GOALS";
const receiveGoalsErrorType = "RECEIVE_GOALS_ERROR";
const requestDeleteGoalType = "REQUEST_DELETE_GOAL";
const receiveDeleteGoalType = "RECEIVE_DELETE_GOAL";
const receiveDeleteGoalErrorType = "RECEIVE_DELETE_GOAL_ERROR";
const requestPostGoalType = "REQUEST_POST_GOAL";
const receivePostGoalType = "RECEIVE_POST_GOAL";
const receivePostGoalErrorType = "RECEIVE_POST_GOAL_ERROR";
const requestPutGoalType = "REQUEST_PUT_GOAL";
const receivePutGoalType = "RECEIVE_PUT_GOAL";
const receivePutGoalErrorType = "RECEIVE_PUT_GOAL_ERROR";

const initialState = {
	isDeleting: false,
	isLoadedUserId: null,
	isLoading: false,
	isPosting: false,
	isPutting: false,
	goals: []
};

export const actionCreators = {
	addGoal: (data: any) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.goals.isPosting) {			
			return;
		}

		const { userId } = state.auth;
		data = { ...data, userId: userId };
		dispatch({ type: requestPostGoalType, data: data });
		const url = `${baseUrl}api/Goals`;
		try {
			const response = await fetch(url,
				{
					method: "POST",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receivePostGoalErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while adding a goal.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receivePostGoalType, data: responseData });
		} catch (exception) {
			dispatch({ type: receivePostGoalErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while adding a goal.", exception, null));
		}
	},
	removeGoal: (data: any) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.goals.isDeleting) {
			return;
		}

		dispatch({ type: requestDeleteGoalType, data: data });
		const url = `${baseUrl}api/Goals/${data}`;
		try {
			const response = await fetch(url,
				{
					method: "DELETE",
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receiveDeleteGoalErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while removing a goal.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveDeleteGoalType, data: responseData });
		} catch (exception) {
			dispatch({ type: receiveDeleteGoalErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while removing a goal.", exception, null));
		}
	},
	loadGoals: () => async (dispatch: any, getState: any) => {
		const state = getState();
		const { userId } = state.auth;
		if (state.goals.isLoadedUserId === userId) {
			return;
		}
		if (state.goals.isLoading) {
			return;
		}

		dispatch({ type: requestGoalsType });
		const url = `${baseUrl}api/Goals/${userId}`;
		try {
			const response = await fetch(url,{
				method: "GET",
				headers: {
					'Content-Type': "application/json",
					"Authorization": `Bearer ${getState().auth.accessToken}`
				},
				credentials: "same-origin"
			});
			if (!response.ok) {
				dispatch({ type: receiveGoalsErrorType, response: response });
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receiveGoalsType, data: responseData, userId: userId });
		} catch (exception) {
			dispatch({ type: receiveGoalsErrorType, exception: exception });
		}
	},
	updateGoal: (data: any) => async (dispatch: any, getState: any) => {
		const state = getState();
		if (state.goals.isPutting) {
			return;
		}

		dispatch({ type: requestPutGoalType, data: data });
		const url = `${baseUrl}api/Goals/${data.goalId}`;
		try {
			const response = await fetch(url,
				{
					method: "PUT",
					body: JSON.stringify(data),
					headers: {
						'Content-Type': "application/json",
						"Authorization": `Bearer ${getState().auth.accessToken}`
					},
					credentials: "same-origin"
				});
			if (!response.ok) {
				dispatch({ type: receivePutGoalErrorType, response: response });
				dispatch(errorActionCreators.addError("An error occurred while updating a goal.", null, response));
				return;
			}
			const responseData = await response.json();
			dispatch({ type: receivePutGoalType, data: responseData });
		} catch (exception) {
			dispatch({ type: receivePutGoalErrorType, exception: exception });
			dispatch(errorActionCreators.addError("An error occurred while updating a goal.", exception, null));
		}
	}
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === requestPostGoalType) {
		return {
			...state,
			isPosting: true
		};
	}

	if (action.type === receivePostGoalType) {
		return {
			...state,
			isPosting: false,
			goals: [
				...state.goals,
				action.data
			]
		};
	}

	if (action.type === receivePostGoalErrorType) {
		return {
			...state,
			isPosting: false
		};
	}

	if (action.type === requestGoalsType) {		
		return {
			...state,
			isLoading: true
		};
	}

	if (action.type === receiveGoalsType) {		
		return {
			...state,
			isLoadedUserId: action.userId,
			isLoading: false,
			goals: action.data
		};
	}

	if (action.type === receiveGoalsErrorType) {		
		return {
			...state,
			isLoadedUserId: null,
			isLoading: false,
			goals: []
		};
	}

	if (action.type === requestPutGoalType) {
		return {
			...state,
			isPutting: true
		};
	}

	if (action.type === receivePutGoalType) {		
		return {
			...state,
			isPutting: false,
			goals: state.goals.map((goal: any) =>
				goal.goalId === action.data.goalId
				? { ...goal, text: action.data.text, description: action.data.description }
				: goal)
		};
	}

	if (action.type === receivePutGoalErrorType) {
		return {
			...state,
			isPutting: false
		};
	}

	if (action.type === requestDeleteGoalType) {
		return {
			...state,
			isDeleting: true
		};
	}

	if (action.type === receiveDeleteGoalType) {		
		return {
			...state,
			isDeleting: false,
			goals: state.goals.filter((goal: any) => goal.goalId !== action.data.goalId)
		};
	}

	if (action.type === receiveDeleteGoalErrorType) {
		return {
			...state,
			isDeleting: false
		};
	}

	return state;
};